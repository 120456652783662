//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

/** Helper function imports */
import {
  resetPasswordRequest,
  resetPasswordValidateHash,
  resetPassword,
  logout,
  Context,
  setIsValid,
} from '../helpers';

/** Component Imports from rosm-ng-components */
import AuthPasswordResetForm from '@rosm/rosm-ng-components/dist/components/AuthPasswordResetForm/AuthPasswordResetForm.js';

/** Display the password reset form component and pass props */
function UserPasswordReset() {
  // resetHash -> Incoming url parameter containing the reset password hash to validate
  const { resetHash } = useParams();
  const { dispatch, setNotificationSnackBarMessage, setToken } =
    useContext(Context);

  const hash =
    resetHash === undefined || resetHash === null || resetHash === ''
      ? ''
      : resetHash;
  const [hashValid, setHashValid] = useState(false);

  /** Handle the request of a password reset */
  const onRequestResetPassword = (email) => {
    const req = async () => {
      const result = await resetPasswordRequest(email);
      return result;
    };
    req();
  };

  /** Handle the reset of the password by sending the hash, email address, and new password to API */
  const onResetPassword = (hash, email, password) => {
    const req = async () => {
      const result = await resetPassword(hash, email, password);
      return result;
    };
    req();
  };

  /** Handle the incoming email hash check before showing the reset password form */
  const onResetPasswordHashCheck = (hash) => {
    const req = async () => {
      await logout(setIsValid, dispatch, true);
      const result = await resetPasswordValidateHash(hash);
      return result;
    };
    req().then((result) => {
      if (result) {
        setHashValid(true);
        return true;
      }
      return false;
    });
  };

  // Pass props to form
  const props = {
    resetHash: hash,
    hashValid: hashValid,
    setHashValid: setHashValid,
    onRequestResetPassword: onRequestResetPassword,
    onResetPassword: onResetPassword,
    onResetPasswordHashCheck: onResetPasswordHashCheck,
    area: '',
  };

  return <AuthPasswordResetForm props={props} />;
}

export default UserPasswordReset;
