//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useReducer, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Header, MissingContent, Loading } from '../../components/Pages/index';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { DISTRIBUTION_COLORS } from '../../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../../components/ContentCard/StatusMapping';
import { LINK_MAPPING } from '../../components/ContentCard/LinkMapping';
import { Listing } from '@rosm/rosm-ng-components/dist/components/PageContent/ProjectComponents';
// State Management
import {
  documentationListReducer,
  initialDocumentation,
  DOCUMENT_TYPES,
  getDocumentList,
} from './state/documentationList';

const NO_SUCH_CONTENT = 'no content available';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

/**
 *
 * @param {string} assignedDocumentationType - documentation type (ex. hardware) <= AccordionItem (Component)
 * @param {array} items - array of documents for a specific type (ex. hardware -> [{...},]) <= AccordionItem (Component)
 * @returns
 */
const DocumentationListPage = ({
  assignedDocumentationType,
  items,
  showSelectBoxes,
  editing,
  setEditing,
  setLocalDocumentationList,
  handleUpdateDocumentationStateFromListingToSearch,
  styles,
}) => {
  const params = useParams();
  let navigate = useNavigate();
  const [state, dispatch] = useReducer(
    documentationListReducer,
    initialDocumentation
  );
  const { documentationList, loading, error, loaded } = state;

  // For passed documentation types (ex. from AccordionItem) then pass, otherwise, use params
  const documentationType = assignedDocumentationType
    ? assignedDocumentationType
    : params.documentationType;
  // When the documentation type is passed then do not show headers as the list page is used as a list component (ex. AccordionItem)
  const showHeader = assignedDocumentationType ? false : true;
  // Check if documentation type is not projects for filtering usage
  const [isNotProjectType, setIsNotProjectType] = useState(
    documentationType !== 'projects' ? true : false
  );

  useEffect(() => {
    getDocumentList(dispatch, items, documentationType);
    setIsNotProjectType(documentationType !== 'projects' ? true : false);
  }, [documentationType]);

  const handleClick = (id, refresh = false) => {
    navigate(`/documentation/${documentationType}/${id}`);
    if (refresh) {
      window.location.reload();
    }
  };

  if (error) {
    if (error === NO_SUCH_CONTENT) {
      return (
        <MissingContent
          title={'The requested documentation type does not exist.'}
        />
      );
    }
    return (
      <>
        {showHeader && (
          <Header title={DOCUMENT_TYPES[documentationType].toUpperCase()} />
        )}
        <h4>Error Loading Content</h4>
      </>
    );
  }
  if (loading) {
    return <Loading />;
  }

  if (loaded && documentationList) {
    if (documentationList.length === 0) {
      if (setLocalDocumentationList) {
        setLocalDocumentationList(documentationList);
      }
      return (
        <>
          {showHeader && (
            <Header title={DOCUMENT_TYPES[documentationType].toUpperCase()} />
          )}
          <MissingContent />
        </>
      );
    }
  }
  return (
    <>
      {loaded && (
        <Listing
          documentationList={documentationList}
          documentationType={documentationType}
          handleClick={handleClick}
          Header={Header}
          showHeader={showHeader}
          showSelectBoxes={showSelectBoxes}
          isNotProjectType={isNotProjectType}
          LINK_MAPPING={LINK_MAPPING}
          STATUS_MAPPING={STATUS_MAPPING}
          STATUS_MAPPING_DESCRIPTIONS={STATUS_MAPPING_DESCRIPTIONS}
          DISTRIBUTION_COLORS={DISTRIBUTION_COLORS}
          DOCUMENT_TYPES={DOCUMENT_TYPES}
          setLocalDocumentationList={setLocalDocumentationList}
          editing={editing}
          setEditing={setEditing}
          handleUpdateDocumentationStateFromListingToSearch={
            handleUpdateDocumentationStateFromListingToSearch
          }
          styles={styles}
        />
      )}
    </>
  );
};

DocumentationListPage.propTypes = {
  assignedDocumentationType: PropTypes.string,
  items: PropTypes.array,
  showSelectBoxes: PropTypes.bool,
  selected: PropTypes.any,
  setSelected: PropTypes.any,
  setLocalDocumentationList: PropTypes.any,
  editing: PropTypes.any,
  setEditing: PropTypes.any,
  handleUpdateDocumentationStateFromListingToSearch: PropTypes.func,
  styles: PropTypes.any,
};

export default DocumentationListPage;
