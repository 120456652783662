/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useReducer, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Header,
  MissingContent,
  Loading,
} from '../../../components/Pages/index';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Avatar from '@mui/material/Avatar';
import { DISTRIBUTION_COLORS } from '../../../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../../../components/ContentCard/StatusMapping';
import InfoIcon from '@mui/icons-material/Info';
import { config } from '../../../app/Config';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const HEADERS = [
  { title: '', align: 'center', value: '' },
  { title: 'Title', align: 'left', value: 'title' },
  { title: 'Sim Engine', align: 'center' },
  { title: 'Sim Engine Version', align: 'center' },
  { title: 'Custodian', align: 'center' },
  { title: 'Version', align: 'center' },
  { title: 'Status', align: 'center' },
  { title: 'Distribution', align: 'left' },
  { title: 'Last Updated', align: 'right' },
];

const SimulationPluginList = ({ rows }) => {
  let navigate = useNavigate();

  const handleClick = (id) => {
    navigate(`/simulationplugins/${id}`);
  };

  const headerItems = HEADERS.map((header) => {
    return <TableCell align={header.align}>{`${header.title}`}</TableCell>;
  });

  const rowItems = rows.map((row) => {
    const d = dayjs(row.updatedDate).utc('z');
    const d1 = dayjs().utc('z');
    const diff = d.diff(d1);
    const duration = dayjs.duration(diff).humanize(true);

    const title =
      row.title.length > 50 ? `${row.title.substring(0, 50)}...` : row.title;

    const description = row.description
      ? row.description.length > 150
        ? `${row.description.substring(0, 150)}...`
        : row.description
      : '---';

    const imageUrl =
      config.PUBLIC_URL + `/images/contentType/SimulationPluginUnreal.png`;

    const tagChips = row.tags.map((tag) => {
      return (
        <Chip
          color='primary'
          sx={{
            '& .MuiChip-label': {
              paddingLeft: '4px',
              paddingRight: '4px',
            },
            marginRight: '4px',
            borderRadius: '4px',
            height: '21px',
          }}
          label={tag}
        />
      );
    });

    return (
      <TableRow
        hover
        onClick={() => handleClick(row._id.toString())}
        key={row._id.toString()}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          style={{ padding: '8px' }}
          component='th'
          scope='row'
          align='left'
        >
          <div style={{ marginTop: '4px' }}>
            <img
              component='img'
              height='40px'
              width='40px'
              src={imageUrl}
              alt={'simulationplugin'}
            />
          </div>
        </TableCell>
        <TableCell
          style={{ padding: '8px' }}
          component='th'
          scope='row'
          align='left'
        >
          <div>
            <div style={{ marginBottom: '4px' }}>{title}</div>
            <div style={{ marginBottom: '4px' }}>{description}</div>
            <div>{tagChips}</div>
          </div>
        </TableCell>
        <TableCell style={{}} align='center'>
          {row.simEngine}
        </TableCell>
        <TableCell style={{}} align='center'>
          {row.simEngineVersion}
        </TableCell>
        <TableCell style={{}} align='center'>
          {row.custodian}
        </TableCell>
        <TableCell style={{}} align='center'>
          {row.version ? row.version : '---'}
        </TableCell>
        <TableCell style={{ width: '100px' }} align='center'>
          <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
            {STATUS_MAPPING[row.status]}
          </Tooltip>
        </TableCell>
        <TableCell style={{ width: '80px' }} align='center'>
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              sx={{ bgcolor: DISTRIBUTION_COLORS[row.distributionStatement] }}
            >
              {row.distributionStatement}
            </Avatar>
          </div>
        </TableCell>
        <TableCell style={{ width: '200px' }} align='right'>
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
              <InfoIcon />
            </Tooltip>
            <div style={{ paddingLeft: '4px', marginTop: '2px' }}>
              {duration}
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 550 }} aria-label='simple table'>
          <TableHead>
            <TableRow>{headerItems}</TableRow>
          </TableHead>
          <TableBody>{rowItems}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SimulationPluginList.propTypes = {};

export default SimulationPluginList;
