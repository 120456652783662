//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { GET, POST, BASE_URL_USER, BACKEND_HOST, UPLOAD_URL } from './index';
import urlJoin from 'url-join';
import { USER_MESSAGES } from './user/userMessages';

/**
 * getUserDocuments
 * @returns {object} - json object of documents
 */
export const getUserDocuments = async () => {
  const result = await GET(UPLOAD_URL);
  const data = await result.json();
  return data;
};

/**
 * Get the User Collection Details from the Server (Uses Token Value for userId)
 * OUT:
 *  - User data collection
 */
export const getUserData = async () => {
  try {
    const result = await GET(BASE_URL_USER);
    if (result.ok) {
      const data = await result.json();
      return {
        fetched: data.results,
      };
    }
    throw result.error;
  } catch (err) {
    return {
      error: err,
    };
  }
};

/**
 * Check Token Validity with Server
 * OUT:
 *  - Token validation object containing a { valid: true/false } boolean
 */
export const validateToken = async () => {
  try {
    const endpoint = urlJoin(BASE_URL_USER, 'protected');
    const validateResult = await GET(endpoint);
    if (validateResult.status) {
      const data = await validateResult.json();
      if (data.valid) {
        return {
          valid: true,
          accessLevel: data.accessLevel,
          unableToReachServer: false,
          message: USER_MESSAGES.userTokenValid,
          twoFaEnabled: data.twoFaEnabled,
          accountVerified: data.accountVerified,
          expires: data.expiresOn,
        };
      }
      return {
        valid: false,
        unableToReachServer: false,
        accountVerified: false,
        message: USER_MESSAGES.userTokenInvalid,
      };
    }
  } catch (error) {
    return {
      valid: false,
      unableToReachServer: true,
      accountVerified: false,
      message: USER_MESSAGES.unableToReachServer,
    };
  }
};

/**
 * Authentication Form
 * Posts the Authentication Form to Server and provides a true/false. Provides onAuthLogin Dependency to AuthLoginForm
 * IN:
 * - email: email address from authentication form
 * - password: password from authentication form
 * OUT:
 * - true/false (boolean): based on valid token response from server
 */
export const onAuthLogin = async (email, password, setIsValid, dispatch) => {
  const endpoint = urlJoin(BASE_URL_USER, 'login');
  const result = await POST(endpoint, {
    email: email,
    password: password,
  });
  const data = await result.json();
  if (data.token) {
    setIsValid(true, dispatch);
    return true; // Respond back with a true as the promise result to component
  }
};

/**
 * Signup Handler
 * Posts the signup form to the server and returns resulting success/fail message
 * IN:
 * - email (string): email address from signup form
 * - password (bcrypt string): password from signup form
 * - citizenship (string): citizenship status
 * - organization (string): organization that the applicant belongs to
 * - firstName (string): applicant's first name
 * - lastName (string): applicant's last name
 * - phoneNumber (string): phone number in 1XXXXXXXXXX format
 * - developmentRole (string): primary role in robotic and autonomous system development (ex. System Architect)
 * - rosExperience (string): number of years of experience developing in ROS
 * - rasExperience (string): number of years developing in RAS
 * - rosmExpectations (string): a description of how the application expects to use ROS-M
 * - collaborationPlatform (bool): applicant's collaboration platform (ex. codev) account status (true: has account, false: does not have account)
 * - collaborationPlatformAccountUserName (string): the applicant's collaboration platform (ex. codev) account username (if collaborationPlatform is true)
 * OUT:
 * - Success/Failure object
 */
export const onSignUp = async (
  email,
  password,
  citizenship,
  organization,
  firstName,
  lastName,
  phoneNumber,
  developmentRole,
  rosExperience,
  rasExperience,
  rosmExpectations,
  collaborationPlatform,
  collaborationPlatformAccountUserName
) => {
  const endpoint = urlJoin(BASE_URL_USER, 'signup');
  const result = await POST(endpoint, {
    email: email,
    password: password,
    citizenship: citizenship,
    organization: organization,
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNumber,
    developmentRole: developmentRole,
    rosExperience: rosExperience,
    rasExperience: rasExperience,
    rosmExpectations: rosmExpectations,
    collaborationPlatform: collaborationPlatform,
    collaborationPlatformAccountUserName: collaborationPlatformAccountUserName,
  });

  const data = await result.json();
  return data;
};

/**
 * Verify Email
 * Verify that that incoming email verification link is valid with user record on server
 * IN:
 * - userId (string): the verifying users ID
 * - verificationHash (string): the hash to compare (generated on account create)
 * OUT:
 * - Success/Failure object
 */
export const verifyEmail = async (verificationHash) => {
  if (verificationHash) {
    const endpoint = urlJoin(BASE_URL_USER, 'verifyEmail', verificationHash);
    return GET(endpoint);
  }
};

/**
 * Verify New Email
 * Verify that that incoming new email verification link is valid with user record on server
 * IN:
 * - userId (string): the verifying users ID
 * - verificationHash (string): the hash to compare (generated on account create)
 * OUT:
 * - Success/Failure object
 */
export const verifyNewEmail = async (verificationHash, requisitionId) => {
  try {
    if (verificationHash) {
      const endpoint = urlJoin(
        BASE_URL_USER,
        'verifyNewEmail',
        verificationHash,
        requisitionId
      );
      const result = await GET(endpoint);
      if (result.ok) {
        const data = await result.json();
        return {
          fetched: data,
        };
      }
      throw result.error;
    }
    throw new Error('No Verification Hash Found');
  } catch (err) {
    return {
      error: err,
    };
  }
};

/**
 * Triggers a Password Reset Request for Given Email
 * IN:
 *  - email: the email address of the user requesting the password reset
 * OUT:
 *  - valid (boolean): true/false
 */
export const resetPasswordRequest = async (email) => {
  const endpoint = urlJoin(BASE_URL_USER, 'resetPassword', 'request');
  const validateResult = await POST(endpoint, { email: email, area: '' });
  const data = await validateResult.json();
  return data.valid; // Expects a true value to mark that the email did exist and that a reset password hash was generated and email sent
};

/**
 * Triggers a Password Reset Hash Check
 * IN:
 *  - hash: the hash that is part of the link clicked from the reset request email that is used to validate
 * OUT:
 *  - valid (boolean): true/false
 */
export const resetPasswordValidateHash = async (hash) => {
  const endpoint = urlJoin(BASE_URL_USER, 'resetPassword', 'hash', hash);
  const validateResult = await GET(endpoint);
  const data = await validateResult.json();
  return data.valid; // Expects a true value to mark that the hash was valid before the reset form is even offered
};

export const resetPassword = async (hash, email, password) => {
  const endpoint = urlJoin(BASE_URL_USER, 'resetPassword', 'reset', hash);
  const validateResult = await POST(endpoint, {
    email: email,
    password: password,
    area: '',
  });
  const data = await validateResult.json();
  return data.valid; // Expects a true value to mark that the email did exist and that a reset password hash was generated and email sent
};

/**
 * Sends a contact details update request to server
 * @param {string} userId - the current user's ID
 * @param {string} firstName - the first name field from the edit form
 * @param {string} lastName  - the last name field from the edit form
 * @param {string} phoneNumber - the phone number field from the edit form
 * @param {string} organization - the oganization field
 * @param {string} email - the user's email address
 * @returns {boolean}
 */
//userId not used
export const onContactEdit = async (
  userId,
  firstName,
  lastName,
  phoneNumber,
  organization,
  email
) => {
  const endpoint = urlJoin(BASE_URL_USER, 'update', 'contact');
  const result = await POST(endpoint, {
    updateElements: {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      organization: organization,
      email: email,
    },
  });
  return result.valid; // Expects a true value to show successful update
};

/**
 * Sends a general details update request to server
 * @param {string} userId
 * @param {string} citizenship
 * @param {string} developmentRole
 * @param {string} rosExperience
 * @param {string} rasExperience
 * @param {string} rosmExpectations
 * @param {boolean} collaborationPlatform
 * @param {string} collaborationPlatformAccountUserName
 * @returns
 */
//userId not used
export const onGeneralEdit = async (
  userId,
  citizenship,
  developmentRole,
  rosExperience,
  rasExperience,
  rosmExpectations,
  collaborationPlatform,
  collaborationPlatformAccountUserName
) => {
  const endpoint = urlJoin(BASE_URL_USER, 'update', 'general');
  const result = await POST(endpoint, {
    updateElements: {
      citizenship: citizenship,
      developmentRole: developmentRole,
      rosExperience: rosExperience,
      rasExperience: rasExperience,
      rosmExpectations: rosmExpectations,
      collaborationPlatform: collaborationPlatform,
      collaborationPlatformAccountUserName:
        collaborationPlatformAccountUserName,
    },
  });
  const data = await result.json();
  return data.valid; // Expects a true value to show successful update
};

/**
 * Log User Out
 */
export const logout = async (setIsValid, dispatch, returnHome = false) => {
  const result = await GET(`${BACKEND_HOST}/user/logout`);
  const data = await result.json();
  if (data.success) {
    await setIsValid(false, dispatch);
    if (returnHome) {
      window.location.href = '/';
      return true;
    }
    window.location.reload(); // Refresh the page so that the latest routes can be shown with validation check
    return true;
  }
  return false;
};

/**
 * Sends Password Change to API and Returns Result Object
 * @param {string} currentPassword
 * @param {string} newPassword
 * @returns {object} data result object
 */
export const onPasswordChange = async (currentPassword, newPassword) => {
  const endpoint = urlJoin(BASE_URL_USER, 'update', 'password');
  const result = await POST(endpoint, {
    updateElements: {
      currentPassword: currentPassword,
      newPassword: newPassword,
    },
  });
  const data = await result.json();
  return data;
};

/**
 * Revalidate Token
 */
export const revalidateToken = async () => {
  const result = await GET(`${BASE_URL_USER}/reAuthorizeToken`);
  if (result.status === 200) {
    return result;
  }
  return false;
};

/**
 * Get the user history listing
 */
export const getAccessHistory = async () => {
  const endpoint = urlJoin(BASE_URL_USER, 'accessHistory');
  const result = await GET(endpoint);
  if (result.status === 200) {
    const data = await result.json();
    return data.result;
  }
  return [];
};
