//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext } from 'react';
import Box from '@mui/material/Box';

/* ROS-M Component Imports */
import TwoFaForm from '@rosm/rosm-ng-components/dist/components/TwoFaForm/TwoFaForm.js';

/** Global Context */
import {
  Context,
  POST,
  BACKEND_HOST,
  logout,
  setIsValid,
  setAccessLevel,
} from '../helpers';
import SupportFooter from '../components/SupportFooter';

/**
 * Resets the 2-FA for User
 */
export const onTwoFaReset = async () => {
  const result = await POST(`${BACKEND_HOST}/user/twoFaResetRequest`);
  return result.json();
};

/**
 * Displays the Authentication Form Page
 */
function TwoFaLayout() {
  const { dispatch, user, setNotificationSnackBarMessage, setToken } =
    useContext(Context);
  /**
   * Send 2FA Code to Server to Verify
   * @param {string} twoFaCode
   * @returns
   */
  const onTwoFaLogin = async (twoFaCode) => {
    const result = await POST(`${BACKEND_HOST}/user/twoFaValidate`, {
      twoFaCode: twoFaCode,
    });
    if (result.status === 200) {
      const data = await result.json();
      if (data.token) {
        setAccessLevel(data.accessLevel, dispatch);
        setIsValid(true, dispatch);
        return true; // Respond back with a true as the promise result to component
      }
    }
    return false;
  };

  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TwoFaForm
        onTwoFaLogin={onTwoFaLogin}
        onTwoFaReset={onTwoFaReset}
        resetLink={'/twoFaReset'}
        logout={handleLogout}
        user={user}
      />
      <SupportFooter />
    </Box>
  );
}

export default TwoFaLayout;
