import {
    GET_CATEGORIES,
    ADD_CATEGORIES,
    SET_ERRORS,
  } from '../actions/categories';
  
  const INITIAL_CATEGORIES_STATE = {
    categories: [],
    loaded: false,
    loading: false,
    isFetching: false,
    error: true,
  };
  
  const categoriesReducer = (state = INITIAL_CATEGORIES_STATE, action) => {
    switch (action.type) {
      case GET_CATEGORIES:
        return {
          ...state,
          loaded: false,
          loading: true,
          isFetching: true,
          error: false,
        };
      case ADD_CATEGORIES:
        return {
          ...state,
          categories: action.data,
          loaded: true,
          loading: false,
          isFetching: false,
          error: false,
        };
      case SET_ERRORS:
        console.log('ERRORS: ', action.errors);
        return {
          ...state,
          categories: [],
          loaded: false,
          loading: false,
          isFetching: false,
          error: true,
        };
      default:
        return state;
    }
  };
  
  export default categoriesReducer;
  