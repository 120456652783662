//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//


import {
  setSupportRequestRespond,
  setSupportRequest,
  setCsrRequest,
  setEmailChangeRequest,
  setOrganizationChangeRequest,
  setApiRequest,
  setSdaMdaRequest,
} from '../../helpers/api/userRequests';

/**
 * Component Called - Activates the setSupportRequestRespond function to add a new response message to the support request
 * PROPS:
 *  supportRequestId: the support request ID of the request being updated
 *  message: the new response message to be assigned to the support request
 *  requests: the requests listing (from context) to allow for updating the local context messages associated with the support request
 */
export const onSetSupportRespond = async (
  supportRequestId,
  message,
  requests
) => {
  const result = await setSupportRequestRespond(supportRequestId, message);
  if (requests) {
    return requests.map((obj) => {
      if (obj._id === supportRequestId) {
        if (result.messages) {
          result.messages = result?.messages?.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          );
        }
        return result;
      }
      return obj;
    });
  }
  return result;
};

/** Component Called - Submits a new request
 *  PROPS:
 *  requestTopic: the form field to mark the topic of the support requst
 *  requestMessage: the form field used to provide support request details
 */
export const onCsrRequest = async (requestMessage) => {
  const result = await setCsrRequest(requestMessage);
  return result;
};

/** Component Called - Submits a new request
 *  PROPS:
 *  requestTopic: the form field to mark the topic of the support requst
 *  requestMessage: the form field used to provide support request details
 */
export const onSupportRequest = async (requestTopic, requestMessage) => {
  const result = await setSupportRequest(requestTopic, requestMessage);
  return result;
};

/** Component Called - Submits a new email change request
 *  PROPS:
 *  requestCurrentEmail: the form field with the current user's email address
 *  requestNewEmail: the form field with the new email address to change to
 *  reuesstMessage: the reason for the email change request
 */
export const onEmailChangeRequest = async (
  requestCurrentEmail,
  requestNewEmail,
  requestMessage
) => {
  const result = await setEmailChangeRequest(
    requestCurrentEmail,
    requestNewEmail,
    requestMessage
  );
  return result;
};

/**
 * Component Called - Submits a new organization change request
 * @param {string} requestNewOrganization  - the form field with the new organization to change to
 * @param {string} requestMessage - the reason for the email change request
 * @returns
 */
export const onOrganizationChangeRequest = async (
  requestNewOrganization,
  requestMessage
) => {
  const result = await setOrganizationChangeRequest(
    requestNewOrganization,
    requestMessage
  );
  return result;
};

/**
 * Component Called - Submits a new API request
 * @param {*} requestMessage
 * @returns
 */
export const onApiRequest = async (requestMessage) => {
  const result = await setApiRequest(requestMessage);
  return result;
};

/**
 * Component Called - Submits a new SDA/MDA request
 * @param {*} requestMessage
 * @returns
 */
export const onSdaMdaRequest = async (requestMessage) => {
  const result = await setSdaMdaRequest(requestMessage);
  return result;
};
