//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { getRequestData } from '../index';

export const ACTIONS = {
  /* API CALL FETCHERS */
  FETCH_REQUESTS: 'FETCH_REQUESTS',
  UNABLE_TO_CONNECT: 'UNABLE_TO_CONNECT',
  REQUESTS: 'REQUESTS',
  ERROR_REQUESTS: 'ERROR_REQUESTS',
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_REQUESTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIONS.UNABLE_TO_CONNECT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'unable to connect',
      };
    }
    case ACTIONS.REQUESTS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        requestsData: action.data,
      };
    }
    case ACTIONS.ERROR_REQUESTS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'error getting requests',
      };
    }
    default: {
      return state;
    }
  }
};

export const loadData = (dispatch) => {
  const fetchData = async () => {
    dispatch({ type: ACTIONS.FETCH_REQUESTS });
    // Get Request Data and Count of Requests That are Notifications
    const requestsData = await getRequestData();
    const requestsDataFetched = requestsData?.fetched;
    if (requestsDataFetched) {
      dispatch({
        type: ACTIONS.REQUESTS,
        data: {
          requests: requestsDataFetched,
        },
      });
      return;
    } else {
      dispatch({
        type: ACTIONS.ERROR_REQUESTS,
        errorRequests: 'error',
      });
      return;
    }
  };
  fetchData();
};
