//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext, useEffect } from 'react';
import urlcat from 'urlcat';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import SearchFilterItem from './SearchFilterItem';
import SearchFilterItemCategories from './SearchFilterItemCategories';

import { SearchContext } from '../SearchContext';
import { Context } from '../../../helpers';
import { FILTER_CONTROLS } from './SearchFilterControls';

/**
 * Contains the state for the search filters
 * @returns
 */
const SearchFilters = ({
  result,
  onClickLink,
  incomingUrlParams,
  hasIncomingParameters,
  urlParams,
}) => {
  const initialState = {};
  const fieldMapping = {};
  const [state, setState] = React.useState(initialState);
  const context = useContext(SearchContext);
  const { sortBy } = context;

  const userContext = useContext(Context);
  const user = userContext.state.userData.user;

  let canViewCSR = false;
  user.roles.forEach((element) => {
    if (element.startsWith('CSR') || element === 'ADMINISTRATOR') {
      canViewCSR = true;
    }
  });

  const checkIncomingUrlParamForValue = (group, option) => {
    const groupOptions = incomingUrlParams[group];
    if (groupOptions) {
      let groupOptionsArray = [...new Set(groupOptions.split(','))];
      if (groupOptionsArray.includes(`${option}`)) {
        return true;
      }
    }
    return false;
  };

  FILTER_CONTROLS.map((fItem) => {
    fieldMapping[fItem.field] = [];
    fItem.options.map((fItemOption) => {
      initialState[`${fItem.field}-${fItemOption.field}`] =
        hasIncomingParameters
          ? checkIncomingUrlParamForValue(fItem.field, fItemOption.field)
          : fItemOption.checked;
      fieldMapping[fItem.field].push(fItemOption.field);
    });
    initialState[`${fItem.field}-enabled`] = true; // Automatically select top level on default groups
  });

  /**
   * Reset the Address Bar URL
   */
  const resetUrl = (updatedParams) => {
    // Build URL:
    const newUrl = urlcat(window.location.origin, 'search', updatedParams);
    window.history.replaceState({}, 'Search', newUrl);
  };

  // On initial load, set initial state based on mapped fields with any incoming url encodings
  useEffect(() => {
    setState(initialState);
  }, []);

  // search query
  useEffect(() => {
    context.setFilters(state);
    // Add default selections to search URL on initial load
    resetUrl(getGroupsSelectedOptionsObject(state));
  }, [state, result]);

  const handleSortByChange = (event) => {
    context.setSortByChange(event.target.value);
  };

  /**
   * Handle Clicking a Filter Checkbox by updating state, URL parameters, and setting URL
   * @param {object} event
   */
  const handleChange = (event) => {
    // Get the current state as a single object of groups with selected options as a comma separated string of option names
    const currentGroupOptions = getGroupsSelectedOptionsObject(state);
    // Update the selected options list based on incoming click and return updated options object
    const updatedGroupOptions = updateGroupsSelectedOptionsObject(
      { ...currentGroupOptions },
      event
    );
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    resetUrl(updatedGroupOptions);
  };

  /**
   * Converts an object into an array of the key title if value is true
   * @param {object} object
   * @returns {array} stateValues
   */
  const convertObjectToArray = (object) => {
    let stateValues = [];
    for (const [key, value] of Object.entries(object)) {
      if (value) {
        stateValues.push(key);
      }
    }
    return stateValues;
  };

  /**
   * Convert selected group options to get unique selected groups
   * @param {array} options
   * @returns
   */
  const getSelectedGroupsAsArray = (options) => {
    let groups = [];
    options.map((option) => {
      groups.push(option.split('-')[0]);
    });
    return groups;
  };

  /**
   * Gets an updated object containing all selected groupings with selected options as a comma separated string for url encoding
   * @param {object} state
   * @returns {object} groupOptions
   */
  const getGroupsSelectedOptionsObject = (state) => {
    const options = convertObjectToArray(state);
    const groups = getSelectedGroupsAsArray(options);
    let groupOptions = {};
    groups.map((group) => {
      let currentGroupOptions = [];
      options.map((option) => {
        const optionSplit = option.split('-');
        const optionGroup = optionSplit[0];
        const optionGroupOption = optionSplit[1];
        if (optionGroup === group) {
          // Option of "enabled" is the top level group
          currentGroupOptions.push(optionGroupOption);
        }
      });
      groupOptions[group] = currentGroupOptions.join(',');
    });
    return groupOptions;
  };

  /**
   * Updates the current selected group options with changes upon select/deselect and returns updated object
   * @param {object} groupsSelectedOptionsObject
   * @param {object} event
   * @returns {object} groupSelectedOptionsObject
   */
  const updateGroupsSelectedOptionsObject = (
    groupsSelectedOptionsObject,
    event
  ) => {
    const field = event.target.name.split('-');
    const group = field[0];
    const option = field[1];
    const optionValue = event.target.checked;
    // Convert comma separated listing of group options into an array for modification
    let groupOptionsArray = [];
    if (option === 'enabled' && !optionValue) {
      groupOptionsArray = [];
      delete groupsSelectedOptionsObject[group];
    } else {
      if (groupsSelectedOptionsObject[group]) {
        groupOptionsArray = [
          ...new Set(groupsSelectedOptionsObject[group].split(',')),
        ];
      }
      if (groupOptionsArray.includes(option) && !optionValue) {
        groupOptionsArray = groupOptionsArray.filter(
          (groupOption) => groupOption !== option
        );
      } else {
        groupOptionsArray.push(`${option}`);
      }
      // Convert back into comma separated value
      groupsSelectedOptionsObject[group] = groupOptionsArray.join(',');
    }
    return groupsSelectedOptionsObject;
  };

  /**
   * Select all
   */
  const handleResetClick = (field, categories = false) => {
    setFieldValues(field, true, categories);
  };

  /**
   * Select None
   */
  const handleNoneClick = (field, categories = false) => {
    setFieldValues(field, false, categories);
  };

  /**
   * Update the state for the selected group field
   */
  const handleEnabledChange = (field) => {
    setState({
      ...state,
      [field]: !state[field],
    });
  };

  /**
   * Updates a specific field's boolean (selected) value within the state
   * @param {string} field
   * @param {boolean} value
   */
  const setFieldValues = (field, value, categories = false) => {
    let categoryFieldMapping = [];
    if (categories) {
      categories.options.map((category) => {
        categoryFieldMapping.push(`${category.field}`);
      });
    }
    const fields = categories ? categoryFieldMapping : fieldMapping[field];

    if (fields && fields.length > 0) {
      const updatedFieldState = {};
      let newFieldListing = [];
      fields.map((fieldOption) => {
        updatedFieldState[`${field}-${fieldOption}`] = value;
        if (value) {
          updatedFieldState[`${field}-enabled`] = true;
          newFieldListing.push(fieldOption);
        } else {
          updatedFieldState[`${field}-enabled`] = false;
        }
      });
      setState({
        ...state,
        ...updatedFieldState,
      });
      const currentGroupOptions = getGroupsSelectedOptionsObject(state);
      if (!value) {
        delete currentGroupOptions[field];
        resetUrl(currentGroupOptions);
      } else {
        resetUrl({
          ...currentGroupOptions,
          [field]: newFieldListing.join(','),
        });
      }
    }
  };

  const filterItems = FILTER_CONTROLS.map((fItem, index) => {
    return (
      <SearchFilterItem
        key={`${fItem.field}`}
        options={state}
        handleResetClick={handleResetClick}
        handleNoneClick={handleNoneClick}
        handleChange={handleChange}
        handleEnabledChange={handleEnabledChange}
        enabled={state[`${fItem.field}-enabled`]}
        item={fItem}
      />
    );
  });

  //ROSM Categories
  filterItems.push(
    <SearchFilterItemCategories
      key={`rosmCategories`}
      options={state}
      handleResetClick={handleResetClick}
      handleNoneClick={handleNoneClick}
      handleChange={handleChange}
      enabled={state[`rosmCategories-enabled`]}
      handleEnabledChange={handleEnabledChange}
    />
  );

  return (
    <div style={{ marginTop: '12px' }}>
      <FormControl fullWidth>
        <InputLabel id='sortby-select-label'>Sort By</InputLabel>
        <Select
          sx={{
            '& .MuiInputBase-input': {
              padding: '8px 4px 4px 4px',
            },
          }}
          labelId='sortby-select-label'
          id='sortby-select'
          value={sortBy}
          label='Sort By'
          onChange={handleSortByChange}
        >
          <MenuItem value={'updatedDate-desc'}>Last Updated (desc)</MenuItem>
          <MenuItem value={'updatedDate-asc'}>Last Updated (asc)</MenuItem>
          <MenuItem value={'_score-desc'}>Relevance (desc)</MenuItem>
          <MenuItem value={'createdDate-desc'}>Date Created (desc)</MenuItem>
          <MenuItem value={'createdDate-asc'}>Date Created (asc)</MenuItem>
        </Select>
      </FormControl>
      {filterItems}
    </div>
  );
};
SearchFilters.propTypes = {
  result: PropTypes.any,
  onClickLink: PropTypes.any,
  incomingUrlParams: PropTypes.object,
  hasIncomingParameters: PropTypes.bool,
  urlParams: PropTypes.object,
};

export default SearchFilters;
