//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

/* ROS-M Component Imports */
import SignupForm from '@rosm/rosm-ng-components/dist/components/SignupForm/SignupForm.js';

/** Global Context */
import { onSignUp } from '../../helpers';

/** Styles */
import { BoxContainer, SuccessIcon } from './styles';

/** Assets */
import RosmLogo from './assets/rosmIcon.svg';

/** Icons */
import LoginIcon from '@mui/icons-material/Login';

/**
 * Account Signup
 * Show signup form with success message
 *
 */

function Signup() {
  const [signupSuccess, setSignupSuccess] = useState(false);

  return (
    <>
      {signupSuccess ? (
        <>
          <Container component='main' maxWidth='lg'>
            <BoxContainer>
              <img src={RosmLogo} width={40} alt='ROS Logo' />
              <Grid container sx={{ mt: 4 }}>
                <Grid item xs={3}>
                  <SuccessIcon />
                </Grid>
                <Grid item xs={9}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='h1' sx={{ mb: 2 }}>
                        Signup Success!
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 26, fontWeight: 'bold', mb: 1 }}
                      >
                        You have successfully submitted an account request
                      </Typography>
                      <Typography variant='body1'>
                        You will receive a verification email at your provided
                        email address. Please complete the email verification
                        request so that the account approval process can
                        continue.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        startIcon={<LoginIcon />}
                        sx={{ fontSize: 20, mt: 6, width: '100%' }}
                        size='large'
                        href='/'
                      >
                        Visit The Login Form
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BoxContainer>
          </Container>
        </>
      ) : (
        <SignupForm onSignup={onSignUp} setSignupSuccess={setSignupSuccess} />
      )}
    </>
  );
}

export default Signup;
