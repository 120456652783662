//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W56HZV-17-C-0062, Project Agreement DCS-S-17-011
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

export const DOCUMENTATION_ACTIONS = {
  FETCH: 'FETCH',
  FETCH_UPDATED: 'FETCH_UPDATED',
  UPDATED: 'UPDATED',
  UPDATE_ERROR: 'UPDATE_ERROR',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  NO_SUCH_CONTENT: 'NO SUCH CONTENT',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const documentationItemReducer = (state, action) => {
  switch (action.type) {
    case DOCUMENTATION_ACTIONS.FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case DOCUMENTATION_ACTIONS.FETCH_UPDATED: {
      return {
        ...state,
      };
    }
    case DOCUMENTATION_ACTIONS.UPDATED: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }
    case DOCUMENTATION_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        documentationItem: action.data,
      };
    }
    case DOCUMENTATION_ACTIONS.UPDATE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        documentationItem: action.data,
      };
    }
    case DOCUMENTATION_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    case DOCUMENTATION_ACTIONS.NO_SUCH_CONTENT: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export const initialDocumentation = {
  documentationItem: null,
  loading: false,
  loaded: false,
  error: null,
};
