/**
 */
export const NEW_CONTENT = {
  FETCH_POST: 'FETCH_POST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const postNewDataReducer = (state, action) => {
  switch (action.type) {
    case NEW_CONTENT.RESET: {
      return {
        status: null,
        loading: false,
        loaded: false,
        error: null,
        errorMessage: null,
      };
    }
    case NEW_CONTENT.FETCH_POST: {
      return {
        ...state,
        loading: true,
        error: null,
        errorMessage: null,
      };
    }
    case NEW_CONTENT.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        status: action.data,
        error: null,
        errorMessage: null,
      };
    }
    case NEW_CONTENT.ERROR: {
      console.log(action);
      return {
        ...state,
        loading: false,
        error: action.error,
        errorMessage: action.errorMessage,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const initialState = {
  status: null,
  loading: false,
  loaded: false,
  error: null,
  errorMessage: null,
};
