//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import SearchIcon from '@mui/icons-material/Search';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { Link } from 'react-router-dom';
import Icon from '@mdi/react';

// Documentation Type Icons
import {
  mdiFileStar, // Projects
  mdiCogTransfer, // Hardware
  mdiApplicationCog, // Model
  mdiFileDocumentAlert, // Planning
  mdiFormatListText, // Requirement
  mdiCodeJson, // Software
  mdiTextBoxCheck, // Standard
  mdiHistory, // Test
  mdiHammerWrench, // Tool
} from '@mdi/js';

const LINK_ITEMS = [
  {
    to: '/',
    title: 'Discover',
    icon: <SearchIcon />,
    tooltip: 'Discover Content',
  },
  {
    to: '/myContent',
    title: 'My Content',
    icon: <FolderSharedIcon />,
    tooltip: 'My Content',
  },
];

const links = (page, palette) =>
  LINK_ITEMS.map((item) => {
    return (
      <Tooltip key={item.title} title={item.tooltip} placement='right'>
        <ListItemButton
          component={Link}
          to={item.to}
          selected={page === item.to}
        >
          <ListItemIcon
            sx={page === item.to ? { color: palette.sidebarIcon.selected } : {}}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={
              page === item.to ? { sx: { fontWeight: 'bold' } } : {}
            }
            primary={item.title}
          />
        </ListItemButton>
      </Tooltip>
    );
  });

export const mainListItems = (page, palette) => (
  <React.Fragment>
    {/* <ListSubheader sx={{ backgroundColor: 'transparent' }} component="div" inset>
      Registry
    </ListSubheader> */}
    {links(page, palette)}

    {/* <ListItemButton component={Link} to="/recent">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Recent" />
    </ListItemButton> */}

    {/* <ListItemButton component={Link} to="/mycontent">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="My Content" />
    </ListItemButton> */}
  </React.Fragment>
);

export const rosmListItems = (page, palette) => (
  <React.Fragment>
    <ListSubheader sx={{ backgroundColor: 'transparent' }} component='div'>
      <ListItemIcon>
        <Avatar
          sx={{
            backgroundColor: palette.primary.main,
            fontSize: '1rem',
            width: 24,
            height: 24,
          }}
        >
          R
        </Avatar>
      </ListItemIcon>
      ROS
    </ListSubheader>

    <Tooltip title='Packages' placement='right'>
      <ListItemButton
        component={Link}
        to='/packages'
        selected={page.includes('/packages')}
      >
        <ListItemIcon
          sx={
            page.includes('/packages')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/packages') ? { sx: { fontWeight: 'bold' } } : {}
          }
          primary='Packages'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Instantiations' placement='right'>
      <ListItemButton
        component={Link}
        to='/instantiations'
        selected={page.includes('/instantiations')}
      >
        <ListItemIcon
          sx={
            page.includes('/instantiations')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <LayersIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/instantiations')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Instantiations'
        />
      </ListItemButton>
    </Tooltip>
  </React.Fragment>
);

export const simulationListItems = (page, palette) => (
  <React.Fragment>
    <ListSubheader sx={{ backgroundColor: 'transparent' }} component='div'>
      <ListItemIcon>
        <Avatar
          sx={{
            backgroundColor: palette.primary.main,
            fontSize: '1rem',
            width: 24,
            height: 24,
          }}
        >
          S
        </Avatar>
      </ListItemIcon>
      Simulation
    </ListSubheader>

    <Tooltip title='ProjectGL' placement='right'>
      <ListItemButton
        component={Link}
        to='/simulationplugins'
        selected={page.includes('/simulationplugins')}
      >
        <ListItemIcon
          sx={
            page.includes('/simulationplugins')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <ViewInArIcon />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/simulationplugins')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='ProjectGL'
        />
      </ListItemButton>
    </Tooltip>
  </React.Fragment>
);

export const csrListItems = (page, palette) => (
  <React.Fragment>
    {/* CSR */}

    <ListSubheader sx={{ backgroundColor: 'transparent' }} component='div'>
      <ListItemIcon>
        <Avatar
          sx={{
            backgroundColor: palette.primary.main,
            fontSize: '1rem',
            width: 24,
            height: 24,
          }}
        >
          D
        </Avatar>
      </ListItemIcon>
      Documentation
    </ListSubheader>

    <Tooltip title='Project Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/projects'
        selected={page.includes('/documentation/projects')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/projects')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiFileStar} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/projects')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Projects'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Hardware' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/hardware'
        selected={page.includes('/documentation/hardware')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/hardware')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiCogTransfer} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/hardware')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Hardware'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Model Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/model'
        selected={page.includes('/documentation/model')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/model')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiApplicationCog} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/model')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Model'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Planning Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/planningDocument'
        selected={page.includes('/documentation/planningDocument')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/planningDocument')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiFileDocumentAlert} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/planning')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Planning'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Requirement Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/requirement'
        selected={page.includes('/documentation/requirement')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/requirement')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiFormatListText} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/requirement')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Requirement'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Software Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/software'
        selected={page.includes('/documentation/software')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/software')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiCodeJson} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/software')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Software'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Standard Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/standard'
        selected={page.includes('/documentation/standard')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/standard')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiTextBoxCheck} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/standard')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Standard'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Test Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/test'
        selected={page.includes('/documentation/test')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/test')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiHistory} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/test')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Test'
        />
      </ListItemButton>
    </Tooltip>

    <Tooltip title='Tool Documents' placement='right'>
      <ListItemButton
        component={Link}
        to='/documentation/tool'
        selected={page.includes('/documentation/tool')}
      >
        <ListItemIcon
          sx={
            page.includes('/documentation/tool')
              ? { color: palette.sidebarIcon.selected }
              : {}
          }
        >
          <Icon path={mdiHammerWrench} size={1} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={
            page.includes('/documentation/tool')
              ? { sx: { fontWeight: 'bold' } }
              : {}
          }
          primary='Tool'
        />
      </ListItemButton>
    </Tooltip>
  </React.Fragment>
);
