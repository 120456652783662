/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PageviewIcon from '@mui/icons-material/Pageview';

import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs/esm/index';

import { VirtualTable } from '@rosm/rosm-ng-components/dist/components/ListContent';

const HEADERS = [
  { title: 'Link', align: 'left', value: 'link' },
  { title: 'Last Visited', align: 'left', value: 'lastVisited' },
];

const MyHistoryList = ({ rows }) => {
  let navigate = useNavigate();

  const handleClick = (row) => {
    navigate(row.link);
  };

  const headerItems = () =>
    HEADERS.map((header) => {
      return (
        <TableCell
          style={{
            width: header.width,
            minWidth: header.width,
            maxWidth: header.width,
          }}
          align={header.align}
          sx={{ fontWeight: 'bold' }}
        >
          {header.title === 'Link' ? <>{header.title}</> : <>{header.title}</>}
        </TableCell>
      );
    });

  const rowItems = (index, row) => {
    return (
      <>
        <TableCell>
          <Grid container>
            <Grid item>
              <PageviewIcon />
            </Grid>
            <Grid
              item
              sx={{
                fontSize: '115%',
                fontWeight: 'bold',
                pl: 1,
              }}
            >
              <u>{row.link}</u>
              <br />
              <Typography sx={{ fontSize: '80%' }}>{row.title}</Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          {dayjs(row.lastAccessTime).format('MMMM D, YYYY h:mm A')}
        </TableCell>
      </>
    );
  };

  return (
    <VirtualTable
      styles={null}
      rows={rows}
      handleClick={handleClick}
      headerItems={headerItems}
      rowItems={rowItems}
    />
  );
};

MyHistoryList.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default MyHistoryList;
