//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext, useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import {
  loadNotificationsData,
  NotificationsContext,
  ACTIONS,
} from '../../helpers/reducers/Notifications';

/** Requests Library Imports */
import { onSetSupportRespond } from '../../helpers';

/** Notifications Library Imports */
import { mapNotifications } from './NotificationsLib';

/** Notifications Component Imports */
import Table from '@rosm/rosm-ng-components/dist/components/NotificationsListing/Table.js';

/** Global Context & Helper Library */
import { Context, setRequestNotificationReviewed } from '../../helpers';

/**
 * Notifications Listing Card :: Notifications Component
 */
const NotificationsListing = () => {
  const [showClosedSupport, setShowClosedSupport] = useState(false); // Pass Show Closed Support Rows Bool
  const { snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage } =
    useContext(Context);
  const { state, dispatch } = useContext(NotificationsContext);
  const [mappedNotifications, setMappedNotifications] = useState();

  // Load the notifications data into the reducer
  useEffect(() => {
    loadNotificationsData(dispatch, state, 'listing');
  }, []);

  // Map the notifications on change for displaying within the notifications table
  useEffect(() => {
    if (state?.notificationsData?.notifications) {
      const map = async () => {
        await mapNotifications(
          state.notificationsData.notifications,
          setMappedNotifications
        );
      };
      map();
    }
  }, [state]);

  /**
   * Push an update to state for notifications
   * @param {array} notificationsUpdated
   */
  const updateNotifications = async (notificationsUpdated) => {
    const updateRequests = async () => {
      const notificationsCount = notificationsUpdated
        .filter(({ newNotification }) => newNotification === true)
        .filter(({ type }) => type === 'support').length;
      dispatch({ type: ACTIONS.FETCH_NOTIFICATIONS });
      dispatch({
        type: ACTIONS.NOTIFICATIONS,
        data: {
          notificationsCount: notificationsCount,
          notifications: notificationsUpdated,
        },
      });
    };
    updateRequests();
  };

  /* Handle the support response to update the context */
  const handleOnSetSupportRespond = (supportRequestId, message) => {
    const req = async () => {
      const notificationsUpdated = await onSetSupportRespond(
        supportRequestId,
        message,
        state.notificationsData.notifications
      );
      await updateNotifications(notificationsUpdated);
    };
    req();
  };

  /* Dismiss the notifications */
  const onNotificationDismiss = (notificationId) => {
    const req = async () => {
      const notificationDismissed = await setRequestNotificationReviewed(
        notificationId
      );
      if (notificationDismissed.status === 200) {
        // Update the requests by setting the requisition as no longer having a new notification
        const notificationsUpdated = state.notificationsData.notifications.map(
          (request) =>
            request._id === notificationId
              ? { ...request, newNotification: false }
              : request
        );
        await updateNotifications(notificationsUpdated);
        return true;
      } else {
        return false;
      }
    };
    req();
  };

  return (
    <Card elevation={0} sx={{ pt: 2 }}>
      <CardContent>
        {state.loaded && mappedNotifications && (
          <>
            <Table
              items={mappedNotifications}
              requestItems={mappedNotifications}
              onSetSupportRespond={handleOnSetSupportRespond}
              loaded={state.loaded}
              showClosedSupport={showClosedSupport}
              setShowClosedSupport={setShowClosedSupport}
              snackbarOpen={snackbarOpen}
              setSnackbarOpen={setSnackbarOpen}
              snackbarMessage={snackbarMessage}
              setSnackbarMessage={setSnackbarMessage}
              onNotificationDismiss={onNotificationDismiss}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default NotificationsListing;
