//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer, useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import AddContentForm from './AddContentForm';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProgressStepper from './components/ProgressStepper';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { MuiChipsInput } from 'mui-chips-input';
import { FormDatePicker } from '@rosm/rosm-ng-components/dist/components/PageContent/BaseComponents';
import { getCategories } from '../../helpers/api/category';
import {
  GET_CATEGORIES,
  ADD_CATEGORIES,
  SET_ERRORS,
} from '../../redux/actions/categories';
import categoriesReducer from '../../redux/reducers/categories';
import { default as DocumentationListPage } from '../csr/DocumentationListPage';
import { DOCUMENT_TYPES } from '../csr/state/documentationList';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { default as SearchDialog } from '../SearchDialog';
import { CSR_MAPPING } from '../../helpers/api';
// helper imports
import { Context, POST } from '../../helpers';

// form data
import {
  csrContentTypes,
  csrFormFields,
  rosmContentTypes,
  rosmFormFields,
} from '../../helpers/form/fields';
import { BACKEND_HOST } from '../../helpers/api';

// Redux imports
import {
  NEW_CONTENT,
  postNewDataReducer,
  initialState,
} from '../../redux/reducers/addContent';

const initialCategoriesState = {
  categories: [],
  loading: false,
  loaded: false,
  error: null,
};

// Top Level Constants
const ROSM = 'rosm';
const CSR = 'csr';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getUrlForData(dataType, contentType) {
  if (dataType === CSR) {
    return `${BACKEND_HOST}/csr/documentation`;
  }
  return `${BACKEND_HOST}/rosm/${contentType.toLowerCase()}s`;
}

/**
 * Add Content Form
 * Generates an "Add Content Form" based on field schemas reference in "getFields"
 */
const AddContent = ({ openUpload, setOpenUpload }) => {
  // Access global context
  const { setSnackbarOpen, setSnackbarMessage, state } = useContext(Context);
  const { userData } = state;
  const user = userData.user;

  const getCategoriesData = async () => {
    categoriesDispatch({ type: GET_CATEGORIES });
    try {
      const response = await getCategories();
      const data = await response.json();
      if (response.status === 200 && data) {
        categoriesDispatch({ type: ADD_CATEGORIES, data });
      } else {
        dispatch({
          type: SET_ERRORS,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      categoriesDispatch({
        type: SET_ERRORS,
        error: 'error',
      });
    }
  };

  const [categoriesState, categoriesDispatch] = useReducer(
    categoriesReducer,
    initialCategoriesState
  );
  const [categoryMappings, setCategoryMappings] = useState(null);

  useEffect(() => {
    getCategoriesData();
  }, []);

  const { categories } = categoriesState;

  useEffect(() => {
    let rosmCategories = [];
    categories.map((category) => {
      rosmCategories.push({
        title: category.name,
        label: category.name,
        value: category.categoryId,
        status: 1,
        description: category.name,
      });
    });
    setCategoryMappings(rosmCategories);
  }, [categories]);

  // setup reducer
  const [reducerState, dispatch] = useReducer(postNewDataReducer, initialState);
  // setup the state
  const { loading, error, loaded, errorMessage } = reducerState;
  // default to rosm for form field data type
  const [formFieldDataType, setFormFieldDataType] = useState(ROSM); // Track the form data type (ex. csr, rosm)
  // the sub type i.e package, csrModel
  const [contentDataType, setContentDataType] = useState(null);
  // the actual form data
  const [formContent, setFormContent] = useState({ releaseDate: new Date() });
  const [releaseDateAvailable, setReleaseDateAvailable] = useState(false);
  const [multiSelectContent, setMultiSelectContent] = useState([]);
  // stepper value
  const [stepValue, setStepValue] = useState(0);
  // Chip management
  const [chips, setChips] = useState({});
  // Show linked data on Project only
  const [showLinkedData, setShowLinkedData] = useState(false);
  // Local documentation list for Project Content
  const [linkedContentType, setLinkedContentType] = useState('hardware');
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSearchType, setActiveSearchType] = useState('');
  const [selectedDocuments, setSelectedDocuments] = useState({});

  // api request
  const postNewContentData = async (url, content) => {
    if (url && content) {
      try {
        let response = await POST(url, content);
        const data = await response.json();
        if (
          response.status === 200 ||
          response.status === 204 ||
          response.status === 201
        ) {
          setOpenUpload(false);
          setSnackbarMessage('Content Successfully Uploaded');
          setSnackbarOpen(true);
          // content added
          dispatch({ type: NEW_CONTENT.SUCCESS, data });
          setFormContent({}); // Clear form fields
          setContentDataType(null); // Clear form data type field
          setStepValue(0);
          setChips({});
          setReleaseDateAvailable(false);
          setMultiSelectContent([]);
          return;
        }
        dispatch({
          type: NEW_CONTENT.ERROR,
          error: data.error,
          errorMessage: data.message,
        });
      } catch (error) {
        dispatch({ type: NEW_CONTENT.ERROR, error: 'error' });
      }
    } else {
      dispatch({ type: NEW_CONTENT.ERROR, error: 'missing data' });
    }
  };

  useEffect(() => {
    // reset the status
    dispatch({ type: NEW_CONTENT.RESET });
    if (contentDataType !== '') {
      setStepValue(1);
    }
    // reset the users information when we select the first option
    formContent.custodian = user.emails[0].address;
    formContent.contact = user.emails[0].address;
    formContent.createdBy = user._id;
    formContent.contentType = contentDataType
      ? contentDataType.charAt(0).toUpperCase() + contentDataType.slice(1)
      : null;
    // Only show linked data on projects
    setShowLinkedData(contentDataType === 'csrProject');
    // Set empty arrays for linked content types for Projects only
    if (contentDataType === 'csrProject') {
      for (const type of Object.keys(DOCUMENT_TYPES).slice(1)) {
        formContent[type] = [];
      }
    }
  }, [contentDataType]);

  useEffect(() => {
    setStepValue(0);
    setChips({});

    dispatch({ type: NEW_CONTENT.RESET });
  }, [openUpload]);

  useEffect(() => {
    if (releaseDateAvailable && !formContent['releaseDate']) {
      setFormContent({ ...formContent, releaseDate: new Date() });
    }
  }, [releaseDateAvailable]);

  // If we reset content creation, set type to null
  useEffect(() => {
    if (stepValue === 0) {
      setContentDataType(null);
    }
  }, [stepValue]);

  // When we change the LinkedContentType, change search params, reset query/activeTab
  useEffect(() => {
    setActiveSearchType(CSR_MAPPING[linkedContentType]);
    setSearchQuery('');
    setActiveTab(0);
  }, [linkedContentType]);

  // If we have no selected documents, don't look at currently selected list
  useEffect(() => {
    if (selectedDocuments[linkedContentType] === undefined) {
      setActiveTab(0);
    }
  }, [selectedDocuments]);

  /* Function that passes the form data to the API */
  const onAddContent = (formType, formFieldsType, data) => {
    const submitFormContent = { ...formContent };
    postNewContentData(
      getUrlForData(formFieldDataType, contentDataType),
      submitFormContent
    );
  };

  const handleContentDataTypeChange = (event) => {
    setFormContent({});
    let fieldType = '';
    rosmContentTypes.options.map((type) => {
      if (type.value === event.target.value) {
        fieldType = ROSM;
      }
    });
    fieldType = !fieldType ? CSR : ROSM;
    setFormFieldDataType(fieldType);
    // Set form field type based on data content type selection
    setContentDataType(event.target.value);
    setReleaseDateAvailable(false);
  };

  const handleFieldChange = (event, field) => {
    // set the field value for the form
    setFormContent({ ...formContent, [field]: event.target.value });
  };

  const handleDateChange = (prop) => (event) => {
    setFormContent({
      ...formContent,
      [prop]: event?.toDate(),
    });
  };

  /** Dialog Close Handler */
  const handleClickCloseDialog = () => {
    setOpenUpload(false);
    setFormContent({});
    setFormFieldDataType('');
    setContentDataType(null);
    setStepValue(0);
    setChips([]);
    dispatch({ type: NEW_CONTENT.RESET });
    setMultiSelectContent([]);
  };

  const handleCheckboxChange = (event) => {
    setReleaseDateAvailable(event.target.checked);
  };

  const handleTagChange = (newChips, field) => {
    setChips({ ...chips, [field]: newChips });
    setFormContent({ ...formContent, [field]: newChips });
  };

  const handleMultiSelectChange = (newMultiSelect, field) => {
    setMultiSelectContent(newMultiSelect);
    setFormContent({
      ...formContent,
      [field]: newMultiSelect,
    });
  };

  const handleMoveToLinkedData = () => {
    setStepValue(2);
  };

  const handleGoBack = () => {
    setStepValue(stepValue - 1);
  };

  const handleUpdateDocumentationStateFromListingToSearch = (
    removedDocumentId
  ) => {
    // Remove from content
    const newFormContent = { ...formContent };
    newFormContent[linkedContentType] = [
      ...newFormContent[linkedContentType],
    ].filter((x) => x !== removedDocumentId);
    setFormContent(newFormContent);

    // Remove from list of selected documents
    const newSelectedDocuments = { ...selectedDocuments };
    newSelectedDocuments[linkedContentType] = [
      ...newSelectedDocuments[linkedContentType],
    ].filter((x) => x._id.toString() !== removedDocumentId);
    if (newSelectedDocuments[linkedContentType].length === 0) {
      delete newSelectedDocuments[linkedContentType];
    }
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleUpdateDocumentationState = (newContent) => {
    const newFormContent = { ...formContent };
    newFormContent[linkedContentType] = newContent[linkedContentType].map(
      (x) => x._id
    );
    setFormContent(newFormContent);

    // Keep track of selected documents by type to be able to unselect
    const newSelectedDocuments = { ...selectedDocuments };
    newSelectedDocuments[linkedContentType] = newContent[linkedContentType];
    if (newContent[linkedContentType].length === 0) {
      delete newSelectedDocuments[linkedContentType];
    }
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab);
  };

  const handleSelectedListChangeFromListing = (newList) => {
    const newSelectedDocuments = { ...selectedDocuments };
    newSelectedDocuments[linkedContentType] = newList;
    if (newList.length === 0) {
      delete newSelectedDocuments[linkedContentType];
    }
    setSelectedDocuments(newSelectedDocuments);
  };
  // create the second level of selection based on the type
  const availableContentDataTypes = rosmContentTypes.options.concat(
    csrContentTypes.options
  );

  const selectContentTypes = availableContentDataTypes.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    );
  });

  // depending on the content type we need to handle the inputs
  let formFields = <></>;

  if (openUpload && contentDataType && formFieldDataType) {
    const fieldArray =
      formFieldDataType === ROSM ? rosmFormFields : csrFormFields;

    formFields = fieldArray.map((formItem, index) => {
      // show logic
      let shouldShowField = false;

      if (formItem.showInForms[0] === 'base') {
        shouldShowField = true;
      }
      if (formItem.showInForms.includes(contentDataType)) {
        shouldShowField = true;
      }
      formItem.showInForms.map((show) => {
        if (show === contentDataType) {
          shouldShowField = true;
        }
      });

      // render logic for the items
      if (shouldShowField) {
        let width = '25%';
        if (
          formItem.field === 'title' ||
          formItem.field === 'description' ||
          formItem.field === 'tags' ||
          formItem.field === 'dependencies'
        ) {
          width = '100%';
        }

        const formFieldValue = formContent[formItem.field];

        // TEXT field
        if (formItem.type === 'Text') {
          return (
            <TextField
              required
              value={formFieldValue}
              key={`${contentDataType}_${formItem.field}_${index}`}
              sx={{ minWidth: width }}
              variant='filled'
              id={`${contentDataType}_${formItem.field}_${index}`}
              defaultValue={formFieldValue}
              label={formItem.label}
              onChange={(e) => handleFieldChange(e, formItem.field)}
            />
          );
        } else if (formItem.type === 'Multiline') {
          return (
            <TextField
              required
              value={formContent[formItem.field]}
              key={`${contentDataType}_${formItem.field}_${index}`}
              sx={{ minWidth: width }}
              multiline
              rows={4}
              variant='filled'
              id={`${contentDataType}_${formItem.field}_${index}`}
              defaultValue={''}
              label={formItem.label}
              onChange={(e) => handleFieldChange(e, formItem.field)}
            />
          );
        } else if (formItem.type === 'Date') {
          //DATE TIME PICKER
          return (
            <div style={{ display: 'flex', marginLeft: '10px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={releaseDateAvailable}
                    onChange={handleCheckboxChange}
                    color='success'
                    defaultChecked
                  />
                }
                label='Release Date'
              />
              {releaseDateAvailable && (
                <FormDatePicker
                  id={formItem.field}
                  label={formItem.label}
                  value={formFieldValue}
                  onChange={handleDateChange}
                  format='MM/DD/YYYY'
                  inputVariant='filled'
                />
              )}
            </div>
          );
        } else if (formItem.type === 'Select') {
          // SELECT box
          let width = '288px';
          // categories pulled from rest API, handle separately
          const options =
            formItem.field === 'category'
              ? categoryMappings.map((childItem) => {
                  return (
                    <MenuItem
                      value={!childItem.value ? '' : childItem.value}
                      key={childItem.value}
                    >
                      {childItem.label}
                    </MenuItem>
                  );
                })
              : formItem.options.map((childItem) => {
                  return (
                    <MenuItem
                      value={!childItem.value ? '' : childItem.value}
                      key={childItem.value}
                    >
                      {childItem.label}
                    </MenuItem>
                  );
                });
          return (
            <FormControl sx={{ m: 1, minWidth: width }}>
              <InputLabel
                sx={{ top: '10px' }}
                id={`${contentDataType}_${formItem.field}_${index}-select-label`}
              >
                {formItem.hoverDescription}
              </InputLabel>
              <Select
                variant='filled'
                labelId={`${contentDataType}_${formItem.field}_${index}-select-label`}
                key={`${contentDataType}_${formItem.field}_${index}-select`}
                id={`${contentDataType}_${formItem.field}_${index}-select`}
                value={formContent[formItem.field]}
                label={formItem.label}
                onChange={(e) => handleFieldChange(e, formItem.field)}
              >
                {options}
              </Select>
            </FormControl>
          );
        } else if (formItem.type === 'TagEntry') {
          return (
            <>
              <Typography>{formItem.hoverDescription}</Typography>
              <FormControl sx={{ m: 0, minWidth: width }}>
                <InputLabel
                  sx={{ top: 0 }}
                  id={`${contentDataType}_${formItem.field}_${index}-meta-label`}
                ></InputLabel>
                <MuiChipsInput
                  required
                  variant='filled'
                  placeholder={formItem.placeholderDescription}
                  id={`${contentDataType}_${formItem.field}_${index}`}
                  value={chips[formItem.field]}
                  onChange={(e) => handleTagChange(e, formItem.field)}
                  sx={{
                    width: width,
                    input: {
                      '&::placeholder': {
                        opacity: 1,
                      },
                    },
                  }}
                />
              </FormControl>
            </>
          );
        } else if (formItem.type === 'MultiSelect') {
          return (
            <FormControl variant='filled' sx={{ m: 1, width: '100%' }}>
              <InputLabel
                id={`${contentDataType}_${formItem.field}_${index}-select-label`}
              >
                {formItem.label}
              </InputLabel>
              <Select
                multiple
                renderValue={(selected) => `${selected.length} Selected`}
                MenuProps={MenuProps}
                labelId={`${contentDataType}_${formItem.field}_${index}-select-label`}
                id={`${contentDataType}_${formItem.field}_${index}-meta-label`}
                sx={{ textTransform: 'uppercase' }}
                value={multiSelectContent}
                onChange={(e) =>
                  handleMultiSelectChange(e.target.value, formItem.field)
                }
                label={formItem.label}
              >
                {formItem.options.map((opt) => (
                  <MenuItem
                    dense
                    style={{ textTransform: 'uppercase' }}
                    value={opt}
                  >
                    <Checkbox
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 18 },
                        padding: '4px',
                      }}
                      checked={multiSelectContent.includes(opt)}
                    />
                    <ListItemText primary={opt} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
      }
    });
  }

  // Generate list of linked document types for Projects

  const linkedDocumentTypesList = Object.entries(DOCUMENT_TYPES)
    .slice(1)
    .map(([key, value]) => {
      const label = value.substring(0, value.indexOf(' '));
      return (
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => setLinkedContentType(key)}
            selected={key === linkedContentType}
          >
            <ListItemText
              primary={`${label} - ${
                formContent[key] ? formContent[key].length : 0
              }`}
            />
          </ListItemButton>
        </ListItem>
      );
    });

  if (
    !openUpload ||
    categoriesState.loading ||
    !categoriesState.loaded ||
    !categoryMappings ||
    categoryMappings.length === 0
  ) {
    return <></>;
  }

  return (
    <AddContentForm
      success={loaded}
      errors={error}
      errorMessage={errorMessage}
      loading={loading}
      onAddContent={onAddContent}
      openUpload={openUpload}
      setOpenUpload={setOpenUpload}
      handleClickCloseDialog={handleClickCloseDialog}
      showLinkedData={showLinkedData}
      stepValue={stepValue}
      handleMoveToLinkedData={handleMoveToLinkedData}
      handleGoBack={handleGoBack}
    >
      <ProgressStepper step={stepValue} showLinkedData={showLinkedData} />
      {stepValue < 2 && (
        <>
          <Box>
            <Typography variant='h6' gutterBottom component='div'>
              Create Content
            </Typography>
            <Typography variant='body1' gutterBottom component='div'>
              Complete the form below to add content to the ROS-M system. Select
              the type of content below and complete all fields.
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex' }}
            component='form'
            noValidate
            autoComplete='off'
          >
            <FormControl sx={{ m: 1, minWidth: '31%' }}>
              <InputLabel sx={{ top: '10px' }} id='content-type-select-label'>
                Select Content Type
              </InputLabel>
              <Select
                variant='filled'
                labelId='content-type-select-label'
                id='content-type-select'
                key='content-type-select'
                value={contentDataType}
                label='Content Type'
                onChange={handleContentDataTypeChange}
              >
                {selectContentTypes}
              </Select>
            </FormControl>
            <Tooltip
              placement='right'
              title='Select the type of data content that you wish to add.'
            >
              <IconButton sx={{ height: '40px', marginTop: '16px' }}>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Divider />
          {/* form data */}

          <Box
            component='form'
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            noValidate
            autoComplete='off'
          >
            <div>{formFields}</div>
          </Box>
        </>
      )}

      {stepValue >= 2 && (
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <List>{linkedDocumentTypesList}</List>
          </Grid>
          <Grid item xs={10}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label='Document Addition' />
                <Tab
                  label='Selected Documents'
                  disabled={selectedDocuments[linkedContentType] === undefined}
                />
              </Tabs>
            </Box>
            {activeTab === 0 && (
              <Box sx={{ marginTop: 4 }}>
                <SearchDialog
                  type={activeSearchType}
                  dataType={linkedContentType}
                  upperCaseType={linkedContentType.toUpperCase()}
                  localDocumentationList={selectedDocuments}
                  handleUpdateDocumentationState={
                    handleUpdateDocumentationState
                  }
                  editing={null}
                  setEditing={null}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  activeSearchType={activeSearchType}
                  setActiveSearchType={setActiveSearchType}
                />
              </Box>
            )}
            {activeTab === 1 && (
              <Box sx={{ marginTop: 1 }}>
                <DocumentationListPage
                  assignedDocumentationType={linkedContentType}
                  items={selectedDocuments[linkedContentType]}
                  onSubDocumentClick={
                    handleUpdateDocumentationStateFromListingToSearch
                  }
                  showSelectBoxes={true}
                  localDocumentationList={selectedDocuments}
                  setLocalDocumentationList={
                    handleSelectedListChangeFromListing
                  }
                  editing={null}
                  setEditing={null}
                  handleUpdateDocumentationStateFromListingToSearch={
                    handleUpdateDocumentationStateFromListingToSearch
                  }
                />
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </AddContentForm>
  );
};

export default AddContent;
