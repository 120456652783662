//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { config } from '../app/Config';
import { version } from '../app/version';
/** Icons */
import ContactMailIcon from '@mui/icons-material/ContactMail';

import { useTheme } from '@mui/material/styles';

/** Styling */
import styles from './Footer.module.css';

function SupportFooter() {
  const { REACT_APP_SUPPORT_EMAIL, REACT_APP_FOOTER_CONTENT } = config;
  const { palette } = useTheme();
  return (
    <div className={styles.footer} style={{ height: '25px', backgroundColor: palette.siteToolbar.main, borderTopColor: palette.siteToolbar.secondary }}>
      <Grid container>
        <Grid item xs={4}>
          <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
          >
            <Link
              href={`mailto:${REACT_APP_SUPPORT_EMAIL}?subject=Support Request`}
              target='_blank'
              rel='noopener noreferrer'
              className='emailSupport'
            >
              <Typography className={styles.link}>
                <ContactMailIcon className={styles.linkIcon} />
                Contact Support
              </Typography>
            </Link>
          </Stack>
        </Grid>
        <Grid container item xs={8} justifyContent='flex-end'>
          <div style={{ paddingRight: '25px' }}>
            {REACT_APP_FOOTER_CONTENT}
            {version && <>{` | v.${version}`}</>}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default SupportFooter;
