//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import {
  GET,
  POST,
  POST_FILE,
  PUT,
  BASE_URL_REQUESTS,
  UPLOAD_URL,
} from './index';
import urlJoin from 'url-join';

export const postUploadFile = async (file) => {
  const result = await POST_FILE(UPLOAD_URL, file);
  const data = await result.json();
  return data;
};

//fileId not used. Empty function
export const deleteFile = async (fileId) => {};

/**
 * Get Listing of Requests for User from the Server (Uses Token Value for userId) *
 * OUT:
 *  - data: the listing of requests for the current user
 */
export const getRequestData = async () => {
  try {
    const endpoint = urlJoin(BASE_URL_REQUESTS, 'listing');
    const result = await GET(endpoint);
    if (result.ok) {
      const data = await result.json();
      return {
        fetched: data.results,
        error: false,
      };
    }
    throw result.error;
  } catch (err) {
    return {
      error: err,
    };
  }
};

/**
 * Get Listing of Requests with Notifications for User from the Server (Uses Token Value for userId)
 * @param {OPTIONAL: boolean} countOnly
 * @returns data { fetched: array of results with notifications OR simple count of notifications}
 */
export const getRequestNotificationsData = async (countOnly) => {
  try {
    const endpoint = urlJoin(BASE_URL_REQUESTS, 'notifications', countOnly);
    const result = await GET(endpoint);
    if (result.ok) {
      const data = await result.json();
      return {
        fetched: data.results,
        error: false,
      };
    }
    throw result.error;
  } catch (err) {
    return {
      error: err,
    };
  }
};

/**
 * Get Listing of Requests by Request Type from the Server (Uses Token Value for userId)
 * IN:
 *  - requestType: the type of request to query from the UserRequisitions collection (ex. api)
 * OUT:
 *  - data: the listing of requests matching the specified requestType
 */
export const getRequestDataForRequestType = async (requestType) => {
  const endpoint = urlJoin(BASE_URL_REQUESTS, 'listing', requestType);
  const result = await GET(endpoint);
  const data = await result.json();
  return data.results;
};

/**
 * Creates a new support request response message
 * IN:
 *  - supportRequestId: the ID of the support request being updated
 *  - message: the response message to add
 * OUT:
 *  - data: the updated request object upon success to allow for ui context update or a failure message
 */
export const setSupportRequestRespond = async (supportRequestId, message) => {
  const endpoint = urlJoin(
    BASE_URL_REQUESTS,
    'addSupportResponse',
    supportRequestId
  );
  const result = await POST(endpoint, {
    message: message,
    newUpdate: true,
  });
  const data = await result.json();
  return data;
};

/**
 * Marks requests as having no newUpdates to clear notifications once reviewed
 * Parameters:
 *  - [out]
 *    - result: { message: success/fail }
 */
export const setRequestNotificationsReviewed = async () => {
  const endpoint = urlJoin(
    BASE_URL_REQUESTS,
    'setRequestNotificationsReviewed'
  );
  const result = await PUT(endpoint, {
    newNotification: false,
  });
  return result;
};

/**
 * Creates a new support request
 * IN:
 *  - supportRequestId: the ID of the support request being updated
 *  - message: the response message to add
 * OUT:
 *  - data: the updated request object upon success to allow for ui context update or a failure message
 */
//requestTopic and requestMessage not used
export const setSupportRequest = async (requestTopic, requestMessage) => {
  return true;
};

/**
 * Set Notification Reviewed
 * IN:
 *  - notificationId: the id of the notification (UserRequisitionId) to mark as being reviewed *
 * OUT:
 *  - message: success/fail
 */
export const setRequestNotificationReviewed = async (notificationId) => {
  if (notificationId) {
    const endpoint = urlJoin(
      BASE_URL_REQUESTS,
      'setRequestNotificationReviewed'
    );
    const result = await PUT(endpoint, {
      notificationId: notificationId,
    });
    return result;
  } else {
    return false;
  }
};

/**
 * Creates a new CSR Access request
 * IN:
 *  - requestMessage: the reason for the request
 * OUT:
 *  - data: the updated request object upon success to allow for ui context update or a failure message
 */
//requestMessage not used
export const setCsrRequest = async (requestMessage) => {
  return true;
};

/**
 * Creates a new Email Change request
 * IN:
 *  - requestCurrentEmail (string) - current email to verify
 *  - requestNewEmail (string) - the email to change to
 *  - requestNewEmailVerify (string) - a verification of the new email (typed again)
 */
//requestCurrentEmail, requestNewEmail, and requestMessage unused
export const setEmailChangeRequest = async (
  requestCurrentEmail,
  requestNewEmail,
  requestMessage
) => {
  return true;
};

/**
 * Creates a new Organization Change request
 * @param {string} requestNewOrganization - the new organization to change to
 * @param {string} requestMessage - the reason for the organization change request
 * @returns
 */
//requestNewOrganization, requestMessage unused
export const setOrganizationChangeRequest = async (
  requestNewOrganization,
  requestMessage
) => {
  return true;
};

/**
 * Creates a new API Request
 * @param {string} requestMessage
 * @returns
 */
//requestMessage unused
export const setApiRequest = async (requestMessage) => {
  return true;
};

/**
 * Creates a new SDA/MDA Request
 * @param {string} requestMessage
 * @returns
 */
//requestMessage unused
export const setSdaMdaRequest = async (requestMessage) => {
  return true;
};

//error unused
export const emailAdminOnError = async (error) => {
  return true;
};

/**
 * Creates a new support request
 * IN:
 *  - supportRequestId: the ID of the support request being updated
 *  - message: the response message to add
 * OUT:
 *  - data: the updated request object upon success to allow for ui context update or a failure message
 */
export const resendEmailChangeVerification = async (requestId) => {
  const endpoint = urlJoin(BASE_URL_REQUESTS, 'resendEmailChangeVerification');
  const result = await POST(endpoint, {
    requestId: requestId,
  });
  return result;
};
