//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//
import { ros1Versions, ros2Versions } from '../../RosUtils';
const compatibleRosVersions = [...ros1Versions, ...ros2Versions];

/**
 * Form Field Specifications
 *
 * The following arrays provide specifications on what fields to show on related forms
 */
export const rosmContentTypes = {
  field: 'contentType',
  type: 'Select',
  validation: '',
  options: [
    {
      title: 'Instantiation',
      label: 'Instantiation',
      value: 'instantiation',
      description: 'A ROSM Instantiation',
    },
    {
      title: 'Package',
      label: 'Package',
      value: 'package',
      description: 'A ROSM Package',
    },
  ],
};

/* ROS-M Form Field Specifications */
export const rosmFormFields = [
  {
    field: 'title',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Title',
    hoverDescription: 'Provide a short title.',
    placeholderDescription: 'Enter a title for the new content',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'description',
    type: 'Multiline',
    options: [],
    status: 1,
    required: true,
    label: 'Description',
    hoverDescription: 'Write a short, detailed description.',
    placeholderDescription: 'Enter a desccription for the new content',
    maxCharacters: 0,
    showInForms: [
      'component',
      'instantiation',
      'interface',
      'package',
      'profile',
    ],
  },
  {
    field: 'status',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Public',
        label: 'Public',
        value: 'public',
        status: 1,
        description:
          'The status of the data is public and viewable by all with CSR access',
      },
      {
        title: 'Private',
        label: 'Private',
        value: 'private',
        status: 1,
        description:
          'The status of the data is private and only viewable by you and high level access users',
      },
      {
        title: 'Draft',
        label: 'Draft',
        value: 'draft',
        status: 1,
        description:
          'The status of the data is draft and will/may be updated in the future as maturity level advances',
      },
    ],
    status: 1,
    required: true,
    label: 'Status',
    hoverDescription: 'Select the status of the entry',
    placeholderDescription: 'Select the status of the entry...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'distribution',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Distribution A',
        label: 'Distribution A',
        value: 'A',
        status: 1,
        description: 'Distribution A',
      },
      {
        title: 'Distribution B',
        label: 'Distribution B',
        value: 'B',
        status: 1,
        description: 'Distribution B',
      },
      {
        title: 'Distribution C',
        label: 'Distribution C',
        value: 'C',
        status: 1,
        description: 'Distribution C',
      },
      {
        title: 'Distribution D',
        label: 'Distribution D',
        value: 'D',
        status: 1,
        description: 'Distribution D',
      },
    ],
    status: 1,
    required: true,
    label: 'Distribution',
    hoverDescription: 'Select the distribution of the entry',
    placeholderDescription: 'Select the distribution of the entry...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'interfaces',
    type: 'AddTableArray',
    validation: '',
    options: [],
    status: 0,
    required: false,
    label: 'Interfaces',
    hoverDescription: 'Select associated interfaces',
    placeholderDescription:
      'No interfaces have been linked. Please add interfaces using the table below...',
    maxCharacters: 0,
    showInForms: ['component', 'instantiation', 'interface', 'profile'],
  },
  {
    field: 'components',
    type: 'AddTableArray',
    validation: '',
    options: [],
    status: 0,
    required: true,
    label: 'Components',
    hoverDescription: 'Select associated components',
    placeholderDescription:
      'No components have been linked. Please add components using the table below...',
    maxCharacters: 0,
    showInForms: ['component', 'instantiation'],
  },
  {
    field: 'trl',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'basicPrinciple1',
        label: '1 - Basic principles observed and reported',
        value: 1,
        status: 1,
        description: `Lowest level of technology readiness. Scientific
                    research begins to be translated into applied research and
                    development (R&D). Examples might include paper studies of
                    a technology's basic properties.`,
      },
      {
        title: 'technologyConcept2',
        label: '2 - Technology concept and/or application formulated',
        value: 2,
        status: 1,
        description: `Invention begins. Once basic principles are observed,
                    practical applications can be invented. Applications
                    are speculative, and there may be no proof or detailed
                    analysis to support the assumptions. Examples are
                    limited to analytic studies.`,
      },
      {
        title: 'analyticalExperimental3',
        label: '3 - Active R&D is initiated',
        value: 3,
        status: 1,
        description: `Active R&D is initiated. This includes analytical
                    studies and laboratory studies to physically validate
                    the analytical predictions of separate elements of the
                    technology. Examples include components that are not
                    yet integrated or representative.`,
      },
      {
        title: 'componentBreadboardLab4',
        label:
          '4 - Component and/or breadboard validation in laboratory environment',
        value: 4,
        status: 1,
        description: `Basic technological components are integrated to
                    establish that they will work together. This is
                    relatively “low fidelity” compared with the eventual
                    system. Examples include integration of “ad hoc”
                    hardware in the laboratory.`,
      },
      {
        title: 'componentBreadboardRelevant5',
        label:
          '5 - Component and/or breadboard validation in relevant environment',
        value: 5,
        status: 1,
        description: `Fidelity of breadboard technology increases
                    significantly. The basic technological components
                    are integrated with reasonably realistic supporting
                    elements so they can be tested in a simulated environment.
                    Examples include “high-fidelity” laboratory integration
                    of components.`,
      },
      {
        title: 'systemSubsystemModule6',
        label: '6 - System/subsystem module or prototype',
        value: 6,
        status: 1,
        description: `Representative model or prototype system, which is well
                    beyond that of TRL 5, is tested in a relevant environment.
                    Represents a major step up in a technology's demonstrated
                    readiness. Examples include testing a prototype in a
                    high-fidelity laboratory environment or in a simulated
                    operational environment.`,
      },
      {
        title: 'systemPrototype7',
        label: '7 - System prototype demo in an operational environment',
        value: 7,
        status: 1,
        description: `Prototype near or at planned operational system.
                    Represents a major step up from TRL 6 by requiring
                    demonstration of an actual system prototype in an
                    operational environment (e.g., in an aircraft, in a vehicle, or in space).`,
      },
      {
        title: 'techHasBeenProven8',
        label:
          '8 - Actual system completed and qualified through test and demonstration',
        value: 8,
        status: 1,
        description: `Technology has been proven to work in its final form
                    and under expected conditions. In almost all cases,
                    this TRL represents the end of true system development.
                    Examples include developmental test and evaluation (DT&E)
                    of the system in its intended weapon system to determine
                    if it meets design specification.`,
      },
      {
        title: 'actualSystemProven9',
        label: '9 - Actual system proven through successful mission operations',
        value: 9,
        status: 1,
        description: `Actual application of the technology in its final form and
                    under mission conditions, such as those encountered in operational
                    test and evaluation (OT&E). Examples include using the system under
                    operational mission conditions.`,
      },
      {
        title: 'unknown',
        label: '11 - Unknown',
        value: 11,
        status: 1,
        description: `Technology readiness is unknown or unsupported.`,
      },
    ],
    status: 1,
    required: true,
    label: 'TRL (Technology Readiness Level)',
    hoverDescription: 'TRL (Technology Readiness Level)?',
    placeholderDescription: '',
    maxCharacters: 0,
    showInForms: [
      'component',
      'instantiation',
      'interface',
      'profile',
      'Package',
    ],
  },
  {
    field: 'contact',
    type: 'Text',
    validation: 'Email',
    options: [],
    status: 1,
    required: false,
    label: 'Contact Email Address',
    hoverDescription: 'The email address of the contact for the data',
    placeholderDescription:
      'Enter the email address of the contact for the data',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'version',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Version',
    hoverDescription: 'Enter the version',
    placeholderDescription: '1.0.0',
    maxCharacters: 0,
    showInForms: ['package'],
  },
  {
    field: 'sourceUrl',
    type: 'Text',
    validation: 'URL',
    options: [],
    status: 1,
    required: true,
    label: 'Source URL',
    hoverDescription: 'A link to external source',
    placeholderDescription: 'Enter a link to the external source...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'tutorialUrl',
    type: 'Text',
    validation: 'URL',
    options: [],
    status: 1,
    required: true,
    label: 'Tutorial URL',
    hoverDescription: 'A link to external tutorial',
    placeholderDescription: 'Enter a link to the external tutorial...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'bugTrackingUrl',
    type: 'Text',
    validation: 'URL',
    options: [],
    status: 1,
    required: true,
    label: 'Bug Tracking URL',
    hoverDescription: 'A link to external bug tracking URL',
    placeholderDescription: 'Enter a link to the external bug tracking URL...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'category',
    type: 'Select',
    validation: '',
    options: null, // hits an endpoint to get options, need to parse in a React context
    status: 1,
    required: true,
    label: 'Category',
    hoverDescription: 'Select a category for your content',
    placeholderDescription: 'Select a category for your content...',
    maxCharacters: 0,
    showInForms: ['package'],
  },
  {
    field: 'dependencies',
    type: 'TagEntry',
    validation: '',
    options: [],
    status: 1,
    required: false,
    label: 'Dependency Tags',
    hoverDescription:
      'Please enter dependency tags by typing a word and pressing the enter key. You may edit a tag by double clicking on it.',
    placeholderDescription: 'Type Dependency Tag and press Enter...',
    maxCharacters: 0,
    showInForms: ['package'],
  },
  {
    field: 'compatibleRosVersions',
    type: 'MultiSelect',
    validation: '',
    options: compatibleRosVersions,
    status: 1,
    required: true,
    label: 'ROS Version(s)',
    hoverDescription: 'Please select compatible ROS versions.',
    placeholderDescription: 'Select Compatible ROS Versions...',
    maxCharacters: 0,
    showInForms: ['package'],
  },
  {
    field: 'tags',
    type: 'TagEntry',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Meta Tags',
    hoverDescription:
      'Please enter meta tags to describe your entry by typing a word and pressing the enter key. You may edit a tag by double clicking on it.',
    placeholderDescription: 'Type Meta Tag and press Enter...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
];
