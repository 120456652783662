import { ReactComponent as LogoSvg } from './rosmIcon.svg';
import { config } from '../app/Config';
import { Typography } from '@mui/material';

function RosmLogo() {

    
    return config.REACT_APP_THEME === 'NATO' ?
    <>
        <div style={{ width: '120px', height: '60px', textAlign: 'center' }}>
            <img
                width='120px'
                height='60px'
                alt='NATO logo'
                src={config.PUBLIC_URL + '/static/images/logos/nato_logo.png'}
            />
        </div>
    </>
    :
    <>
    <LogoSvg
        style={{ width: '35px', height: '35px', marginRight: '30px' }}
    />
        <Typography variant='h5' component='div'>
            ROS-M
        </Typography>
    </>;    

};

export default RosmLogo;
