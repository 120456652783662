//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import './Search.css';

import { Loading } from '../components/Pages/index';
import { SearchContext } from '../components/Search/SearchContext';
import { postSearchRequest } from '../helpers/api/search';

import SearchResultItemView from '../components/Search/SearchResultItemView';
import SearchFilters from '../components/Search/Filters/SearchFilters';
import { FILTER_CONTROLS } from '../components/Search/Filters/SearchFilterControls';
/**
 * TODO move to the actions section
 */
const SEARCH_ACTIONS = {
  FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const searchReducer = (state, action) => {
  switch (action.type) {
    case SEARCH_ACTIONS.FETCH_SEARCH_RESULTS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case SEARCH_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        searchResults: action.data,
      };
    }
    case SEARCH_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        searchResults: [],
        error: true,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialSearchReducer = {
  searchResults: null,
  loading: false,
  loaded: false,
  error: false,
};

let incomingUrlParams = {};
let urlParams = {};
let hasIncomingParameters = false;

function SearchPage() {
  const searchContext = useContext(SearchContext);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  /**
   * Get the incoming URL Params from address bar and Return as Object
   */
  const getIncomingUrlParams = () => {
    for (const [key, value] of searchParams.entries()) {
      incomingUrlParams[key] = value;
    }
    return incomingUrlParams;
  };

  // On first page load, declare URL parameters based on defaults or URL params if available
  useEffect(() => {
    // Check if there are any incoming URL params
    getIncomingUrlParams();
    hasIncomingParameters = JSON.stringify(incomingUrlParams) !== '{}';

    /**
     * Maps the default filter controls returning only true values
     * @returns object
     */
    const mapControls = () => {
      let urlParamsMapped = {};
      FILTER_CONTROLS.map((fItem) => {
        fItem.options.map((fItemOption) => {
          if (fItemOption.checked) {
            urlParamsMapped[`${fItem.field}-${fItemOption.field}`] =
              fItemOption.checked;
          }
        });
      });
      return urlParamsMapped;
    };
    urlParams = hasIncomingParameters ? incomingUrlParams : mapControls();
  }, []);

  const [state, dispatch] = useReducer(searchReducer, initialSearchReducer);

  const { searchResults, loading, error } = state;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSearchResults = async () => {
    dispatch({
      type: SEARCH_ACTIONS.FETCH_DOCUMENTATION_LIST,
    });

    try {
      const response = await postSearchRequest({
        query: {
          searchTerm: searchContext.query,
          resultsPerPage: rowsPerPage,
          currentPage: page,
          sortList: [
            {
              field: searchContext.sortBy.split('-')[0],
              direction: searchContext.sortBy.split('-')[1],
            },
          ],
        },
        options: searchContext.filters,
      });
      const data = await response.json();

      if (
        response.status === 200 &&
        data &&
        data.status === 'ok' &&
        data.results
      ) {
        dispatch({ type: SEARCH_ACTIONS.SUCCESS, data });
      } else {
        dispatch({
          type: SEARCH_ACTIONS.ERROR,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_ACTIONS.ERROR,
        error: 'error',
      });
    }
  };

  // search query updates
  useEffect(() => {
    getSearchResults(searchContext.query);
  }, [
    searchContext.query,
    rowsPerPage,
    page,
    searchContext.filters,
    searchContext.sortBy,
  ]);

  if (loading) {
    return <Loading />;
  }

  if (!searchResults) {
    return <Loading />;
  }

  const handleClick = (e) => {
    const { _sourceId, _baseType, _contentType, _docType } = e;
    let url;
    if (_baseType === 'rosm') {
      url = `${_docType}s/${_sourceId}`;
    } else {
      url = `${_docType}/${_contentType.substring(3)}/${_sourceId}`;
    }
    navigate(`/${url}`);
  };

  let searchResultsComponent;
  if (searchResults.totalResults > 0) {
    const resultItems = searchResults.results.map((result, index) => {
      return (
        <ListItemButton
          key={`r-${index}`}
          onClick={() => handleClick(result)}
          sx={{ width: '100%' }}
        >
          <SearchResultItemView result={result} />
        </ListItemButton>
      );
    });

    searchResultsComponent = (
      <List
        sx={{ width: '100%', bgcolor: 'transparent' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        {resultItems}
      </List>
    );
  } else if (error) {
    searchResultsComponent = (
      <SearchResultsHeader title={'Error Getting Search Results For Query'} />
    );
  } else {
    searchResultsComponent = (
      <SearchResultsHeader title={'No Search Results Available For Query'} />
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <SearchFilters
            incomingUrlParams={incomingUrlParams}
            hasIncomingParameters={hasIncomingParameters}
            urlParams={urlParams}
            result={searchContext.query}
          />
        </Grid>
        <Grid item xs>
          {!error && (
            <TablePagination
              style={{ display: 'flex' }}
              component='div'
              count={searchResults.totalResults}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
          {searchResultsComponent}
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchPage;

function SearchResultsHeader({ title }) {
  return (
    <Typography
      style={{ padding: '12px', textAlign: 'center' }}
      variant='h6'
      gutterBottom
      noWrap
      component='div'
    >
      {title}
    </Typography>
  );
}
