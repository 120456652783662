//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import ACTIONS from './contextActions';

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIONS.UNABLE_TO_CONNECT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'unable to connect',
      };
    }
    case ACTIONS.USER: {
      return {
        ...state,
        loading: false,
        loaded: true,
        userData: action.data,
      };
    }
    case ACTIONS.ERROR_USER: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'error getting user',
      };
    }

    case ACTIONS.FETCH_VERIFY_NEW_EMAIL: {
      return {
        ...state,
        loadingValidateNewEmail: true,
      };
    }
    case ACTIONS.VERIFY_NEW_EMAIL: {
      return {
        ...state,
        loadingValidateNewEmail: false,
        loadedValidateNewEmail: true,
        validateNewEmail: action.data,
      };
    }
    case ACTIONS.ERROR_VERIFY_NEW_EMAIL: {
      return {
        ...state,
        loadingValidateNewEmail: false,
        loadedValidateNewEmail: false,
        errorValidateNewEmail: 'error verifying email',
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
