//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

export const categoryMapping = {
  //
  1: 'planning.png',
  2: 'planning.png',
  3: 'planning.png',
  4: 'planning.png',
  //
  5: 'perception.png',
  6: 'perception.png',
  7: 'perception.png',
  8: 'perception.png',
  9: 'perception.png',
  //
  10: 'mapping.png',
  11: 'mapping.png',
  12: 'mapping.png',
  13: 'mapping.png',
  //
  14: 'localization.png',
  15: 'localization.png',
  16: 'localization.png',
  17: 'localization.png',
  //
  18: 'hardware-drivers.png',
  19: 'hardware-drivers.png',
  20: 'hardware-drivers.png',
  21: 'hardware-drivers.png',
  22: 'hardware-drivers.png',
  23: 'hardware-drivers.png',
  24: 'hardware-drivers.png',
  //
  25: 'utilities.png',
  26: 'utilities.png',
  27: 'utilities.png',
  28: 'utilities.png',
  //
  29: 'protocols.png',
  30: 'protocols.png',
  31: 'protocols.png',
  32: 'protocols.png',
  33: 'protocols.png',
  //
  34: 'models.png',
  //
  35: 'simulation.png',
  36: 'simulation.png',
  37: 'simulation.png',
  38: 'simulation.png',
  //
  39: 'autonomy.png',
  40: 'autonomy.png',
  //
  41: 'planning.png',
};
