//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

export const ros2Versions = [
  'ardent',
  'bouncy',
  'crystal',
  'dashing',
  'eloquent',
  'foxy',
  'galactic',
  'humble',
];

export const ros1Versions = [
  'noetic',
  'melodic',
  'lunar',
  'kinetic',
  'jade',
  'indigo',
  'hydro',
  'groovy',
  'fuerte',
  'electric',
  'diamondback',
  'c turtle',
  'box turtle',
];

// all versions
export const allRosVersions = [...ros1Versions, ...ros2Versions];

// Trl levels
export const trlLevels = [
  {
    value: 0,
    primary: 'Unknown',
    secondary: `Technology readiness is unknown or unsupported.`,
  },
  {
    value: 1,
    primary: 'Basic principles observed and reported',
    secondary: `Lowest level of technology readiness. Scientific
                research begins to be translated into applied research and
                development (R&D). Examples might include paper studies of
                a technology's basic properties.`,
  },
  {
    value: 2,
    primary: 'Technology concept and/or application formulated',
    secondary: `Invention begins. Once basic principles are observed,
                practical applications can be invented. Applications
                are speculative, and there may be no proof or detailed
                analysis to support the assumptions. Examples are
                limited to analytic studies.`,
  },
  {
    value: 3,
    primary:
      'Analytical and experimental critical function and/or characteristic proof of concept',
    secondary: `Active R&D is initiated. This includes analytical
                studies and laboratory studies to physically validate
                the analytical predictions of separate elements of the
                technology. Examples include components that are not
                yet integrated or representative.`,
  },
  {
    value: 4,
    primary: 'Component and/or breadboard validation in laboratory environment',
    secondary: `Basic technological components are integrated to
                establish that they will work together. This is
                relatively “low fidelity” compared with the eventual
                system. Examples include integration of “ad hoc”
                hardware in the laboratory.`,
  },
  {
    value: 5,
    primary: 'Component and/or breadboard validation in relevant environment',
    secondary: `Fidelity of breadboard technology increases
                significantly. The basic technological components
                are integrated with reasonably realistic supporting
                elements so they can be tested in a simulated environment.
                Examples include “high-fidelity” laboratory integration
                of components.`,
  },
  {
    value: 6,
    primary:
      'System/subsystem model or prototype demonstration in a relevant environment',
    secondary: `Representative model or prototype system, which is well
                beyond that of TRL 5, is tested in a relevant environment.
                Represents a major step up in a technology's demonstrated
                readiness. Examples include testing a prototype in a
                high-fidelity laboratory environment or in a simulated
                operational environment.`,
  },
  {
    value: 7,
    primary: 'System prototype demonstration in an operational environment',
    secondary: `Prototype near or at planned operational system.
                Represents a major step up from TRL 6 by requiring
                demonstration of an actual system prototype in an
                operational environment (e.g., in an aircraft, in a vehicle, or in space).`,
  },
  {
    value: 8,
    primary:
      'Actual system completed and qualified through test and demonstration',
    secondary: `Technology has been proven to work in its final form
                and under expected conditions. In almost all cases,
                this TRL represents the end of true system development.
                Examples include developmental test and evaluation (DT&E)
                of the system in its intended weapon system to determine
                if it meets design specification.`,
  },
  {
    value: 9,
    primary: 'Actual system proven through successful mission operations',
    secondary: `Actual application of the technology in its final form and
                under mission conditions, such as those encountered in operational
                test and evaluation (OT&E). Examples include using the system under
                operational mission conditions.`,
  },
];

// maturity levels
export const maturityLevels = [
  { value: 0, primary: 'None', secondary: 'Package has no maturity level' },
  {
    value: 1,
    primary: 'Unknown',
    secondary: 'The maturity is not known for the given package',
  },
  {
    value: 2,
    primary: 'Development',
    secondary:
      'Package is mostly complete and some level of testing has occurred on development platforms',
  },
  {
    value: 3,
    primary: 'Prototype',
    secondary: 'Package is in the initial stages of development',
  },
  {
    value: 4,
    primary: 'Production',
    secondary:
      'Package has been implemented and tested on at least one production-intent platform',
  },
];

export const availableLicenses = [
  'United States Government Purpose',
  'United States Government Rights Unlimited',
  'Academic Free License v3.0',
  'Apache license 2.0',
  'Artistic license 2.0',
  'Boost Software License 1.0',
  'BSD',
  'BSD 2-clause "Simplified" license',
  'BSD 3-clause "New" or "Revised" license',
  'BSD 3-clause Clear license',
  'Creative Commons license family',
  'Creative Commons Zero v1.0 Universal',
  'Creative Commons Attribution 4.0',
  'Creative Commons Attribution Share Alike 4.0',
  'Educational Community License v2.0',
  'Eclipse Public License 1.0',
  'European Union Public License 1.1',
  'GNU Affero General Public License v3.0',
  'GNU General Public License family',
  'GNU General Public License v2.0',
  'GNU General Public License v3.0',
  'GNU Lesser General Public License family',
  'GNU Lesser General Public License v2.1',
  'GNU Lesser General Public License v3.0',
  'ISC',
  'LaTeX Project Public License v1.3c',
  'Microsoft Public License',
  'MIT',
  'Mozilla Public License 2.0',
  'Open Software License 3.0',
  'PostgreSQL License',
  'SIL Open Font License 1.1',
  'University of Illinois/NCSA Open Source License',
  'The Unlicense',
  'zLib License',
];
