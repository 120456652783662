//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

/** Helper Imports */
import { verifyEmail } from '../../helpers';

/** Styles */
import { BoxContainer, SuccessIcon, FailureIcon } from './styles';

/** Assets */
import RosmLogo from './assets/rosmIcon.svg';

/** Icons */
import LoginIcon from '@mui/icons-material/Login';

import { Header, Loading } from '../../components/Pages';

const VERIFY_ACTIONS = {
  FETCH_VERIFY: 'FETCH_VERIFY',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const verifyReducer = (state, action) => {
  switch (action.type) {
    case VERIFY_ACTIONS.ROSM_PACKAGES_ACTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case VERIFY_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        validEmail: action.data,
      };
    }
    case VERIFY_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialVerifyState = {
  validEmail: null,
  loading: false,
  loaded: false,
  error: null,
};

/**
 * Email Verification
 * Show the email verification success/failure message
 */

function VerifyEmail() {
  const [state, dispatch] = useReducer(verifyReducer, initialVerifyState);
  const { validEmail, loading, error, loaded } = state;

  let { verificationHash } = useParams();

  /* On First Mount: Verify email with Server API (Once w/ No Dependency Reference) */
  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: VERIFY_ACTIONS.FETCH_VERIFY });
      const fetchData = async () => {
        const result = await verifyEmail(verificationHash);
        const data = await result.json();
        if (result.status === 200) {
          dispatch({ type: VERIFY_ACTIONS.SUCCESS, data: data });
          return;
        } else {
          dispatch({
            type: VERIFY_ACTIONS.ERROR,
            error: result.error,
          });
        }
      };

      fetchData();
    }
  }, []);

  return (
    <>
      {error && <h4>error</h4>}
      {loading ? (
        <Loading />
      ) : (
        <Container component='main' maxWidth='lg'>
          <BoxContainer>
            <img
              style={{ marginTop: '12px' }}
              src={RosmLogo}
              width={60}
              alt='ROS Logo'
            />
            <Header title={'ROS-M Email Verification'} />
            <Grid container sx={{ mt: 4 }}>
              {(error || !validEmail) && loaded ? (
                <>
                  <Grid item xs={2}>
                    <FailureIcon />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 2 }}>
                          Invalid Email or Error During Verification
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 26, fontWeight: 'bold', mb: 1 }}
                        >
                          The email validation process was unsuccessful
                        </Typography>
                        <Typography variant='body1'>
                          The email verification link that was used was invalid
                          or expired. The email address may have already been
                          validated.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <SuccessIcon />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 2 }}>
                          Email Verified!
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 26, fontWeight: 'bold', mb: 1 }}
                        >
                          You have successfully verified your email address
                        </Typography>
                        <Typography variant='body1'>
                          You may now login with your new email address.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          startIcon={<LoginIcon />}
                          sx={{ fontSize: 20, mt: 6, width: '100%' }}
                          size='large'
                          href='/'
                        >
                          Visit The Login Form
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </BoxContainer>
        </Container>
      )}
    </>
  );
}

export default VerifyEmail;
