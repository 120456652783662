import { GET, CATEGORY_URL } from '../../helpers/api';

export const GET_CATEGORIES = 'GET_ALL_CATEGORIES';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';
export const SET_ERRORS = 'SET_ERRORS';

/**
 *
 */
export function fetchCategories() {
  return (dispatch) => {
    return GET(CATEGORY_URL)
      .then((req) => req.json())
      .then((json) => dispatch(addCategories(json)))
      .catch((err) => dispatch(setErrors(err)));
  };
}

/**
 *
 */
export const getCategories = () => ({
  type: GET_CATEGORIES,
});

/**
 *
 */
export const addCategories = (categoriesData) => ({
  type: ADD_CATEGORIES,
  categoriesData,
});

/**
 *
 */
export const setErrors = (errors) => ({
  type: SET_ERRORS,
  errors,
});
