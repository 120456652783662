//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { ros1Versions, ros2Versions } from '../../../helpers/RosUtils';

const defaultSelected = ['noetic', 'melodic', 'kinetic', 'humble'];

const rosOptions1 = ros1Versions.map((rV) => {
  return {
    name: `${rV} - ROS1`,
    field: rV,
    checked: defaultSelected.includes(rV),
  };
});

const rosOptions2 = ros2Versions.map((rV) => {
  return {
    name: `${rV} - ROS2`,
    field: rV,
    checked: defaultSelected.includes(rV),
  };
});

const rosOptions = [...rosOptions1, ...rosOptions2];

export const FILTER_CONTROLS = [
  {
    title: 'Type',
    field: '_contentType',
    description: 'Type stored in the registry',
    type: 'Checkbox',
    options: [
      {
        name: 'Package',
        field: 'package',
        checked: true,
        isCsr: false,
      },
      {
        name: 'Instantiation',
        field: 'instantiation',
        checked: true,
        isCsr: false,
      },
      {
        name: 'Project',
        field: 'CsrProject',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Hardware',
        field: 'CsrHardware',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Model',
        field: 'CsrModel',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Planning Document',
        field: 'CsrPlanningDocument',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Requirement',
        field: 'CsrRequirement',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Software',
        field: 'CsrSoftware',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Standard',
        field: 'CsrStandard',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Test',
        field: 'CsrTest',
        checked: true,
        isCsr: true,
      },
      {
        name: 'Tools',
        field: 'CsrTool',
        checked: true,
        isCsr: true,
      },
    ],
  },
  {
    title: 'Distribution',
    field: 'distributionStatement',
    description: 'Distribution of entry stored in the registry',
    type: 'Checkbox',
    options: [
      {
        name: 'A',
        field: 'A',
        checked: true,
      },
      {
        name: 'B',
        field: 'B',
        checked: true,
      },
      {
        name: 'C',
        field: 'C',
        checked: true,
      },
      {
        name: 'D',
        field: 'D',
        checked: true,
      },
    ],
  },
  {
    title: 'Maturity Level',
    field: 'maturityLevel',
    description: 'Maturity entry stored in the registry',
    type: 'Checkbox',
    options: [
      {
        name: 'None',
        field: 0,
        checked: false,
      },
      {
        name: 'Unknown',
        field: 1,
        checked: true,
      },
      {
        name: 'Development',
        field: 2,
        checked: true,
      },
      {
        name: 'Prototype',
        field: 3,
        checked: true,
      },
      {
        name: 'Production',
        field: 4,
        checked: true,
      },
    ],
  },
  {
    title: 'ROS Version',
    field: 'compatibleRosVersions',
    description: 'Compatible Ros Versions stored in the registry',
    type: 'Checkbox',
    options: rosOptions,
  },
];
