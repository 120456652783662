//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { GET, BASE_CSR_DOCUMENTATION_URL, PUT } from './index';
import urlJoin from 'url-join';

// TODO reduce mapping needs by using directly
export const CSR_MAPPING = {
  projects: 'CsrProject',
  hardware: 'CsrHardware',
  model: 'CsrModel',
  planningDocument: 'CsrPlanningDocument',
  requirement: 'CsrRequirement',
  software: 'CsrSoftware',
  test: 'CsrTest',
  tool: 'CsrTool',
  standard: 'CsrStandard',
};

/**
 *
 * @param {*} documentationType
 * @returns
 */
export function getByType(documentationType) {
  const contentType = CSR_MAPPING[documentationType];

  return getCsrDocumentationUrl(contentType);
}

/**
 *
 * @param {*} documentationType
 * @param {*} documentationId
 * @returns
 */
export function getByTypeById(documentationType, documentationId) {
  return getCsrDocumentationUrlById(documentationId);
}

/**
 *
 * @param {*} packageId
 * @param {*} packageContent
 * @returns
 */
export function updateDocumentationById(documentationId, documentationContent) {
  return PUT(
    BASE_CSR_DOCUMENTATION_URL + `/${documentationId}`,
    documentationContent
  );
}

/**
 *
 * @param {*} contentType
 * @returns
 */
export function getCsrDocumentationUrl(contentType) {
  const url = urlJoin(
    BASE_CSR_DOCUMENTATION_URL,
    `?documentType=${contentType}`
  );
  return GET(url);
}

/**
 *
 * @param {*} contentId
 * @returns
 */
export function getCsrDocumentationUrlById(contentId) {
  const url = urlJoin(BASE_CSR_DOCUMENTATION_URL, contentId);
  return GET(url);
}
