/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useState, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Avatar from '@mui/material/Avatar';
import { DISTRIBUTION_COLORS } from '../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../components/ContentCard/StatusMapping';
import InfoIcon from '@mui/icons-material/Info';
import { remark } from 'remark';
import ReactMarkdown from 'react-markdown';
import strip from 'strip-markdown';
import { VirtualTable } from '@rosm/rosm-ng-components/dist/components/ListContent';

// Rosm Icons
import BarChartIcon from '@mui/icons-material/BarChart'; // RosmPackage
import LayersIcon from '@mui/icons-material/Layers'; // RosmInstantiation

import Icon from '@mdi/react';
// Documentation Type Icons
import {
  mdiFileStar, // Projects
  mdiCogTransfer, // Hardware
  mdiApplicationCog, // Model
  mdiFileDocumentAlert, // Planning
  mdiFormatListText, // Requirement
  mdiCodeJson, // Software
  mdiTextBoxCheck, // Standard
  mdiHistory, // Test
  mdiHammerWrench, // Tool
} from '@mdi/js';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const HEADERS = [
  { title: '', align: 'center', value: '', width: '10%' },
  { title: 'Association', align: 'left', value: 'association', width: '10%' },
  { title: 'Title', align: 'left', value: 'title', width: '25%' },
  { title: 'Grouping', align: 'center', width: '5%' },
  { title: 'Content Type', align: 'center', width: '10%' },
  { title: 'Custodian', align: 'center', width: '10%' },
  { title: 'Version', align: 'center', width: '5%' },
  { title: 'Status', align: 'center', width: '5%' },
  { title: 'Distribution', align: 'left', width: '5%' },
  { title: 'Last Updated', align: 'right', width: '10%' },
];

const IconMapping = {
  RosmPackage: <BarChartIcon fontSize='large' />,
  RosmInstantiation: <LayersIcon fontSize='large' />,
  CsrProject: <Icon path={mdiFileStar} size={1.5} />,
  CsrHardware: <Icon path={mdiCogTransfer} size={1.5} />,
  CsrModel: <Icon path={mdiApplicationCog} size={1.5} />,
  CsrPlanningDocument: <Icon path={mdiFileDocumentAlert} size={1.5} />,
  CsrRequirement: <Icon path={mdiFormatListText} size={1.5} />,
  CsrSoftware: <Icon path={mdiCodeJson} size={1.5} />,
  CsrStandard: <Icon path={mdiTextBoxCheck} size={1.5} />,
  CsrTest: <Icon path={mdiHistory} size={1.5} />,
  CsrTool: <Icon path={mdiHammerWrench} size={1.5} />,
};

const ContentTypeNameMapping = {
  RosmPackage: 'Package',
  RosmInstantiation: 'Instantiation',
  CsrProject: 'Project',
  CsrHardware: 'Hardware',
  CsrModel: 'Model',
  CsrPlanningDocument: 'Planning Document',
  CsrRequirement: 'Requirement',
  CsrSoftware: 'Software',
  CsrStandard: 'Standard',
  CsrTest: 'Test',
  CsrTool: 'Tool',
};

const MyContentList = ({ rows }) => {
  const [descriptions, setDescriptions] = useState({});

  let navigate = useNavigate();

  const stripMarkdown = async (s) => {
    return await remark()
      .use(strip)
      .process(s.replace(/\t/g, ' ')) // replace tabs to ignore markdown monospace (otherwise all monospace text gets stripped)
      .then((s) => {
        const newDesc = s.value.replace(/(?:\r\n|\r|\n)/g, ' ');
        return newDesc.length > 150
          ? `${newDesc.substring(0, 150)}...`
          : newDesc;
      });
  };

  useEffect(() => {
    async function cleanDescriptions() {
      let descs = {};
      for (const r in rows) {
        const data = rows[r];
        const desc = await stripMarkdown(data.description);
        descs[data._id] = desc;
      }
      setDescriptions(descs);
    }
    cleanDescriptions();
  }, [rows]);

  const handleClick = (row) => {
    const id = row._id.toString();
    switch (row.contentType) {
      case 'RosmPackage':
        navigate(`/packages/${id}`);
        break;
      case 'RosmInstantiation':
        navigate(`/instantiations/${id}`);
        break;
      case 'CsrProject':
        navigate(`/documentation/projects/${id}`);
        break;
      case 'CsrHardware':
        navigate(`/documentation/hardware/${id}`);
        break;
      case 'CsrModel':
        navigate(`/documentation/model/${id}`);
        break;
      case 'CsrPlanningDocument':
        navigate(`/documentation/planningDocument/${id}`);
        break;
      case 'CsrRequirement':
        navigate(`/documentation/requirement/${id}`);
        break;
      case 'CsrSoftware':
        navigate(`/documentation/software/${id}`);
        break;
      case 'CsrStandard':
        navigate(`/documentation/standard/${id}`);
        break;
      case 'CsrTest':
        navigate(`/documentation/test/${id}`);
        break;
      case 'CsrTool':
        navigate(`/documentation/tool/${id}`);
        break;
      default:
        break;
    }
  };

  const headerItems = () =>
    HEADERS.map((header) => {
      return (
        <TableCell
          style={{
            width: header.width,
            minWidth: header.width,
            maxWidth: header.width,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          align={header.align}
        >{`${header.title}`}</TableCell>
      );
    });

  const rowItems = (index, row) => {
    const d = dayjs(row.updatedDate).utc('z');
    const d1 = dayjs().utc('z');
    const diff = d.diff(d1);
    const duration = dayjs.duration(diff).humanize(true);

    const title =
      row.title.length > 50 ? `${row.title.substring(0, 50)}...` : row.title;

    const tagChips = row.tags.map((tag) => {
      return (
        <>
          <Chip
            color='primary'
            sx={{
              '& .MuiChip-label': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              marginRight: '4px',
              borderRadius: '4px',
              height: '21px',
              margin: '4px',
            }}
            label={tag}
          />
          <br />
        </>
      );
    });
    const associationChips = row.associationTypes?.map((tag) => {
      return (
        <>
          <Chip
            color='primary'
            sx={{
              '& .MuiChip-label': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              marginRight: '4px',
              borderRadius: '4px',
              height: '21px',
              margin: '4px',
            }}
            label={tag}
          />
          <br />
        </>
      );
    });

    return (
      <>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='center'
        >
          {row?.images?.length === 0 ? (
            <div style={{ marginTop: '25px' }}>
              {IconMapping[row.contentType]}
            </div>
          ) : (
            <div style={{ marginTop: '4px' }}>
              <img width={'120px'} height={'100px'} src={row.images[0]} />
            </div>
          )}
        </TableCell>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          {associationChips}
        </TableCell>
        <TableCell
          style={{
            width: '25%',
            minWidth: '25%',
            maxWidth: '25%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div>
            <div style={{ marginBottom: '4px' }}>{title}</div>
            <div style={{ marginBottom: '4px', whiteSpace: 'pre-wrap' }}>
              <ReactMarkdown
                children={descriptions[row._id.toString()]}
                remarkPlugins={[strip]}
              />
            </div>
            <div>{tagChips}</div>
          </div>
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <Chip
            color={
              row.contentType.substring(0, 3).toLowerCase() === 'csr'
                ? 'warning'
                : 'success'
            }
            sx={{
              '& .MuiChip-label': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              marginRight: '4px',
              borderRadius: '4px',
              height: '21px',
            }}
            label={
              row.contentType.substring(0, 3).toLowerCase() === 'csr'
                ? 'CSR'
                : 'ROSM'
            }
          />
        </TableCell>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
          }}
          align='center'
        >
          <Chip
            color='primary'
            sx={{
              '& .MuiChip-label': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
              marginRight: '4px',
              borderRadius: '4px',
              height: '21px',
            }}
            label={ContentTypeNameMapping[row.contentType].toUpperCase()}
          />
        </TableCell>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
          }}
          align='center'
        >
          {row.custodian}
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <Tooltip title={row.version ? row.version : 'No version available.'}>
            <Typography>
              {row.version
                ? row.version.substring(0, 7) +
                  (row.version.length > 7 ? '...' : '')
                : '---'}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
            {STATUS_MAPPING[row.status]}
          </Tooltip>
        </TableCell>
        <TableCell
          style={{
            width: '5%',
            minWidth: '5%',
            maxWidth: '5%',
          }}
          align='center'
        >
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              sx={{ bgcolor: DISTRIBUTION_COLORS[row.distributionStatement] }}
            >
              {row.distributionStatement}
            </Avatar>
          </div>
        </TableCell>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
          }}
          align='right'
        >
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
              <InfoIcon />
            </Tooltip>
            <div
              style={{
                paddingLeft: '4px',
                marginTop: '2px',
              }}
            >
              {duration}
            </div>
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <VirtualTable
      styles={null}
      rows={rows}
      handleClick={handleClick}
      headerItems={headerItems}
      rowItems={rowItems}
    />
  );
};

MyContentList.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default MyContentList;
