//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useReducer, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@mui/material';
import { categoryMapping } from '../../utilities/CategoryMapping';
import { DISTRIBUTION_COLORS } from '../../helpers/distribution';
import { getCategories } from '../../helpers/api/category';
import {
  GET_CATEGORIES,
  ADD_CATEGORIES,
  SET_ERRORS,
} from '../../redux/actions/categories';
import categoriesReducer from '../../redux/reducers/categories';
import { Loading } from '../Pages';

const initialCategoriesState = {
  categories: [],
  loading: false,
  loaded: false,
  error: null,
};

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const SearchResultItemView = ({ result, onClickLink }) => {
  const d = dayjs(result.updatedDate).utc('z');
  const d1 = dayjs().utc('z');
  const diff = d.diff(d1);
  const duration = dayjs.duration(diff).humanize(true);

  const getCategoriesData = async () => {
    dispatch({ type: GET_CATEGORIES });
    try {
      const response = await getCategories();
      const data = await response.json();
      if (response.status === 200 && data) {
        dispatch({ type: ADD_CATEGORIES, data });
      } else {
        dispatch({
          type: SET_ERRORS,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ERRORS,
        error: 'error',
      });
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  const [state, dispatch] = useReducer(
    categoriesReducer,
    initialCategoriesState
  );
  const { categories, loading, loaded } = state;

  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    let rosmCategories = [];
    categories.map((category) => {
      rosmCategories.push({
        title: category.name,
        label: category.name,
        value: category.categoryId,
        status: 1,
        description: category.name,
        img: `${category.primary.toLowerCase()}.png`,
      });
    });
    setCategoryData(rosmCategories);
  }, [categories]);

  if (
    loading ||
    !loaded ||
    categoryData === null ||
    categoryData.length === 0
  ) {
    return <Loading />;
  }

  const tagChips = result.tags.map((tag, index) => {
    return (
      <Chip
        key={`${index}_${tag}`}
        size={'small'}
        color='primary'
        sx={{ marginRight: '4px' }}
        label={tag.toUpperCase()}
      />
    );
  });

  // limit the title
  const title = result.title
    ? result.title.length > 200
      ? `${result.title.substring(0, 200)}...`
      : result.title
    : '---';

  // limit the description
  const description = result.description
    ? result.description.length > 250
      ? `${result.description.substring(0, 250)}...`
      : result.description
    : '---';

  let imageUrl, contentChip, contentTooltip;
  if (result._baseType === 'rosm') {
    if (result._docType === 'package') {
      imageUrl =
        process.env.PUBLIC_URL +
        `/images/categories/${categoryMapping[result.category]}`;
      contentTooltip = `PACKAGE - ${categoryData[
        result.category
      ].title.toUpperCase()}`;
      contentChip = (
        <>
          <Chip
            color={'primary'}
            style={{ marginRight: '4px' }}
            size={'small'}
            label={result._docType.toUpperCase()}
          />
          <Chip
            color={'primary'}
            style={{ marginRight: '4px' }}
            size={'small'}
            label={categoryData[result.category].title.toUpperCase()}
          />
        </>
      );
    } else if (result._docType === 'instantiation') {
      imageUrl =
        result.images.length > 0
          ? result.images[0]
          : process.env.PUBLIC_URL + `/images/contentType/Instantiation.png`;

      contentTooltip = result._docType.toUpperCase();
      contentChip = (
        <Chip
          color={'primary'}
          style={{ marginRight: '4px' }}
          size={'small'}
          label={result._docType.toUpperCase()}
        />
      );
    }
  } else if (result._baseType === 'csr') {
    imageUrl =
      result.images.length > 0
        ? result.images[0]
        : process.env.PUBLIC_URL +
          `/images/contentType/${result._contentType.substring(3)}.png`;

    contentTooltip = result._contentType.substring(3).toUpperCase();
    contentChip = (
      <Chip
        color={'primary'}
        style={{ marginRight: '4px' }}
        size={'small'}
        label={result._contentType.substring(3).toUpperCase()}
      />
    );
  }

  let rosVersions = <></>;
  if (result.compatibleRosVersions) {
    if (result.compatibleRosVersions.length === 1) {
      rosVersions = (
        <Chip
          color={'secondary'}
          size={'small'}
          sx={{ marginLeft: '12px', color: 'white' }}
          label={result.compatibleRosVersions.join(' | ')}
        />
      );
    } else if (result.compatibleRosVersions.length > 1) {
      rosVersions = (
        <Tooltip title={result.compatibleRosVersions.length}>
          <Chip
            color={'secondary'}
            size={'small'}
            sx={{
              marginLeft: '12px',
              color: 'white',
              textTransform: 'uppercase',
            }}
            label={result.compatibleRosVersions.join(' | ')}
          />
        </Tooltip>
      );
    }
  }

  return (
    <Card sx={{ display: 'flex', width: '100%' }} className='result-item'>
      <div>
        <Tooltip title={contentTooltip}>
          <img
            style={{
              height: 45,
              width: 45,
              padding: '4px',
              borderRadius: '8px',
              marginBotton: '10px',
              marginTop: '15px',
              marginLeft: '15px',
            }}
            src={imageUrl}
            alt='image'
          />
        </Tooltip>
        <div style={{ marginLeft: '23px', marginTop: '15px' }}>
          <Tooltip title={`Distribution ${result.distributionStatement}`}>
            <Avatar
              sx={{
                width: 26,
                height: 26,
                bgcolor: DISTRIBUTION_COLORS[result.distributionStatement],
              }}
            >
              {result.distributionStatement}
            </Avatar>
          </Tooltip>
        </div>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant='h6' component='div'>
            {title}
            {result.version && (
              <Chip
                color={'secondary'}
                size={'small'}
                sx={{ marginLeft: '12px', color: 'white' }}
                label={result.version}
              />
            )}
            {rosVersions}
          </Typography>
          <Typography variant='caption' display='block' gutterBottom>
            {`Updated ${duration}`}
          </Typography>

          <Typography variant='body2'>{description}</Typography>
          <div style={{ display: 'inline-flex' }}>
            {/* <Typography variant='body2'>{`Date: ${result.releaseDate}`}</Typography>
            <span style={{ paddingLeft: '4px', paddingRight: '4px' }} />
            <Typography variant='body2'>{`Version: ${result.version}`}</Typography>
            <span style={{ paddingLeft: '4px', paddingRight: '4px' }} />
            <Typography variant='body2'>{`Status: ${result.status.toUpperCase()}`}</Typography>
            <span style={{ paddingLeft: '4px', paddingRight: '4px' }} />
            <Typography variant='body2'>{`Status: ${result.custodian}`}</Typography> */}
          </div>

          <>
            <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
            <Chip
              color={'primary'}
              size={'small'}
              sx={{ marginRight: '4px' }}
              label={result._baseType.toUpperCase()}
            />
            {contentChip}
            {result.tags.length > 0 && tagChips}
          </>
        </CardContent>
      </Box>
    </Card>
  );
};

export default SearchResultItemView;
