const natoTheme = {
    palette: {
      primary: {
        main: '#37a4d4',
      },
      secondary: {
        main: '#004489',
      },
      text: {
        primary: '#888888',
        secondary: '#666666',
      },
      siteToolbar: {
        main: '#004489',
        secondary: '#37a4d4',
        text: '#ffffff'
      },
      sidebarIcon:{
        selected: '#004489'
      },
      uploadButton: {
        main: '#ffffff',
      }
    },
    shape: {
      borderRadius: 4,
    },
  };

export default natoTheme;