/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer } from 'react';
import { getAccessHistory } from '../helpers';
import MyHistoryList from './MyHistoryList';

import { Header, Loading } from '../components/Pages/index';

const MY_HISTORY_ACTIONS = {
  FETCH_MY_HISTORY: 'FETCH_MY_HISTORY',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const myHistoryReducer = (state, action) => {
  switch (action.type) {
    case MY_HISTORY_ACTIONS.FETCH_MY_CONTENT: {
      return {
        ...state,
        loading: true,
      };
    }
    case MY_HISTORY_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        myHistory: action.data,
      };
    }
    case MY_HISTORY_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialMyHistoryState = {
  myHistory: [],
  loading: false,
  loaded: false,
  error: null,
};

function MyHistoryListPage() {
  const [state, dispatch] = useReducer(myHistoryReducer, initialMyHistoryState);
  const { myHistory, loading, error, loaded } = state;

  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: MY_HISTORY_ACTIONS.FETCH_MY_HISTORY });
      const getList = async () => {
        try {
          const data = await getAccessHistory();
          dispatch({ type: MY_HISTORY_ACTIONS.SUCCESS, data });
        } catch (error) {
          dispatch({ type: MY_HISTORY_ACTIONS.ERROR, error: 'error' });
        }
      };
      getList();
    }
  }, []);

  return (
    <div>
      <div>
        {error && <h4>error</h4>}
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Header title={'My History'.toUpperCase()} />
            {myHistory?.length > 0 ? (
              <MyHistoryList rows={myHistory} />
            ) : (
              <>No pages visited yet</>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default MyHistoryListPage;
