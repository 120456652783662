//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

/** Styles */
import { BoxContainer, SuccessIcon, FailureIcon } from './auth/styles';

/** Assets */
import RosmLogo from '../components/rosmIcon.svg';

/** Icons */
import LoginIcon from '@mui/icons-material/Login';

import { Header, Loading } from '../components/Pages';

/** Global Context */
import { verifyTwoFaReset } from '../helpers';

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const ACTIONS = {
  /* API CALL FETCHERS */
  FETCH_TWOFA_RESET: 'FETCH_USER',
  UNABLE_TO_CONNECT: 'UNABLE_TO_CONNECT',
  TWOFA_RESET: 'USER',
  ERROR_TWOFA_RESET: 'ERROR_USER',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_TWOFA_RESET: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIONS.UNABLE_TO_CONNECT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'unable to connect',
      };
    }
    case ACTIONS.TWOFA_RESET: {
      return {
        ...state,
        loading: false,
        loaded: true,
        twoFaReset: action.data,
      };
    }
    case ACTIONS.ERROR_TWOFA_RESET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'error getting user',
      };
    }
    default: {
      return state;
    }
  }
};

/**
 * Displays the Authentication Form Page
 */
function UserTwoFaReset() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { resetHash } = useParams();
  const { twoFaReset, loading, loaded, error } = state;

  /* On First Mount: Verify incoming 2-FA reset hash */
  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: ACTIONS.FETCH_TWOFA_RESET });
      const fetchData = async () => {
        const data = await verifyTwoFaReset(resetHash);
        if (data.fetched) {
          dispatch({ type: ACTIONS.TWOFA_RESET, data: data });
          return;
        }
        dispatch({
          type: ACTIONS.ERROR_TWOFA_RESET,
          error: data.error,
        });
      };
      fetchData();
    }
  }, []);

  return (
    <>
      {error && <h4>error</h4>}
      {loading ? (
        <Loading />
      ) : (
        <Container component='main' maxWidth='lg'>
          <BoxContainer>
            <img
              style={{ marginTop: '12px' }}
              src={RosmLogo}
              width={60}
              alt='ROS Logo'
            />
            <br />
            <Header title={'2-Factor Authorization Reset'} />
            <Grid container sx={{ mt: 4 }}>
              {error || !twoFaReset ? (
                <>
                  <Grid item xs={2}>
                    <FailureIcon />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 2 }}>
                          Invalid Reset or Error During Verification
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 26, fontWeight: 'bold', mb: 1 }}
                        >
                          The 2-FA reset process was unsuccessful
                        </Typography>
                        <Typography variant='body1'>
                          The 2-FA reset verification link was invalid or
                          expired. Please double check to ensure that you are
                          opening the latest reset email.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={2}>
                    <SuccessIcon />
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h4' sx={{ mb: 2 }}>
                          2-FA Reset!
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: 26, fontWeight: 'bold', mb: 1 }}
                        >
                          You have successfully reset your 2-FA.
                        </Typography>
                        <Typography variant='body1'>
                          You may now login again in order to complete the 2-FA
                          setup process.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          startIcon={<LoginIcon />}
                          sx={{ fontSize: 20, mt: 6, width: '100%' }}
                          size='large'
                          href='/'
                        >
                          Visit The Login Form
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </BoxContainer>
        </Container>
      )}
    </>
  );
}

export default UserTwoFaReset;
