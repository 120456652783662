//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

/** Icons */
import CloseIcon from '@mui/icons-material/Close';

const getErrorMessages = (errors) => {
  let out = ""
  errors?.error?.forEach(element => {
    out = out + element.path + " :: "
  });
  return out.substring(0, out.length - 4);
}

const AddContent = ({
  errors,
  errorMessage,
  success,
  onAddContent,
  openUpload,
  children,
  handleClickCloseDialog,
  loading,
  showLinkedData,
  stepValue,
  handleMoveToLinkedData,
  handleGoBack
}) => {
  return (
    <Dialog
      open={openUpload}
      onClose={handleClickCloseDialog}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <DialogTitle component='span'>
        <Grid
          container
          justifyContent='space-between'
          sx={{ borderBottom: 0 }}
          component='span'
        >
          <Grid item xs={8} component='span'>
            {/* <Typography variant='button' sx={{}}>
              Create Content
            </Typography> */}
          </Grid>
          <Grid item xs={2} align='right' component='span'>
            <IconButton sx={{'float': 'right'}}  onClick={handleClickCloseDialog}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {errors && (
          <>
            <Typography variant='button' sx={{}}>
              {errors?.message} :: {errors?.error?.length} errors
            </Typography>
            <Typography variant='button' sx={{}}>
              {getErrorMessages(errors)}
            </Typography>
          </>
        )}

        {success && (
          <Typography variant='button' sx={{}}>
            Data Saved
          </Typography>
        )}

        <Button disabled={loading || stepValue === 0} autoFocus onClick={handleGoBack}>
          Previous
        </Button>
        <Button disabled={loading} autoFocus onClick={showLinkedData && stepValue === 1 ? handleMoveToLinkedData : onAddContent}>
          {showLinkedData && stepValue < 2 ? 'Next' : 'Save changes'}
        </Button>
        <Button disabled={loading} autoFocus onClick={handleClickCloseDialog}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddContent;
