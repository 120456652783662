//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { getRequestNotificationsData } from '../index';
import { createContext, useReducer } from 'react';

export const ACTIONS = {
  /* API CALL FETCHERS */
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  UNABLE_TO_CONNECT: 'UNABLE_TO_CONNECT',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ERROR_NOTIFICATIONS: 'ERROR_NOTIFICATIONS',
};

export const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_NOTIFICATIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ACTIONS.UNABLE_TO_CONNECT: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'unable to connect',
      };
    }
    case ACTIONS.NOTIFICATIONS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        notificationsData: action.data,
      };
    }
    case ACTIONS.ERROR_NOTIFICATIONS: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: 'error getting notifications',
      };
    }
    default: {
      return state;
    }
  }
};

export const loadNotificationsData = (dispatch, state, countOnly) => {
  const fetchData = async () => {
    dispatch({ type: ACTIONS.FETCH_NOTIFICATIONS });
    // Get Notifications Data
    const notificationsData = await getRequestNotificationsData(countOnly);
    const notificationsDataFetched = notificationsData?.fetched;
    if (notificationsDataFetched || notificationsDataFetched === 0) {
      const data =
        countOnly === 'countOnly'
          ? {
              notifications: state?.notificationsData?.notifications,
              notificationsCount: notificationsDataFetched,
            }
          : {
              notificationsCount: state?.notificationsData?.notificationsCount,
              notifications: notificationsDataFetched,
            };

      dispatch({
        type: ACTIONS.NOTIFICATIONS,
        data: data,
      });
      return;
    } else {
      dispatch({
        type: ACTIONS.ERROR_NOTIFICATIONS,
        errorNotifications: 'error',
      });
      return;
    }
  };
  fetchData();
};

export const NotificationsContext = createContext({});

/**
 * Global Context Provider
 * Provides a wrapper that exposes the global states specified to children.
 */
export const NotificationsContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <NotificationsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};
