//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useReducer, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import highlightsReducer from '../redux/reducers/highlights';
import { useNavigate } from 'react-router-dom';
import { getHighlightsList } from '../helpers/api';
import { Header, MissingContent, Loading } from '../components/Pages/index';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import dayjs from 'dayjs/esm/index';
import { remark } from 'remark';
import ReactMarkdown from 'react-markdown';
import strip from 'strip-markdown';
import { DISTRIBUTION_COLORS } from '../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../components/ContentCard/StatusMapping';
import { LINK_MAPPING } from '../components/ContentCard/LinkMapping';

import { ADD_HIGHLIGHTS, SET_ERRORS } from '../redux/actions/highlights';
import { VirtualTable } from '@rosm/rosm-ng-components/dist/components/ListContent';

const initialHighlightsState = {
  highlights: [],
  loading: false,
  loaded: false,
  error: null,
};

const HEADERS = [
  { title: 'Title', align: 'left', width: '500px' },
  { title: 'Type', align: 'center', width: '10%' },
  { title: 'Custodian', align: 'center', width: '10%' },
  { title: 'Link Type', align: 'center', width: '10%' },
  { title: 'Status', align: 'center', width: '10%' },
  { title: 'Distribution', align: 'left', width: '10%' },
  { title: 'Last Updated', align: 'right', width: '200px' },
];

const contentTypes = {
  RosmRisk: 'Risk',
  RosmCategory: 'Category',
  RosmPackage: 'Package',
  RosmComponent: 'Component',
  RosmInstantiation: 'Instantiation',
  RosmProfile: 'Profile',
  RosmInterface: 'Interface',
  CsrProject: 'Project',
  CsrHardware: 'Hardware',
  CsrModel: 'Model',
  CsrPlanningDocument: 'PlanningDocument',
  CsrRequirement: 'Requirement',
  CsrSoftware: 'Software',
  CsrStandard: 'Standard',
  CsrTest: 'Test',
  CsrTool: 'Tool',
};

const contentTypeUrls = {
  RosmRisk: 'risks',
  RosmCategory: 'categories',
  RosmPackage: 'packages',
  RosmComponent: 'components',
  RosmInstantiation: 'instantiations',
  RosmProfile: 'profiles',
  RosmInterface: 'interfaces',
  CsrProject: 'documentation/projects',
  CsrHardware: 'documentation/hardware',
  CsrModel: 'documentation/model',
  CsrPlanningDocument: 'documentation/planningDocument',
  CsrRequirement: 'documentation/requirement',
  CsrSoftware: 'documentation/software',
  CsrStandard: 'documentation/standard',
  CsrTest: 'documentation/test',
  CsrTool: 'documentation/tool',
};

const LandingPage = () => {
  const [descriptions, setDescriptions] = useState({});

  let navigate = useNavigate();

  const getHighlights = async () => {
    try {
      const response = await getHighlightsList();
      if (!response?.ok) {
        throw new Error('token expired');
      }
      const data = await response.json();
      if (response.status === 200 && data) {
        dispatch({ type: ADD_HIGHLIGHTS, data });
      } else {
        dispatch({
          type: SET_ERRORS,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SET_ERRORS,
        error: 'error',
      });
      return true;
    }
  };

  useEffect(() => {
    const getData = async () => {
      const highlightsValidation = await getHighlights();
      if (highlightsValidation) {
        window.location.href = '/';
      }
    };
    getData();
  }, []);

  const stripMarkdown = async (s) => {
    return await remark()
      .use(strip)
      .process(s.replace(/\t/g, ' ')) // replace tabs to ignore markdown monospace (otherwise all monospace text gets stripped)
      .then((s) => {
        const newDesc = s.value.replace(/(?:\r\n|\r|\n)/g, ' ');
        return newDesc.length > 150
          ? `${newDesc.substring(0, 150)}...`
          : newDesc;
      });
  };

  const handleClick = (row) => {
    const { _id, contentType } = row;
    navigate(`/${contentTypeUrls[contentType]}/${_id}`);
  };

  const [state, dispatch] = useReducer(
    highlightsReducer,
    initialHighlightsState
  );
  const { highlights, loading, error, loaded } = state;

  useEffect(() => {
    async function cleanDescriptions() {
      let descs = {};
      for (const r in highlights) {
        const data = highlights[r];
        const desc = await stripMarkdown(data.description);
        descs[data._id] = desc;
      }
      setDescriptions(descs);
    }
    cleanDescriptions();
  }, [highlights]);

  const headerItems = () =>
    HEADERS.map((header) => {
      return (
        <TableCell
          style={{
            width: header.width,
            minWidth: header.width,
            maxWidth: header.width,
          }}
          align={header.align}
        >{`${header.title}`}</TableCell>
      );
    });

  const rowItems = (index, row) => {
    const d = dayjs(row.updatedDate).utc('z');
    const d1 = dayjs().utc('z');
    const diff = d.diff(d1);
    const duration = dayjs.duration(diff).humanize(true);

    const title =
      row.title.length > 50 ? `${row.title.substring(0, 50)}...` : row.title;

    const description = row.description
      ? row.description.length > 150
        ? `${row.description.substring(0, 150)}...`
        : row.description
      : '---';

    const tagChips = row.tags.map((tag) => {
      return (
        <Chip
          color='primary'
          sx={{
            '& .MuiChip-label': {
              paddingLeft: '4px',
              paddingRight: '4px',
            },
            marginRight: '4px',
            borderRadius: '4px',
            height: '21px',
          }}
          label={tag}
        />
      );
    });

    return (
      <>
        <TableCell
          style={{
            width: '500px',
            minWidth: '500px',
            maxWidth: '500px',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div>
            <div style={{ marginBottom: '4px' }}>{title}</div>
            <div style={{ marginBottom: '4px' }}>
              <ReactMarkdown
                children={descriptions[row._id.toString()]}
                remarkPlugins={[strip]}
              />
            </div>
            <div>{tagChips}</div>
          </div>
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          {row.contentType && contentTypes[row.contentType]
            ? contentTypes[row.contentType]
            : '--'}
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          {row.custodian}
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          <Tooltip title={'other'}>{LINK_MAPPING['other']}</Tooltip>
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
            {STATUS_MAPPING[row.status]}
          </Tooltip>
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              sx={{
                bgcolor: DISTRIBUTION_COLORS[row.distributionStatement],
              }}
            >
              {row.distributionStatement}
            </Avatar>
          </div>
        </TableCell>
        <TableCell
          style={{ width: '200px', minWidth: '200px', maxWidth: '200px' }}
          align='right'
        >
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
              <InfoIcon />
            </Tooltip>
            <div style={{ paddingLeft: '4px', marginTop: '2px' }}>
              {duration}
            </div>
          </div>
        </TableCell>
      </>
    );
  };

  if (error) {
    return (
      <>
        <Header title={'Highlights'} />
        <h4>Error Loading Content</h4>
      </>
    );
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {loaded && !loading && !error && highlights && highlights.length > 0 ? (
        <>
          <Header title={'Discover'} />
          <VirtualTable
            styles={null}
            rows={highlights}
            handleClick={handleClick}
            headerItems={headerItems}
            rowItems={rowItems}
          />
        </>
      ) : (
        <MissingContent />
      )}
    </>
  );
};

LandingPage.propTypes = {
  /**
   * array of highlights
   */
  highlights: PropTypes.array,
  /**
   * function to make call to get highlights from backend
   */
  getHighlights: PropTypes.func,
};

export default LandingPage;
