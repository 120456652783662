//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useEffect, useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { SearchContext } from './SearchContext';

function SearchBarDialog(props) {
  const context = useContext(SearchContext);
  const { searchQuery, setSearchQuery, upperCaseType } = props;
  const [localSearch, setLocalSearch] = useState(searchQuery);

  useEffect(() => {
    setLocalSearch(searchQuery);
  }, [searchQuery]);

  /**
   * Upon changing the input, set the local search state to prepare for searching and fire search if enter key hit
   * @param {event} e
   */
  const handleInputChange = (e) => {
    const value = e.currentTarget.value;
    setLocalSearch(value);
    if (e.keyCode === 13) {
      handleButtonPress(localSearch);
    }
  };

  /**
   * Sets the search query and fires context search handler
   * @param {string} search
   */
  const handleButtonPress = (search) => {
    setSearchQuery(search);
    setContext(search);
  };

  /**
   * Search handler function to update the search context returned in the SearchDialog
   * @param {string} search
   */
  const setContext = (search) => {
    context.searchHandler(search);
  };

  return (
    <>
      <TextField
        onChange={handleInputChange}
        onKeyDown={handleInputChange}
        value={localSearch}
        placeholder={`Search for ${upperCaseType} to add to project... `}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
          'aria-label': 'search',
        }}
        sx={{ height: 25, width: '60%' }}
      />

      <Button
        variant='outlined'
        onClick={() => handleButtonPress(localSearch)}
        sx={{ marginLeft: 1, lineHeight: 1, padding: -1 }}
      >
        Search
      </Button>
    </>
  );
}

export default SearchBarDialog;
