//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState } from 'react';

const DEFAULT_SORT = 'updatedDate-desc';
const DEFAULT_FILTERS = null;

// Create a context
export const SearchContext = React.createContext({
  results: [],
  query: '',
  filters: [],
  sortBy: DEFAULT_SORT,
  sortOptions: [],
  searchHandler: () => {},
  setSortByChange: () => {},
  setFilters: () => {},
});

// Defining a simple HOC component
export const SearchContextProvider = (props) => {
  // query, sort and filters
  const [query, setQuery] = useState('');
  const [sortBy, setSortBy] = React.useState(DEFAULT_SORT);
  const [filters, setSearchFilters] = React.useState(DEFAULT_FILTERS);

  const searchHandler = (query) => {
    setQuery(query);
  };

  const setSortByChange = (value) => {
    setSortBy(value);
  };

  const setFilters = (filterState) => {
    const filterData = {
      _contentType: [],
      maturityLevel: [],
      distributionStatement: [],
      compatibleRosVersions: [],
      rosmCategories: [],
    };

    Object.keys(filterState).map((f) => {
      const fItemData = f.split('-');
      if (filterState[f]) {
        filterData[fItemData[0]].push(fItemData[1]);
      }
    });

    setSearchFilters(filterData);
  };

  return (
    <SearchContext.Provider
      value={{
        query: query,
        filters: filters,
        sortBy: sortBy,
        sortOptions: [],
        setFilters: setFilters,
        searchHandler: searchHandler,
        setSortByChange: setSortByChange,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
