//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W56HZV-17-C-0062, Project Agreement DCS-S-17-011
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { getByType } from '../../../helpers/api/csrRequests';

/**
 * TODO move to the actions section
 */
export const DOCUMENTATION_ACTIONS = {
  FETCH_DOCUMENTATION_LIST: 'FETCH_DOCUMENTATION_LIST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
export const documentationListReducer = (state, action) => {
  switch (action.type) {
    case DOCUMENTATION_ACTIONS.FETCH_DOCUMENTATION_LIST: {
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    }
    case DOCUMENTATION_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        documentationList: action.data,
      };
    }
    case DOCUMENTATION_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

export const initialDocumentation = {
  documentationList: [],
  loading: false,
  loaded: false,
  error: null,
};

/**
 * Document Type Mapping
 */
export const DOCUMENT_TYPES = {
  projects: 'Project Documentation',
  hardware: 'Hardware Documentation',
  model: 'Model Documentation',
  planningDocument: 'Planning Documentation',
  requirement: 'Requirement Documentation',
  software: 'Software Documentation',
  standard: 'Standard Documentation',
  test: 'Test Documentation',
  tool: 'Tool Documentation',
};

/**
 * Get Listing of Documents from API
 * @param {func} dispatch
 * @returns
 */
export const getDocumentList = async (dispatch, items, documentationType) => {
  dispatch({
    type: DOCUMENTATION_ACTIONS.FETCH_DOCUMENTATION_LIST,
  });
  try {
    let data = [];
    let response = {};

    if (items) {
      response.status = 200;
      data = items;
    } else {
      response = await getByType(documentationType);
      data = await response.json();
    }

    if (response.status === 200) {
      dispatch({ type: DOCUMENTATION_ACTIONS.SUCCESS, data });
      return;
    }
    dispatch({
      type: DOCUMENTATION_ACTIONS.ERROR,
      error: data?.error ? data.error : response.error,
    });
  } catch (error) {
    dispatch({
      type: DOCUMENTATION_ACTIONS.ERROR,
      error: 'error',
    });
  }
};
