import { blue, orange } from '@mui/material/colors';

const defaultTheme = {
    palette: {
      primary: {
        main: blue[800],
      },
      secondary: {
        main: orange[500],
      },
      text: {
        primary: '#888888',
        secondary: '#666666',
      },
      siteToolbar: {
        main: '#333333',
        secondary: orange[500],
        text: '#ffffff'
      },
      sidebarIcon:{
        selected: orange[500]
      },
      uploadButton: {
        main: orange[500],
      }
    },
    shape: {
      borderRadius: 4,
    },
  };

export default defaultTheme;