/**
 * Form Field Specifications
 *
 * The following arrays provide specifications on what fields to show on related forms
 */

export const csrContentTypes = {
  field: 'contentType',
  type: 'Select',
  validation: '',
  options: [
    {
      title: 'csrHardware',
      label: 'Hardware',
      value: 'csrHardware',
      status: 1,
      description:
        'A supported project hardware item (ex. radios, vehicle, battery, networking/communications switches, lidar(s), camera(s))',
    },
    {
      title: 'csrModel',
      label: 'Model',
      value: 'csrModel',
      status: 1,
      description:
        'Models used within the project (ex. functional (vehicle architecture [perception talks to planning]), interface (ex. IOP draw/JAUS), architecture (ex. AGVRA), simulation (3D object, vehicles, sensors, inputs, configurations)',
    },
    {
      title: 'csrPlanningDocument',
      label: 'Planning Document',
      value: 'csrPlanningDocument',
      status: 1,
      description:
        'A project plan/goal, success criteria, or timeline for development or integration',
    },
    {
      title: 'csrProject',
      label: 'Project',
      value: 'csrProject',
      status: 0,
      description:
        'A top level CSR project in which all content types are associated',
    },
    {
      title: 'csrRequirement',
      label: 'Requirement',
      value: 'csrRequirement',
      status: 1,
      description:
        'A project requirement (ex. CDD high level, performance, specs, ICD, PSPEC [system/sub system])',
    },
    {
      title: 'csrSoftware',
      label: 'Software',
      value: 'csrSoftware',
      status: 1,
      description:
        'A supported software used on the project (ex. RTK suite, operating system)',
    },
    {
      title: 'csrStandard',
      label: 'Standard Document',
      value: 'csrStandard',
      status: 1,
      description:
        'A standard used within a project (ex. IOP instantiation, development, safety, operation, security, location, hardware, testing, software tooling)',
    },
    {
      title: 'csrTest',
      label: 'Test Document',
      value: 'csrTest',
      status: 1,
      description:
        'A test procedure (ex. regression, unit, system, integration, simulation), test plan, or test result',
    },
    {
      title: 'csrTool',
      label: 'Tool',
      value: 'csrTool',
      status: 1,
      description:
        'A data collection or simulation system tool used within a project',
    },
  ],
  required: true,
  label: 'Content Type',
  hoverDescription: 'Select the type of data that you wish to add.',
  placeholderDescription: 'Select the type of data that you wish to add...',
  maxCharacters: 1,
};

/* CSR Form Field Specifications */
export const csrFormFields = [
  {
    field: 'title',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Title',
    hoverDescription: 'Provide a short title.',
    placeholderDescription: 'Enter a title for the data',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'description',
    type: 'Multiline',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Description',
    hoverDescription: 'Write a short, detailed description.',
    placeholderDescription: 'Enter a description for data',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'make',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Make',
    hoverDescription: 'The make of the hardware (ex. Texas Instrument)',
    placeholderDescription: 'Enter the make of the hardware (ex. brand)',
    maxCharacters: 0,
    showInForms: ['csrHardware'],
  },
  {
    field: 'model',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Model',
    hoverDescription: 'The model of the hardware (ex. TI-101)',
    placeholderDescription: 'Enter the make of the hardware (ex. TI-101)',
    maxCharacters: 0,
    showInForms: ['csrHardware'],
  },
  {
    field: 'manufacturer',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Manufacturer',
    hoverDescription:
      'The manufacturer of the hardware (ex. Texas Instrument, Inc.)',
    placeholderDescription:
      'Enter the manufacturer of the hardware (ex. Texas Instrument, Inc.)',
    maxCharacters: 0,
    showInForms: ['csrHardware'],
  },
  {
    field: 'serialNumber',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Serial Number',
    hoverDescription:
      'The serial number associated with the hardware (ex. TI-001-034022)',
    placeholderDescription:
      'Enter the manufacturer of the hardware (ex. TI-001-034022)',
    maxCharacters: 0,
    showInForms: ['csrHardware'],
  },
  {
    field: 'tags',
    type: 'ChipEntryArray',
    validation: '',
    options: [],
    status: 1,
    required: false,
    label: 'Tags',
    hoverDescription:
      'Tags used to reference when searching (ex. Vision, Lidar, Battery).',
    placeholderDescription: 'Add meta tag and press enter to save',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'contact',
    type: 'Text',
    validation: 'Email',
    options: [],
    status: 1,
    required: false,
    label: 'Contact Email Address',
    hoverDescription: 'The email address of the contact for the data',
    placeholderDescription:
      'Enter the email address of the contact for the data',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'custodian',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Custodian',
    hoverDescription:
      'The individual or organization that will be responsible for managing the data',
    placeholderDescription: 'Enter an individual or organization name...',
    maxCharacters: 0,
    showInForms: [
      'csrModel',
      'csrPlanningDocument',
      'csrProject',
      'csrRequirement',
      'csrSoftware',
      'csrStandard',
      'csrTest',
      'csrHardware',
      'csrTool',
    ],
  },
  {
    field: 'status',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Public',
        label: 'Public',
        value: 'public',
        status: 1,
        description:
          'The status of the data is public and viewable by all with CSR access',
      },
      {
        title: 'Private',
        label: 'Private',
        value: 'private',
        status: 1,
        description:
          'The status of the data is private and only viewable by you and high level access users',
      },
      {
        title: 'Draft',
        label: 'Draft',
        value: 'draft',
        status: 1,
        description:
          'The status of the data is draft and will/may be updated in the future as maturity level advances',
      },
    ],
    status: 1,
    required: true,
    label: 'Status',
    hoverDescription: 'Select the status of the entry',
    placeholderDescription: 'Select the status of the entry...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'distributionStatement',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Distribution A',
        label: 'Distribution A',
        value: 'A',
        status: 1,
        description: 'Distribution A',
      },
      {
        title: 'Distribution B',
        label: 'Distribution B',
        value: 'B',
        status: 1,
        description: 'Distribution B',
      },
      {
        title: 'Distribution C',
        label: 'Distribution C',
        value: 'C',
        status: 1,
        description: 'Distribution C',
      },
      {
        title: 'Distribution D',
        label: 'Distribution D',
        value: 'D',
        status: 1,
        description: 'Distribution D',
      },
    ],
    status: 1,
    required: true,
    label: 'Distribution',
    hoverDescription: 'Select the distribution of the entry',
    placeholderDescription: 'Select the distribution of the entry...',
    maxCharacters: 0,
    showInForms: ['base'],
  },
  {
    field: 'link',
    type: 'Text',
    validation: 'URL',
    options: [],
    status: 1,
    required: false,
    label: 'Link',
    hoverDescription: 'A link to external documentation',
    placeholderDescription: 'Enter a link to an external document...',
    maxCharacters: 0,
    showInForms: [
      'csrModel',
      'csrPlanningDocument',
      'csrProject',
      'csrRequirement',
      'csrSoftware',
      'csrStandard',
      'csrHardware',
      'csrTest',
      'csrTool',
    ],
  },
  {
    field: 'linkType',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Word Document',
        label: 'Word Document',
        value: 'wordDocument',
        status: 1,
        description: 'word document',
      },
      {
        title: 'PDF Document',
        label: 'PDF Document',
        value: 'pdfDocument',
        status: 1,
        description: 'pdf document',
      },
      {
        title: 'Repository',
        label: 'Repository',
        value: 'repository',
        status: 1,
        description: 'Repository',
      },
      {
        title: 'Folder',
        label: 'Folder',
        value: 'folder',
        status: 1,
        description: 'Remote Folder',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'other',
        status: 1,
        description: 'An item not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Link Type',
    hoverDescription: 'Select the type of the link',
    placeholderDescription: 'Select the type of the link...',
    maxCharacters: 0,
    showInForms: [
      'csrModel',
      'csrPlanningDocument',
      'csrRequirement',
      'csrSoftware',
      'csrHardware',
      'csrStandard',
      'csrTest',
      'csrTool',
    ],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Architecture',
        label: 'Architecture',
        value: 'Architecture',
        status: 1,
        description: 'An architectural type (ex. AGVRA)',
      },
      {
        title: 'Functional',
        label: 'Functional',
        value: 'Functional',
        status: 1,
        description:
          'A functional type (ex. vehicle architecture [perception talks to planning])',
      },
      {
        title: 'Interface',
        label: 'Interface',
        value: 'Interface',
        status: 1,
        description: 'An interface type (ex. IOP draw/JAUS)',
      },
      {
        title: 'Simulation',
        label: 'Simulation',
        value: 'Simulation',
        status: 1,
        description:
          'A simulation type (ex. 3D object, vehicle, sensor, input, configuration',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general requirement not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of model',
    placeholderDescription: 'Select the model type...',
    maxCharacters: 0,
    showInForms: ['csrModel'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Project Plan',
        label: 'Project Plan',
        value: 'Project Plan',
        status: 1,
        description:
          'A project plan that details what was / will be completed for the project',
      },
      {
        title: 'Project Goal',
        label: 'Project Goal',
        value: 'Project Goal',
        status: 1,
        description:
          'A project goal that explains what the final goal of the project is',
      },
      {
        title: 'Success Criteria',
        label: 'Success Criteria',
        value: 'Success Criteria',
        status: 1,
        description:
          'An success criteria that details the required outcome to mark the project as a success',
      },
      {
        title: 'Timeline',
        label: 'Timeline',
        value: 'Timeline',
        status: 1,
        description:
          'A development and/or integration timeline for the project',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general requirement not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of planning document',
    placeholderDescription: 'Select the planning document type...',
    maxCharacters: 0,
    showInForms: ['csrPlanningDocument'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Performance',
        label: 'Performance',
        value: 'Performance',
        status: 1,
        description: 'A performance requirement for the project',
      },
      {
        title: 'CDD',
        label: 'CDD (high level)',
        value: 'CDD',
        status: 1,
        description: 'A high level CDD requirement',
      },
      {
        title: 'Specification',
        label: 'Specification',
        value: 'Specification',
        status: 1,
        description: 'A required specification',
      },
      {
        title: 'ICD',
        label: 'ICD',
        value: 'ICD',
        status: 1,
        description: 'An ICD requirement',
      },
      {
        title: 'PSPEC',
        label: 'PSPEC',
        value: 'PSPEC',
        status: 1,
        description: 'A PSPEC requirement',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general requirement not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of requirement',
    placeholderDescription: 'Select the requirement type...',
    maxCharacters: 0,
    showInForms: ['csrRequirement'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'RTK',
        label: 'RTK (general autonomy suite)',
        value: 'RTK',
        status: 1,
        description: 'A performance requirement for the project',
      },
      {
        title: 'Operating System',
        label: 'Operating System',
        value: 'Operating System',
        status: 1,
        description: 'A required operating system',
      },
      {
        title: 'Development',
        label: 'Development',
        value: 'Development',
        status: 1,
        description: 'A development software requirement',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general software not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of software',
    placeholderDescription: 'Select the software type...',
    maxCharacters: 0,
    showInForms: ['csrSoftware'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'IOP Instantiation',
        label: 'IOP Instantiation',
        value: 'IOP Instantiation',
        status: 1,
        description: 'An IOP instantiation standard',
      },
      {
        title: 'Development',
        label: 'Development (ex. code)',
        value: 'Development',
        status: 1,
        description: 'A development standard',
      },
      {
        title: 'Safety',
        label: 'Safety',
        value: 'Safety',
        status: 1,
        description: 'A safety standard',
      },
      {
        title: 'Operation',
        label: 'Operation',
        value: 'Operation',
        status: 1,
        description: 'An operation standard',
      },
      {
        title: 'Security',
        label: 'Security',
        value: 'Security',
        status: 1,
        description: 'A security standard',
      },
      {
        title: 'Location',
        label: 'Location (ex. installation/facility)',
        value: 'Location',
        status: 1,
        description: 'A location standard (ex. installation/facility)',
      },
      {
        title: 'Hardware',
        label: 'Hardware',
        value: 'Hardware',
        status: 1,
        description: 'A hardware standard',
      },
      {
        title: 'Testing',
        label: 'Testing',
        value: 'Testing',
        status: 1,
        description: 'A testing standard',
      },
      {
        title: 'Software Tooling',
        label: 'Software Tooling (ex. OS requirements)',
        value: 'Software Tooling',
        status: 1,
        description: 'A software tooling standard (ex. OS requirements)',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general standard not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of standard',
    placeholderDescription: 'Select the standard type...',
    maxCharacters: 0,
    showInForms: ['csrStandard'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Regression',
        label: 'Regression',
        value: 'Regression',
        status: 1,
        description: 'A regression test',
      },
      {
        title: 'Unit',
        label: 'Unit',
        value: 'Unit',
        status: 1,
        description: 'A unit test',
      },
      {
        title: 'System',
        label: 'System',
        value: 'System',
        status: 1,
        description: 'A system test',
      },
      {
        title: 'Integration',
        label: 'Integration',
        value: 'Integration',
        status: 1,
        description: 'An integration test',
      },
      {
        title: 'Plan',
        label: 'Plan',
        value: 'Plan',
        status: 1,
        description: 'A testing plan',
      },
      {
        title: 'Simulation',
        label: 'Simulation',
        value: 'Simulation',
        status: 1,
        description: 'A simulation test',
      },
      {
        title: 'Test Result',
        label: 'Test Result',
        value: 'Test Result',
        status: 1,
        description: 'A test result',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general test not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of test',
    placeholderDescription: 'Select the test type...',
    maxCharacters: 0,
    showInForms: ['csrTest'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Data Collection',
        label: 'Data Collection',
        value: 'Data Collection',
        status: 1,
        description: 'A data collection tool',
      },
      {
        title: 'Simulation System',
        label: 'Simulation System',
        value: 'Simulation System',
        status: 1,
        description: 'A simulation system tool',
      },
      {
        title: 'Operating System',
        label: 'Operating System',
        value: 'Operating System',
        status: 1,
        description: 'An operating system tool',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general tool not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of tool',
    placeholderDescription: 'Select the tool type...',
    maxCharacters: 0,
    showInForms: ['csrTool'],
  },
  {
    field: 'type',
    type: 'Select',
    validation: '',
    options: [
      {
        title: 'Battery',
        label: 'Battery',
        value: 'Battery',
        status: 1,
        description: 'A hardware battery',
      },
      {
        title: 'Radio',
        label: 'Radio',
        value: 'Radio',
        status: 1,
        description: 'A hardware radio',
      },
      {
        title: 'Sensor',
        label: 'Sensor',
        value: 'Sensor',
        status: 1,
        description: 'A hardware sensor',
      },
      {
        title: 'Other',
        label: 'Other',
        value: 'Other',
        status: 1,
        description: 'A general hardware type not listed',
      },
    ],
    status: 1,
    required: true,
    label: 'Type',
    hoverDescription: 'Select the type of hardware',
    placeholderDescription: 'Select the hardware type...',
    maxCharacters: 0,
    showInForms: ['csrHardware'],
  },
  {
    field: 'version',
    type: 'Text',
    validation: '',
    options: [],
    status: 1,
    required: false,
    label: 'Version',
    hoverDescription: 'The version of the data (ex. 1.0.1)',
    placeholderDescription: 'Enter the version of the data',
    maxCharacters: 0,
    showInForms: [
      'csrHardware',
      'csrModel',
      'csrPlanningDocument',
      'csrProject',
      'csrRequirement',
      'csrSoftware',
      'csrStandard',
      'csrTest',
      'csrTool',
    ],
  },
  {
    field: 'releaseDate',
    type: 'Date',
    validation: '',
    options: [],
    status: 1,
    required: false,
    label: 'Release Date',
    hoverDescription: 'The date that the data was or will be released',
    placeholderDescription: 'Enter the release date for the data',
    maxCharacters: 0,
    showInForms: [
      'csrHardware',
      'csrModel',
      'csrPlanningDocument',
      'csrRequirement',
      'csrSoftware',
      'csrStandard',
      'csrTest',
      'csrTool',
    ],
  },
  {
    field: 'tags',
    type: 'TagEntry',
    valiation: '',
    options: [],
    status: 1,
    required: true,
    label: 'Meta Tags',
    hoverDescription:
      'Please enter meta tags to describe your entry by typing a word and pressing the enter key. You may edit a tag by double clicking on it.',
    placeholderDescription: 'Type Meta Tag and press Enter...',
    maxCharacters: 0,
    showInForms: ['base'],
  },

  // {
  //   field: 'planningDocuments',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Planning Documents',
  //   hoverDescription:
  //     'Select associated planning documents by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No planning documents have been linked. Please add planning documents using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'hardware',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Hardware',
  //   hoverDescription:
  //     'Select associated hardware by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No hardware has been linked. Please add hardware using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'software',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Software',
  //   hoverDescription:
  //     'Select associated software by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No software has been linked. Please add software using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'tools',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Tools',
  //   hoverDescription:
  //     'Select associated tools by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No tools have been linked. Please add tools using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'requirements',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Requirements',
  //   hoverDescription:
  //     'Select associated requirements by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No requirements have been linked. Please add requirements using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'standards',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Standards',
  //   hoverDescription:
  //     'Select associated standards by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No standards have been linked. Please add standards using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'test',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Tests',
  //   hoverDescription:
  //     'Select associated tests by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No tests have been linked. Please add tests using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'models',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Models',
  //   hoverDescription:
  //     'Select associated models by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No models have been linked. Please add models using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
  // {
  //   field: 'supportingMaterial',
  //   type: 'AddTableArray',
  //   validation: '',
  //   options: [],
  //   status: 0,
  //   required: false,
  //   label: 'Supporting Material',
  //   hoverDescription:
  //     'Select associated supporting materials by searching within the table below. Click the "Add" button to link or "Remove" button to remove the link to the project.',
  //   placeholderDescription:
  //     'No supporting materials have been linked. Please add supporting materials using the table below...',
  //   maxCharacters: 0,
  //   showInForms: ['csrProject'],
  // },
];
