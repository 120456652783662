/** Global Context & Helper Function Imports */
import { getUserData, validateToken } from './index';

import ACTIONS from '../context/contextActions';

/**
 * Load the Initial State Data
 * @param {*} dispatch
 */
export const loadData = async (dispatch) => {
  const fetchData = async () => {
    dispatch({ type: ACTIONS.FETCH_USER });
    const isValidData = await validateToken();

    if (isValidData.unableToReachServer) {
      dispatch({ type: ACTIONS.UNABLE_TO_CONNECT });
      return;
    }

    const isValid = isValidData.valid;
    if (isValid) {
      // Establish Access Level
      const accessLevelData = isValidData.accessLevel;
      if (accessLevelData === 'full') {
        // Get User Data
        const userData = await getUserData();
        const userDataFetched = userData.fetched;
        if (userDataFetched) {
          dispatch({
            type: ACTIONS.USER,
            data: { token: isValidData, user: userDataFetched },
          });
          return;
        } else {
          dispatch({
            type: ACTIONS.ERROR_USER,
            errorUser: 'error',
          });
          return;
        }
      }
    }
    dispatch({
      type: ACTIONS.USER,
      data: { token: isValidData, user: null },
    });
  };

  fetchData();
};
