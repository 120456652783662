//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext, useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import TopNavigation from '../components/TopNavigation';
import Tour from '../components/Tour.js';
import SimpleSnackbar from '../components/Snackbar';

/** Global Context */
import { Context, logout, setIsValid, useLocalStorage } from '../helpers';
import { NotificationsContextProvider } from '../helpers/reducers/Notifications';

import { AddContent } from '../pages/AddContent';
import { Notifications } from '../pages/Notifications';

function BaseLayout({ user }) {
  const [open, setOpen] = useLocalStorage(true, 'sidebarOpen');
  const {
    dispatch,
    openUpload,
    setOpenUpload,
    setNotificationSnackBarMessage,
    setToken,
  } = useContext(Context);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleNotificationDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  return (
    <NotificationsContextProvider>
      <SimpleSnackbar />
      <Box sx={{ display: 'flex' }}>
        {openUpload && (
          <AddContent openUpload={openUpload} setOpenUpload={setOpenUpload} />
        )}
        <CssBaseline />
        <TopNavigation
          user={user}
          open={open}
          handleDrawerClick={handleDrawerClick}
          setOpenUpload={setOpenUpload}
          handleLogout={handleLogout}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          toggleNotificationDrawer={toggleNotificationDrawer}
        />
        <Sidebar
          user={user}
          open={open}
          handleDrawerClick={handleDrawerClick}
          setDrawerOpen={setDrawerOpen}
        />
        <Box
          component='main'
          sx={{
            marginTop: '64px',
            marginBottom: '50px',
            padding: (theme) => theme.spacing(1),
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: 'calc(100vh - 114px)',
            overflow: 'auto',
          }}
          id='drawer-container'
          position='relative'
        >
          <Drawer
            anchor={'top'}
            open={drawerOpen}
            onClose={toggleNotificationDrawer}
            elevation={5}
            PaperProps={{
              style: {
                position: 'absolute',
                width: '100%',
                minHeight: '40vh',
              },
            }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'absolute' },
            }}
            SlideProps={{
              onExiting: (node) => {
                node.style.webkitTransform = 'scaleX(0)';
                node.style.transform = 'scaleX(0)';
                node.style.transformOrigin = 'top left ';
              },
            }}
          >
            <Notifications />
          </Drawer>
          <Outlet />
        </Box>
        <Footer logout={handleLogout} />
        <Tour />
      </Box>
    </NotificationsContextProvider>
  );
}

export default BaseLayout;
