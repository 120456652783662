//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useContext } from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';

import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import TopNavigation from '../components/TopNavigation';

/** Global Context */
import { Context, logout, setIsValid, useLocalStorage } from '../helpers';

function ListPageLayout() {
  const [open, setOpen] = useLocalStorage(true, 'sidebarOpen');
  const { dispatch, setNotificationSnackBarMessage, setToken } =
    useContext(Context);

  const handleDrawerClick = () => {
    setOpen(!open);
  };

  /**
   * Set the user to be logged out if they choose to not stay logged in
   */
  const handleLogout = () => {
    const logoutUser = async () => {
      const result = await logout(setIsValid, dispatch, true);
      if (!result) {
        setNotificationSnackBarMessage(true, 'Unable to Logout', 'error');
        return;
      }
      setToken({});
    };
    logoutUser();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopNavigation open={open} handleDrawerClick={handleDrawerClick} />
      <Sidebar open={open} handleDrawerClick={handleDrawerClick} />
      <Box
        component='main'
        sx={{
          marginTop: '64px',
          marginBottom: '50px',
          padding: (theme) => theme.spacing(1),
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: 'calc(100vh - 114px)',
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Box>
      <Footer logout={handleLogout} />
    </Box>
  );
}

export default ListPageLayout;
