/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, {useContext} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { Context } from '../../helpers';

import Icon from '@mdi/react';
import SvgIcon from '@mui/material/SvgIcon';
import { mdiFolderPlusOutline } from '@mdi/js';
import { mdiPencilRulerOutline } from '@mdi/js';

function AddNewContent() {

  const { openUpload, setOpenUpload } = useContext(Context);

  return (
    <Box
        sx={{
            margin:'auto', 
            width: 500,
            cursor: 'pointer'
        }}
    >
        <SvgIcon style={{height: 500, width: 500}}>
            <Icon path={mdiFolderPlusOutline} size={7} />
            <Icon viewBox="11 -25 2 70" path={mdiPencilRulerOutline} size={4} />
        </SvgIcon>
        <Typography variant='overline' display='block' gutterBottom>
        {'You have not created any content yet. Click below or the upload button at the top of any page to add some content.'}
      </Typography>
        <Button
            variant='contained'
            sx={{ fontSize: 20, mt: 6, width: '100%' }}
            size='large'
            onClick={() => {setOpenUpload(!openUpload)}}
        >
            Add Content
        </Button>
    </Box>
  );
}

export default AddNewContent;
