/** Map the Notifications Based on Items */
export const mapNotifications = async (items, setNotifications) => {
  try {
    if (items) {
      let notifications = [];
      items.map((item) => {
        if (item.newNotification) {
          switch (item.type) {
            case 'support':
              // Get latest message or history item (last date first)
              const updates = {
                message: 'Response Received',
                state: 'Request Status Updated',
                assignedUserId: 'Support Representative Assigned',
              };
              const lastMessage = item.messages[item.messages.length - 1];
              const history = [...item.history].reverse();
              const lastHistory = history.some(function (val) {
                return val.field === 'state' || val.field === 'assignedUserId';
              });
              const update =
                lastMessage.createdAt > lastHistory.updatedAt
                  ? updates.message
                  : lastHistory.field === 'state'
                  ? updates.state
                  : updates.assignedUserId;
              notifications.push({
                _id: item._id,
                category: 'Contact Support',
                update: update,
                updatedAt: new Date(),
                messages: item.messages,
              });
              break;
            default:
          }
        }
      });
      await setNotifications(notifications);
    }
    return true;
  } catch (error) {
    return false;
  }
};
