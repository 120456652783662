//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs/esm/index';
import Avatar from '@mui/material/Avatar';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import './Search.css';
import { Loading } from '../components/Pages/index';
import { SearchContext } from '../components/Search/SearchContext';
import { postSearchRequest } from '../helpers/api/search';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import SearchBarDialog from '../components/Search/SearchBarDialog';
import { LINK_MAPPING } from '../components/ContentCard/LinkMapping';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../components/ContentCard/StatusMapping';
import { DISTRIBUTION_COLORS } from '../helpers/distribution';
import { ros1Versions, ros2Versions } from '../helpers/RosUtils';

/**
 * TODO move to the actions section
 */
const SEARCH_ACTIONS = {
  FETCH_SEARCH_RESULTS: 'FETCH_SEARCH_RESULTS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const searchReducer = (state, action) => {
  switch (action.type) {
    case SEARCH_ACTIONS.FETCH_SEARCH_RESULTS: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case SEARCH_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        searchResults: action.data,
      };
    }
    case SEARCH_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        searchResults: [],
        error: true,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialSearchReducer = {
  searchResults: null,
  loading: false,
  loaded: false,
  error: false,
};

function SearchDialog({
  type,
  localDocumentationList,
  dataType,
  upperCaseType,
  handleUpdateDocumentationState,
  editing,
  setEditing,
  searchQuery,
  setSearchQuery,
  activeSearchType,
  setActiveSearchType,
}) {
  const searchContext = useContext(SearchContext);
  const [state, dispatch] = useReducer(searchReducer, initialSearchReducer);
  const { searchResults, loading, error } = state;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [selected, setSelected] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getSearchResults = async () => {
    dispatch({
      type: SEARCH_ACTIONS.FETCH_DOCUMENTATION_LIST,
    });

    try {
      const response = await postSearchRequest({
        query: {
          searchTerm: searchQuery,
          resultsPerPage: rowsPerPage,
          currentPage: page,
          sortList: [
            {
              field: searchContext.sortBy.split('-')[0],
              direction: searchContext.sortBy.split('-')[1],
            },
          ],
        },
        options: {
          _contentType: [type],
          maturityLevel: [0, 1, 2, 3, 4],
          distributionStatement: ['A', 'B', 'C', 'D'],
          compatibleRosVersions: [...ros1Versions, ...ros2Versions],
        },
      });
      const data = await response.json();

      if (
        response.status === 200 &&
        data &&
        data.status === 'ok' &&
        data.results
      ) {
        dispatch({ type: SEARCH_ACTIONS.SUCCESS, data });
      } else {
        dispatch({
          type: SEARCH_ACTIONS.ERROR,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_ACTIONS.ERROR,
        error: 'error',
      });
    }
  };
  // search query updates
  useEffect(() => {
    if (searchQuery && activeSearchType === type && activeSearchType && type) {
      getSearchResults(searchQuery);
    } else {
      setSearchQuery('');
      getSearchResults('');
    }
    setActiveSearchType(type);
  }, [
    searchContext.query,
    rowsPerPage,
    page,
    searchContext.filters,
    searchContext.sortBy,
    activeSearchType,
  ]);

  if (loading) {
    return <Loading />;
  }

  if (!searchResults) {
    return <Loading />;
  }

  let searchResultsComponent;
  if (searchResults.totalResults > 0) {
    const TableHead = () => {
      return (
        <>
          <TableCell align='left'>Select</TableCell>
          <TableCell align='left'>Title</TableCell>
          <TableCell align='center'>Type</TableCell>
          <TableCell align='center'>Custodian</TableCell>
          <TableCell align='center'>Link Type</TableCell>
          <TableCell align='center'>Status</TableCell>
          <TableCell align='left'>Distribution</TableCell>
          <TableCell align='right'>Last Updated</TableCell>
        </>
      );
    };

    const RowItems = ({ result }) => {
      let docs = [];
      if (Array.isArray(localDocumentationList[dataType])) {
        if (localDocumentationList[dataType].length) {
          docs = localDocumentationList[dataType].slice();
        }
      }
      const handleRowClick = (id, row) => {
        let selectedCopy = selected.length ? selected.slice() : [];
        if (selectedCopy.length) {
          if (selectedCopy.includes(id)) {
            setSelected(selectedCopy.filter((doc) => doc !== id));
          } else {
            selectedCopy.push(id);
            setSelected(selectedCopy);
          }
        } else {
          selectedCopy.push(id);
          setSelected(selectedCopy);
        }
        if (localDocumentationList) {
          if (docs.some((doc) => doc._id === id)) {
            docs = docs.filter((doc) => doc._id !== id);
          } else {
            docs.push({
              _id: row.searchId,
              title: row.title,
              description: row.description,
              type: row.type,
              custodian: row.custodian,
              linkType: row.linkType,
              status: row.status,
              distributionStatement: row.distributionStatement,
              updatedDate: row.updatedDate,
              tags: row.tags,
            });
          }
          let updatedDocs = { ...localDocumentationList, [dataType]: docs };
          handleUpdateDocumentationState(updatedDocs);
          if (typeof editing === 'object' && editing !== null) {
            setEditing({ ...editing, saveDisabled: false });
          }
        }
      };
      const Item = ({ row }) => {
        // Set selected if already in selected documents
        let selectedCopy = selected;
        if (docs.some((doc) => doc._id === row._sourceId)) {
          selectedCopy.push(row._sourceId);
        }
        setSelected(selectedCopy);

        const d = dayjs(row.updatedDate).utc('z');
        const d1 = dayjs().utc('z');
        const diff = d.diff(d1);
        const duration = dayjs.duration(diff).humanize(true);
        const title =
          row.title.length > 50
            ? `${row.title.substring(0, 50)}...`
            : row.title;
        const description = row.description
          ? row.description.length > 150
            ? `${row.description.substring(0, 150)}...`
            : row.description
          : '---';
        const tagChips = row.tags.map((tag) => {
          return (
            <Chip
              color='primary'
              sx={{
                '& .MuiChip-label': {
                  paddingLeft: '4px',
                  paddingRight: '4px',
                },
                marginRight: '4px',
                borderRadius: '4px',
                height: '21px',
              }}
              label={tag}
            />
          );
        });
        return (
          <TableRow
            hover
            onClick={() => handleRowClick(row._sourceId.toString(), row)}
            key={row._sourceId.toString()}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              width: '100%',
            }}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={
                  selected.length
                    ? selected.includes(row._sourceId.toString())
                    : false
                }
                inputProps={{
                  'aria-labelledby': row._sourceId,
                }}
              />
            </TableCell>
            <TableCell
              style={{ padding: '8px' }}
              component='th'
              scope='row'
              align='left'
            >
              <div>
                <div style={{ marginBottom: '4px' }}>{title}</div>
                <div style={{ marginBottom: '4px' }}>{description}</div>
                <div>{tagChips}</div>
              </div>
            </TableCell>
            <TableCell style={{}} align='center'>
              {row.type ? row.type : '--'}
            </TableCell>
            <TableCell style={{}} align='center'>
              {row.custodian}
            </TableCell>
            <TableCell style={{ width: '100px' }} align='center'>
              {row.linkType && (
                <Tooltip title={row.linkType}>
                  {LINK_MAPPING[row.linkType]}
                </Tooltip>
              )}
            </TableCell>
            <TableCell style={{ width: '100px' }} align='center'>
              <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
                {STATUS_MAPPING[row.status]}
              </Tooltip>
            </TableCell>
            <TableCell style={{ width: '80px' }} align='center'>
              <div style={{ marginLeft: '15px' }}>
                <Avatar
                  sx={{
                    bgcolor: DISTRIBUTION_COLORS[row.distributionStatement],
                  }}
                >
                  {row.distributionStatement}
                </Avatar>
              </div>
            </TableCell>
            <TableCell style={{ width: '200px' }} align='right'>
              <div style={{ display: 'inline-flex' }}>
                <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
                  <InfoIcon />
                </Tooltip>
                <div style={{ paddingLeft: '4px', marginTop: '2px' }}>
                  {duration}
                </div>
              </div>
            </TableCell>
          </TableRow>
        );
      };
      return <>{searchQuery && searchResults && <Item row={result} />}</>;
    };

    const resultItems = searchResults.results.map((result, index) => {
      return (
        <>
          <RowItems result={result} />
        </>
      );
    });

    searchResultsComponent = (
      <>
        <Paper
          sx={{
            maxHeight: '100%',
            width: '100%',
            mt: 2,
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: '100%' }}
              stickyHeader
              aria-label='sticky table'
            >
              <TableHead>
                <TableRow>
                  <TableHead />
                </TableRow>
              </TableHead>
              <TableBody>{resultItems}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    );
  } else if (error && !searchQuery) {
    searchResultsComponent = (
      <SearchResultsHeader title={'Error Getting Search Results For Query'} />
    );
  } else {
    searchResultsComponent = (
      <SearchResultsHeader title={'Search to find Documents'} />
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <SearchBarDialog
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            upperCaseType={upperCaseType}
          />
        </Grid>
        <Grid item xs={4}>
          <Box display='flex' justifyContent='flex-end'>
            <TablePagination
              style={{ display: 'flex' }}
              component='div'
              count={searchResults.totalResults}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {searchQuery && <>{searchResultsComponent}</>}
        </Grid>
      </Grid>
    </div>
  );
}

export default SearchDialog;

function SearchResultsHeader({ title }) {
  return (
    <Typography
      style={{ padding: '12px', textAlign: 'center' }}
      variant='h6'
      gutterBottom
      noWrap
      component='div'
    >
      {title}
    </Typography>
  );
}
