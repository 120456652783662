/*
 * (C) 2022 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useReducer } from 'react';
import { getRosmPackagesList } from '../../../helpers/api/rosmRequests';
import PackageList from './components/PackageList';

import {
  Header,
  MissingContent,
  Loading,
} from '../../../components/Pages/index';

const ROSM_PACKAGES_ACTIONS = {
  FETCH_ROSM_PACKAGES: 'FETCH_ROSM_PACKAGES',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

/**
 * TODO This can be moved to the reducers section
 * @param {*} state
 * @param {*} action
 * @returns
 */
const rosmPackagesReducer = (state, action) => {
  switch (action.type) {
    case ROSM_PACKAGES_ACTIONS.ROSM_PACKAGES_ACTIONS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_PACKAGES_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        rosmPackagesList: action.data,
      };
    }
    case ROSM_PACKAGES_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialRosmPackagesState = {
  rosmPackagesList: [],
  loading: false,
  loaded: false,
  error: null,
};

function PackagesPage() {
  const [state, dispatch] = useReducer(
    rosmPackagesReducer,
    initialRosmPackagesState
  );
  const { rosmPackagesList, loading, error, loaded } = state;

  useEffect(() => {
    if (!loading && !loaded && !error) {
      dispatch({ type: ROSM_PACKAGES_ACTIONS.FETCH_ROSM_PACKAGES });
      // TODO move elsewhere
      const getList = async () => {
        try {
          let response = await getRosmPackagesList();
          const data = await response.json();
          if (response.status === 200) {
            dispatch({ type: ROSM_PACKAGES_ACTIONS.SUCCESS, data });
            return;
          }
          dispatch({
            type: ROSM_PACKAGES_ACTIONS.ERROR,
            error: data?.error ? data.error : response.error,
          });
        } catch (error) {
          dispatch({ type: ROSM_PACKAGES_ACTIONS.ERROR, error: 'error' });
        }
      };

      getList();
    }
  }, []);

  return (
    <div>
      <div>
        {error && <h4>error</h4>}
        {loading ? (
          <Loading />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Header title={'Packages'.toUpperCase()} />
            {rosmPackagesList.length === 0 && loaded && <MissingContent />}
            {rosmPackagesList.length > 0 && (
              <PackageList rows={rosmPackagesList} />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default PackagesPage;
