/*
 * (C) 2023 Neya Systems, LLC. All Rights Reserved
 *
 * Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
 * warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
 * special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
 * data or profits, whether in an action of contract, negligence or other tortious action, arising
 * out of or in connection with the use or performance of this software.
 *
 * GOVERNMENT UNRESTRICTED RIGHTS
 *     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
 *     Contractor Name    Neya Systems, LLC
 *     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
 *
 * The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
 * software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
 * Noncommercial Computer Software Documentation clause contained in the above identified contract.
 * No restrictions apply after the expiration date shown above.  Any reproduction of the software
 * or portions thereof marked with this legend must also reproduce the markings.
 *
 */

import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import dayjs from 'dayjs/esm/index';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import Avatar from '@mui/material/Avatar';
import { DISTRIBUTION_COLORS } from '../../../../helpers/distribution';
import {
  STATUS_MAPPING,
  STATUS_MAPPING_DESCRIPTIONS,
} from '../../../../components/ContentCard/StatusMapping';
import InfoIcon from '@mui/icons-material/Info';
import { remark } from 'remark';
import ReactMarkdown from 'react-markdown';
import strip from 'strip-markdown';
import { VirtualTable } from '@rosm/rosm-ng-components/dist/components/ListContent';

import { config } from '../../../../app/Config';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const DEFAULT_INSTANTIATION_IMAGE = `${config.PUBLIC_URL}/images/contentType/Instantiation.png`;

const HEADERS = [
  { title: '', align: 'center', value: '', width: '10%' },
  { title: 'Title', align: 'left', value: 'title', width: '25%' },
  { title: 'Custodian', align: 'center', width: '15%' },
  { title: 'Maturity Level', align: 'center', width: '10%' },
  { title: 'Status', align: 'center', width: '10%' },
  { title: 'Distribution', align: 'left', width: '10%' },
  { title: 'Last Updated', align: 'right', width: '20%' },
];

const InstantiationList = ({ rows }) => {
  let navigate = useNavigate();

  const [descriptions, setDescriptions] = useState({});

  const handleClick = (row) => {
    navigate(`/instantiations/${row._id.toString()}`);
  };

  const stripMarkdown = async (s) => {
    return await remark()
      .use(strip)
      .process(s.replace(/\t/g, ' ')) // replace tabs to ignore markdown monospace (otherwise all monospace text gets stripped)
      .then((s) => {
        const newDesc = s.value.replace(/(?:\r\n|\r|\n)/g, ' ');
        return newDesc.length > 150
          ? `${newDesc.substring(0, 150)}...`
          : newDesc;
      });
  };

  useEffect(() => {
    async function cleanDescriptions() {
      let descs = {};
      for (const r in rows) {
        const data = rows[r];
        const desc = await stripMarkdown(data.description);
        descs[data._id] = desc;
      }
      setDescriptions(descs);
    }
    cleanDescriptions();
  }, [rows]);

  const headerItems = () =>
    HEADERS.map((header) => {
      return (
        <TableCell
          style={{
            width: header.width,
            minWidth: header.width,
            maxWidth: header.width,
          }}
          align={header.align}
        >{`${header.title}`}</TableCell>
      );
    });

  const rowItems = (index, row) => {
    const d = dayjs(row.updatedDate).utc('z');
    const d1 = dayjs().utc('z');
    const diff = d.diff(d1);
    const duration = dayjs.duration(diff).humanize(true);
    const title =
      row.title.length > 50 ? `${row.title.substring(0, 50)}...` : row.title;

    const tagChips = row.tags.map((tag) => {
      return (
        <Chip
          color='primary'
          sx={{
            '& .MuiChip-label': {
              paddingLeft: '4px',
              paddingRight: '4px',
            },
            marginRight: '4px',
            borderRadius: '4px',
            height: '21px',
          }}
          label={tag}
        />
      );
    });

    const thumbnailImage =
      row.images.length > 0
        ? { src: row.images[0], width: '120px', height: '100px' }
        : { src: DEFAULT_INSTANTIATION_IMAGE, width: '40px', height: '40px' };

    const entryImage = (
      <div
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          height: thumbnailImage.height,
          width: thumbnailImage.width,
          textAlign: 'center',
        }}
      >
        <img
          width={thumbnailImage.width}
          height={thumbnailImage.height}
          alt='instantiation_image'
          src={thumbnailImage.src}
        />
      </div>
    );

    return (
      <>
        <TableCell
          style={{
            width: '10%',
            minWidth: '10%',
            maxWidth: '10%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div style={{ marginTop: '4px' }}>{entryImage}</div>
        </TableCell>
        <TableCell
          style={{
            width: '25%',
            minWidth: '25%',
            maxWidth: '25%',
            padding: '8px',
          }}
          component='th'
          scope='row'
          align='left'
        >
          <div>
            <div style={{ marginBottom: '4px' }}>{title}</div>
            <div style={{ marginBottom: '4px' }}>
              <ReactMarkdown
                children={descriptions[row._id.toString()]}
                remarkPlugins={[strip]}
              />
            </div>
            <div>{tagChips}</div>
          </div>
        </TableCell>

        <TableCell
          style={{ width: '15%', minWidth: '15%', maxWidth: '15%' }}
          align='center'
        >
          {row.custodian}
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          {`${row.maturityLevel}`}
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          <Tooltip title={STATUS_MAPPING_DESCRIPTIONS[row.status]}>
            {STATUS_MAPPING[row.status]}
          </Tooltip>
        </TableCell>
        <TableCell
          style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
          align='center'
        >
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              sx={{ bgcolor: DISTRIBUTION_COLORS[row.distributionStatement] }}
            >
              {row.distributionStatement}
            </Avatar>
          </div>
        </TableCell>
        <TableCell
          style={{ width: '20%', minWidth: '20%', maxWidth: '20%' }}
          align='right'
        >
          <div style={{ display: 'inline-flex' }}>
            <Tooltip title={d.utc().local().format('YYYY-MM-DD')}>
              <InfoIcon />
            </Tooltip>
            <div style={{ paddingLeft: '4px', marginTop: '2px' }}>
              {duration}
            </div>
          </div>
        </TableCell>
      </>
    );
  };

  return (
    <VirtualTable
      styles={null}
      rows={rows}
      handleClick={handleClick}
      headerItems={headerItems}
      rowItems={rowItems}
    />
  );
};

InstantiationList.propTypes = {};

export default InstantiationList;
