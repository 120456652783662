//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import { createContext, useState, useReducer } from 'react';
import initialState from './contextInitialState';
import reducer from './contextReducer';
import ACTIONS from './contextActions';
/** Global Context */
import { useLocalStorage } from '../index';
/** Initialize the Global State Context */
export const Context = createContext({});

/**
 * Global Context Provider
 * Provides a wrapper that exposes the global states specified to children.
 */
export const ContextProvider = (props) => {
  const [user, setUser] = useState(); // Store User Details (Updated Once on Site Load)
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Store snackbar status
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // The overall color of the snackbar based on severity (ex. success = green, error = red)
  const [openUpload, setOpenUpload] = useState(false); // Upload dialog state
  const [token, setToken] = useState({});

  const [storedShowTour, setStoredShowTour] = useLocalStorage(
    true,
    'storedShowTour'
  );

  const setNotificationSnackBarMessage = (
    isOpen,
    snackbarMessage,
    snackbarSeverity
  ) => {
    setSnackbarMessage(snackbarMessage);
    setSnackbarSeverity(snackbarSeverity);
    setSnackbarOpen(isOpen);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        user,
        setUser,
        setNotificationSnackBarMessage,
        snackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        snackbarSeverity,
        storedShowTour,
        setStoredShowTour,
        ACTIONS,
        openUpload,
        setOpenUpload,
        token,
        setToken,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
