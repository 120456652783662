//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  loadNotificationsData,
  NotificationsContext,
} from '../helpers/reducers/Notifications';
import { drawerWidth } from './Sidebar';

import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import PlusIcon from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchBar from '../components/Search/SearchBar';
import { useTheme } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person'; // Profile
import StarIcon from '@mui/icons-material/Star'; // History
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'; // FAQ's
import ContactSupportIcon from '@mui/icons-material/ContactSupport'; // Contact Support
import NotificationsIcon from '@mui/icons-material/Notifications'; // Notifications
import LogoutIcon from '@mui/icons-material/Logout'; // Sign Out

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function TopNavigation({
  user,
  open,
  handleDrawerClick,
  setOpenUpload,
  handleLogout,
  drawerOpen,
  setDrawerOpen,
  toggleNotificationDrawer,
}) {
  const context = useContext(NotificationsContext);
  const { state, dispatch } = context;
  const { palette } = useTheme();
  const [dropDownMenuOpen, setDropDownMenuOpen] = useState(null);

  // Load the requests data into the reducer
  useEffect(() => {
    loadNotificationsData(dispatch, state, 'countOnly'); // True declares that only a count is required
  }, []);

  let name = 'Sign In';
  let organization = '-';
  if (user) {
    name = `${user.profile.firstName} ${user.profile.lastName}`;
    organization = `${user.profile.organization}`;
  }

  // Profile Menu Items
  const dropDownMenuItems = [
    {
      title: 'Profile',
      link: '/profile',
      component: Link,
      icon: PersonIcon,
      event: () => handleCloseDropDownMenu(),
    },
    {
      title: 'History',
      link: '/myHistory',
      component: Link,
      icon: StarIcon,
      event: () => handleCloseDropDownMenu(),
    },
    {
      title: 'F.A.Q',
      link: '/profile?scroll=faq',
      component: Link,
      icon: QuestionAnswerIcon,
      event: () => handleCloseDropDownMenu(),
    },
    {
      title: 'Contact Support',
      link: '/profile?scroll=contactSupport',
      component: Link,
      icon: ContactSupportIcon,
      event: () => handleCloseDropDownMenu(),
    },
    {
      title: 'Notifications',
      link: null,
      component: MenuItem,
      icon: NotificationsIcon,
      event: () => {
        toggleNotificationDrawer();
        handleCloseDropDownMenu();
      },
    },
    {
      title: 'Sign Out',
      link: '/',
      component: Link,
      icon: LogoutIcon,
      event: () => handleLogout(),
    },
  ];

  const handleOpenDropDownMenu = (event) => {
    setDrawerOpen(false);
    setDropDownMenuOpen(event.currentTarget);
  };

  const handleCloseDropDownMenu = (event) => {
    setDropDownMenuOpen(null);
  };

  return (
    <AppBar
      position='absolute'
      open={open}
      style={{ backgroundColor: palette.siteToolbar.main }}
    >
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge='start'
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerClick}
          sx={{
            transition: 'opacity 0.75s ease-out',
            ...(open && { opacity: 0 }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <SearchBar setDrawerOpen={setDrawerOpen} />
        <Button
          sx={{ height: '20px' }}
          style={{
            color: palette.uploadButton.main,
            borderColor: palette.uploadButton.main,
          }}
          startIcon={<PlusIcon />}
          variant='outlined'
          onClick={() => {
            setDrawerOpen(false);
            setOpenUpload(true);
          }}
        >
          Upload
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color='inherit'
          onClick={toggleNotificationDrawer}
          className='notifications'
          style={{ color: palette.siteToolbar.text }}
        >
          {state.loaded && (
            <>
              {state.notificationsData.notificationsCount &&
              state.notificationsData.notificationsCount > 0 ? (
                <Badge
                  badgeContent={
                    state.notificationsData.notificationsCount > 9
                      ? '9+'
                      : state.notificationsData.notificationsCount
                  }
                  style={{ color: palette.siteToolbar.text }}
                >
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </>
          )}
        </IconButton>
        <div
          style={{
            marginLeft: '20px',
            display: 'inline-flex',
            color: palette.siteToolbar.text,
          }}
        >
          <div>
            <Typography variant='overline' component='div'>
              {name}
            </Typography>
            <Typography variant='caption' component='div'>
              {organization}
            </Typography>
          </div>
          <IconButton
            sx={{ p: 1 }}
            className='profile'
            onClick={handleOpenDropDownMenu}
          >
            <StyledBadge
              overlap='circular'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant='dot'
            >
              <Avatar
                style={{ textTransform: 'uppercase' }}
                alt={name}
                src='/static/images/avatar/1.jpg'
              />
            </StyledBadge>
          </IconButton>
          <Menu
            sx={{ mt: '48px' }}
            id='dropDownMenu'
            anchorEl={dropDownMenuOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            open={Boolean(dropDownMenuOpen)}
            onClose={handleCloseDropDownMenu}
          >
            {dropDownMenuItems.map((item) => (
              <MenuItem
                key={item.title}
                onClick={item.event}
                to={item.link}
                component={item.component}
                divider={item.title === 'Notifications'}
                sx={{
                  fontWeight:
                    item.title === 'Sign Out' ? 'bold' : 'normal !important',
                }}
              >
                <item.icon color='primary' sx={{ mr: 2 }} />
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

TopNavigation.propTypes = {
  user: PropTypes.object.isRequired,
  handleDrawerClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenUpload: PropTypes.func.isRequired,
  drawerOpen: PropTypes.string.isRequired,
  setDrawerOpen: PropTypes.func.isRequired,
  toggleNotificationDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default TopNavigation;
