/**
 * canEditContent
 * @param {object} user - user profile object
 * @param {string} contentUserId - content user id
 */
export function canEditContent(user, contentUserId) {
  // is the user elevated, show the edit
  if (user.roles.includes('ADMINISTRATOR')) {
    return true;
  }
  if (user._id === contentUserId) {
    return true;
  }
  return false;
}
