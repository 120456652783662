//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useEffect, useReducer, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MissingContent, Loading } from '../../components/Pages/index';
import {
  DocumentationContent,
  ProjectContent,
  EditDialog,
} from '@rosm/rosm-ng-components/dist/components/PageContent';
import { canEditContent, Context } from '../../helpers';
import {
  getByTypeById,
  updateDocumentationById,
} from '../../helpers/api/csrRequests';
import { uploadContentImage, deleteImage } from '../../helpers/api/images';
import { default as DocumentationListPage } from './DocumentationListPage';
import { config } from '../../app/Config';
import { default as SearchDialog } from '../../pages/SearchDialog';
// State Management
import {
  DOCUMENTATION_ACTIONS,
  documentationItemReducer,
  initialDocumentation,
} from './state/documentationDetails.js';

const ERROR_SAVING = 'error saving';
const NO_SUCH_CONTENT = 'no content available';

const DocumentationDetailsPage = ({ user }) => {
  const [editing, setEditing] = useState({ show: false, saveDisabled: true });
  const { setNotificationSnackBarMessage } = useContext(Context);
  let { documentationType, documentationId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(
    documentationItemReducer,
    initialDocumentation
  );

  const { documentationItem, loading, error, loaded } = state;

  const _bk = { ...documentationItem };

  const getDocument = async () => {
    dispatch({
      type: DOCUMENTATION_ACTIONS.FETCH_DOCUMENTATION_LIST,
    });
    try {
      const response = await getByTypeById(documentationType, documentationId);
      const data = await response.json();
      if (response.status === 200) {
        dispatch({ type: DOCUMENTATION_ACTIONS.SUCCESS, data });
        return;
      }
      dispatch({
        type: DOCUMENTATION_ACTIONS.ERROR,
        error: data?.error ? data.error : response.error,
      });
    } catch (error) {
      dispatch({
        type: DOCUMENTATION_ACTIONS.ERROR,
        error: 'error',
      });
    }
  };

  const updateDocumentation = async (documentationContent) => {
    dispatch({ type: DOCUMENTATION_ACTIONS.FETCH_UPDATED });
    try {
      let response = await updateDocumentationById(
        documentationId,
        documentationContent
      );
      const data = await response.json();
      if (response.status === 200 && data.status) {
        dispatch({
          type: DOCUMENTATION_ACTIONS.UPDATED,
          data: documentationContent,
        });
        setNotificationSnackBarMessage(true, 'Document Updated', 'success');
        return;
      }
      dispatch({
        type: DOCUMENTATION_ACTIONS.UPDATE_ERROR,
        error: data?.error ? data.error : response.error,
        data: _bk,
      });
      setNotificationSnackBarMessage(
        true,
        `Documentation Update: ${data.error.length} Error(s)`,
        'error'
      );
    } catch (error) {
      dispatch({ type: DOCUMENTATION_ACTIONS.ERROR, error: ERROR_SAVING });
      setNotificationSnackBarMessage(
        true,
        'Documentation Update Error',
        'error'
      );
    }
  };

  useEffect(() => {
    if (!loading && !loaded && !error) {
      getDocument();
    }
  }, []);

  if (error) {
    if (error === NO_SUCH_CONTENT) {
      return <MissingContent title={'The requested content does not exist.'} />;
    }
    return <h4>error</h4>;
  }

  if (!documentationItem) {
    return <Loading />;
  }

  if (!loaded || loading) {
    return <Loading />;
  }

  const handleEvent = (event, newContent) => {
    updateDocumentation(newContent);
  };

  /**
   * Update the listing of documents within the details page
   * @param {object} newContent
   */
  const handleUpdateDocumentationState = (newContent) => {
    dispatch({ type: DOCUMENTATION_ACTIONS.FETCH_UPDATED });
    dispatch({ type: DOCUMENTATION_ACTIONS.SUCCESS, data: newContent });
  };

  /**
   * Update the listing of of documents within the details page for a specific type to match up searc
   * @param {string} id
   * @param {string} type
   */
  const handleUpdateDocumentationStateFromListingToSearch = (id, type) => {
    const localResult = documentationItem[type];
    let updatedLocalResult = [];
    if (localResult.length) {
      updatedLocalResult = localResult.filter((f) => f._id !== id);
      dispatch({ type: DOCUMENTATION_ACTIONS.FETCH_UPDATED });
      dispatch({
        type: DOCUMENTATION_ACTIONS.SUCCESS,
        data: { ...documentationItem, [type]: updatedLocalResult },
      });
    }
  };

  const handleUploadFile = async (file) => {
    const data = new FormData();
    data.append('file', file);
    data.append('contentType', documentationItem.contentType);
    const resp = await uploadContentImage(
      documentationItem._id.toString(),
      data
    );

    if (resp.status === 400) {
      setNotificationSnackBarMessage(true, 'Document Update Error', 'error');
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(true, 'Document Image Added', 'success');
    }
    return resp;
  };

  const handleImageDelete = async (imageUrl) => {
    const imageId = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const resp = await deleteImage(documentationItem._id.toString(), imageId);
    if (resp.status === 400) {
      setNotificationSnackBarMessage(
        true,
        'Documentation Update Error',
        'error'
      );
    }
    if (resp.status === 201) {
      setNotificationSnackBarMessage(
        true,
        'Documentation Image Deleted',
        'success'
      );
    }
  };

  const handleImageChanges = async (newImages) => {
    dispatch({
      type: DOCUMENTATION_ACTIONS.UPDATED,
      data: { ...documentationItem, images: newImages },
    });
  };

  const onSubDocumentClick = (subDocId, subDocType) => {
    if (subDocId && subDocType) {
      const url = `/documentation/${subDocType
        .substring(3)
        .toLowerCase()}/${subDocId}`;
      navigate(url, { replace: true });
      // use navigate is not refreshing the content
      window.location.reload();
    }
  };

  const linkOffsiteButtonClicked = (url) => {
    window.open(url);
  };

  const showEditing = canEditContent(user, documentationItem.userId);

  /**
   * Show the Dialog Component by updating the editing.show value to true
   * @param {string} documentType
   * @param {string} projectId
   */
  const showDialog = (documentType, projectId) => {
    setEditing({
      ...editing,
      show: true,
      type: documentType,
      project: projectId,
    });
  };

  const saveChanges = (type, selected) => {
    let documentationItemCopy = { ...documentationItem };
    const updateDocumentList = async () => {
      if (!Array.isArray(selected[type]) && selected[type]) {
        // For single selected item object value not in an array
        documentationItemCopy[type] = [selected[type]];
      } else if (Array.isArray(selected[type]) && selected[type]) {
        // For an array of selected item objects
        documentationItemCopy[type] = selected[type];
      } else if (selected.contentType !== 'CsrProject') {
        // For non-project just set value to incoming array
        documentationItemCopy[type] = selected;
      } else {
        // For no selections, set to an empty array
        documentationItemCopy[type] = [];
      }
      setEditing({ show: false, saveDisabled: true });
      // Update on API
      await updateDocumentation(documentationItemCopy);
      // Update local state
      dispatch({ type: DOCUMENTATION_ACTIONS.FETCH });
      dispatch({
        type: DOCUMENTATION_ACTIONS.SUCCESS,
        data: documentationItemCopy,
      });
    };
    updateDocumentList();
  };

  if (documentationItem.contentType === 'CsrProject') {
    return (
      <>
        {!loading && loaded && (
          <>
            <ProjectContent
              linkOffsiteButtonClicked={linkOffsiteButtonClicked}
              imagePath={config.PUBLIC_URL}
              content={documentationItem}
              showEditing={showEditing}
              handleEvent={handleEvent}
              handleUploadFile={handleUploadFile}
              handleImageDelete={handleImageDelete}
              onSubDocumentClick={onSubDocumentClick}
              DocumentationListPage={DocumentationListPage}
              showDialog={showDialog}
            />
            <EditDialog
              editing={editing}
              setEditing={setEditing}
              saveChanges={saveChanges}
              onSubDocumentClick={onSubDocumentClick}
              documentationItem={documentationItem}
              DocumentationListPage={DocumentationListPage}
              SearchDialog={SearchDialog}
              handleUpdateDocumentationState={handleUpdateDocumentationState}
              handleUpdateDocumentationStateFromListingToSearch={
                handleUpdateDocumentationStateFromListingToSearch
              }
            />
          </>
        )}
      </>
    );
  }

  return (
    <DocumentationContent
      linkOffsiteButtonClicked={linkOffsiteButtonClicked}
      imagePath={config.PUBLIC_URL}
      content={documentationItem}
      showEditing={showEditing}
      handleEvent={handleEvent}
      handleUploadFile={handleUploadFile}
      handleImageDelete={handleImageDelete}
      handleImageChanges={handleImageChanges}
    />
  );
};

DocumentationDetailsPage.propTypes = {};

export default DocumentationDetailsPage;
