/** Import User Event Handlers */
import {
  onSetSupportRespond,
  onSupportRequest,
  onCsrRequest,
  onResendEmailChangeVerification,
} from './requests/';

import {
  onSignUp,
  onContactEdit,
  onGeneralEdit,
  onPasswordChange,
} from './api/';

import {
  onEmailChangeRequest,
  onOrganizationChangeRequest,
  onApiRequest,
  onSdaMdaRequest,
} from '../pages/UserRequests/UsersRequestsLib';

/** Import 2FA Handlers */
import { onTwoFaReset } from '../layouts/TwoFaLayout';

export const onEvents = {
  onSetSupportRespond: onSetSupportRespond,
  onSupportRequest: onSupportRequest,
  onCsrRequest: onCsrRequest,
  onSignUp: onSignUp,
  onContactEdit: onContactEdit,
  onGeneralEdit: onGeneralEdit,
  onPasswordChange: onPasswordChange,
  onEmailChangeRequest: onEmailChangeRequest,
  onOrganizationChangeRequest: onOrganizationChangeRequest,
  onTwoFaReset: onTwoFaReset,
  onApiRequest: onApiRequest,
  onSdaMdaRequest: onSdaMdaRequest,
  onResendEmailChangeVerification: onResendEmailChangeVerification,
};
