import { GET, PUT } from './index';

import { SIMULATION_PLUGIN_URL } from './index';

export function getSimulationPlugins(simulationPluginId = null) {
  if (simulationPluginId) {
    return GET(SIMULATION_PLUGIN_URL + `/${simulationPluginId}`);
  }
  return GET(SIMULATION_PLUGIN_URL);
}

export function updateSimulationPluginById(simulationId, simulationContent) {
  return PUT(SIMULATION_PLUGIN_URL + `/${simulationId}`, simulationContent);
}
