//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useContext, useEffect } from 'react';
import ReactJoyride from 'react-joyride';

/** Global Context */
import { Context } from '../helpers';

function Tour() {
  const {storedShowTour, setStoredShowTour} = useContext(Context);

  const [joyride, setJoyride] = useState({
    run: false,
    steps: [
      {
        title: 'Welcome to the ROS Military (ROS-M) Registry',
        target: 'body',
        placement: 'center',
        content: (
          <div>
            Please complete this quick tour to familiarize yourself with the
            site. Use the left and right arrows to quickly navigate the
            tutorial. Select the X above to cancel the tour.
          </div>
        ),
      },
      {
        title: 'Using the Search',
        target: '.searchBar',
        content: (
          <div>
            Using the search bar at the top of the page you can search the ROS-M
            registry for: Packages, Lists, Components, Interfaces, Profiles, and
            Instantiations.
          </div>
        ),
      },
      {
        title: 'Your Profile',
        target: '.profile',
        content: (
          <div>
            Your profile provides the ability to edit your data, make
            access/support requests, change your password, and see your access
            rights.
          </div>
        ),
      },
      {
        title: 'Notifications',
        target: '.notifications',
        content: (
          <div>
            The notifications bell will alert you when there are responses to
            your requests or when there is important information.
          </div>
        ),
      },
      {
        title: 'Navigation Bar',
        target: '.adminNavBar',
        content: (
          <div>
            The navigation bar provides a listing of all available options. You
            can collapse the navigation bar by clicking the left arrow icon and
            expand it once more by pressing the three line icon.
          </div>
        ),
      },
      {
        title: 'Signing Out',
        target: '.signOut',
        content: (
          <div>
            The navigation bar provides a listing of all available options. You
            can collapse the navigation bar by clicking the left arrow icon and
            expand it once more by pressing the three line icon.
          </div>
        ),
      },
    ],
  });

  useEffect(() => {
    // Check if the user has logged in more than once and only show if they have not
    setJoyride({
      ...joyride,
      run: storedShowTour,
    });
  }, []);

  /**
   * Override default blinking beacon component as it is not helpful and cannot be disabled natively
   * @returns
   */
  const HiddenBeacon = () => {
    return <></>;
  };

  const handleJoyrideClose = (data) => {
    if (data.action === 'close' || data.action === 'skip') {
      setStoredShowTour(false);
    }
  };

  return (
    <>
      <ReactJoyride
        steps={joyride.steps}
        run={storedShowTour}
        callback={handleJoyrideClose}
        continuous
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        beaconComponent={HiddenBeacon}
      />
    </>
  );
}
export default Tour;
