//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState, useEffect, useReducer } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearAllOptionsIcon from '@mui/icons-material/PlaylistRemove';
import SelectAllOptionsIcon from '@mui/icons-material/PlaylistAddCheck';

import { getRosmCategoryData } from '../../../helpers';

const ERROR_LOADING = 'error loading';
const NO_SUCH_CONTENT = 'no content available';

const ROSM_CATEGORY_ACTIONS = {
  FETCH: 'FETCH',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const rosmCategoryReducer = (state, action) => {
  switch (action.type) {
    case ROSM_CATEGORY_ACTIONS.FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case ROSM_CATEGORY_ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        categories: action.data,
      };
    }
    case ROSM_CATEGORY_ACTIONS.ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        loaded: false,
      };
    }
    default: {
      return state;
    }
  }
};

const initialCategoryState = {
  loading: false,
  loaded: false,
  error: null,
  categories: null,
};

/**
 * Simple search filter item containing a list of options
 * @param {object} params
 * @returns
 */
const SearchFilterCategoryItem = ({
  enabled,
  handleChange,
  options,
  handleEnabledChange,
  handleResetClick,
  handleNoneClick,
}) => {
  const [reducerState, dispatch] = useReducer(
    rosmCategoryReducer,
    initialCategoryState
  );

  const { categories, loading, error, loaded } = reducerState;
  const [isCollapsed, setCollapsed] = useState(true);

  const getCategories = async () => {
    dispatch({ type: ROSM_CATEGORY_ACTIONS.FETCH });
    try {
      let response = await getRosmCategoryData();
      for (const pair of response.headers.entries()) {
        const key = pair[0];
        const val = pair[1];
        if (key === 'content-length' && val === '0') {
          dispatch({
            type: ROSM_CATEGORY_ACTIONS.NO_SUCH_CONTENT,
            error: NO_SUCH_CONTENT,
          });
          return;
        }
      }
      const data = await response.json();
      if (response.status === 200) {
        dispatch({ type: ROSM_CATEGORY_ACTIONS.SUCCESS, data });
        return;
      } else {
        dispatch({
          type: ROSM_CATEGORY_ACTIONS.ERROR,
          error: data?.error ? data.error : response.error,
        });
      }
    } catch (error) {
      dispatch({ type: ROSM_CATEGORY_ACTIONS.ERROR, error: ERROR_LOADING });
    }
  };

  const handleGroupClick = (event) => {
    handleChange(event);
    handleEnabledChange(event.target.name);
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loading || error || !loaded) {
    return <div></div>;
  }

  const categoryItems = categories.map((i) => {
    return {
      name: i.name,
      id: i.categoryId,
      field: `${i.categoryId}`,
      checked: true,
      isCsr: false,
    };
  });

  categoryItems.sort(function (a, b) {
    return b.id - a.id;
  });

  const item = {
    title: 'ROS-M Category',
    field: 'rosmCategories',
    description: 'ROS-M Categories',
    type: 'Checkbox',
    options: categoryItems,
  };

  const searchFilterOptions = item.options.map((fItemOption) => {
    const ff = `${item.field}-${fItemOption.id}`;
    if (!(ff in options)) {
      options[ff] = false;
    }
    return (
      <FormControlLabel
        key={`k-${item.field}-${fItemOption.field}`}
        name={item.field}
        disabled={!enabled}
        control={
          <Checkbox
            key={ff}
            sx={{
              padding: '2px 2px 2px 5px',
              marginLeft: '8px',
            }}
            size='small'
            checked={!!options[ff]}
            onChange={handleChange}
            name={ff}
          />
        }
        label={<small>{fItemOption.name.replace(':', ' -')}</small>}
      />
    );
  });

  return (
    <Card variant='outlined' sx={{ marginTop: '8px' }}>
      <CardContent
        sx={{ padding: '4px', ':last-child': { paddingBottom: '0px' } }}
      >
        <FormControl
          key={`l-${item.field}`}
          sx={{ m: 1, width: '100%' }}
          component='fieldset'
          variant='standard'
        >
          <FormLabel component='legend' style={{ width: '100%' }}>
            <div style={{ display: 'block', width: '100%' }}>
              <FormControlLabel
                key={`${item.field}-enabled`}
                name={`${item.field}-enabled`}
                control={
                  <Checkbox
                    sx={{
                      padding: '0px 0px 0px 0px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                    size='small'
                    onChange={handleGroupClick}
                    checked={!!enabled}
                  />
                }
                label={item.title}
              />

              <div style={{ position: 'absolute', right: '4px', top: '-3px' }}>
                {!isCollapsed && (
                  <Tooltip title='Select All'>
                    <IconButton
                      size='small'
                      color='primary'
                      aria-label='All'
                      component='label'
                      onClick={() => handleResetClick(item.field, item)}
                    >
                      <SelectAllOptionsIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!isCollapsed && (
                  <Tooltip title='Clear All'>
                    <IconButton
                      size='small'
                      color='primary'
                      aria-label='None'
                      component='label'
                      onClick={() => handleNoneClick(item.field, item)}
                    >
                      <ClearAllOptionsIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  size='small'
                  color='primary'
                  aria-label='Collapse'
                  component='label'
                  onClick={(e) => setCollapsed(!isCollapsed)}
                >
                  {isCollapsed && <ExpandLess />}
                  {!isCollapsed && <ExpandMore />}
                </IconButton>
              </div>
            </div>
          </FormLabel>
          {!isCollapsed && (
            <>
              <FormGroup>{searchFilterOptions}</FormGroup>
            </>
          )}
        </FormControl>
      </CardContent>
    </Card>
  );
};

SearchFilterCategoryItem.propTypes = {};

export default SearchFilterCategoryItem;
