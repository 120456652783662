//
// (C) 2023 Neya Systems, LLC. All Rights Reserved
//
// Neya Systems, LLC disclaims all warranties with regard to this software, including all implied
// warranties of merchantability and fitness, in no event shall Neya Systems, LLC be liable for any
// special, indirect or consequential damages or any damages whatsoever resulting from loss of use,
// data or profits, whether in an action of contract, negligence or other tortious action, arising
// out of or in connection with the use or performance of this software.
//
// GOVERNMENT UNRESTRICTED RIGHTS
//     Contract No.       W15QKN-17-9-102-TR16, Project Agreement 70-201801
//     Contractor Name    Neya Systems, LLC
//     Contractor Address 555 Keystone Dr, Warrendale, PA 15086
//
// The Government's rights to use, modify, reproduce, release, perform, display, or disclose this
// software are restricted by paragraph \(b\)\(2\) of the Rights in Noncommercial Computer Software and
// Noncommercial Computer Software Documentation clause contained in the above identified contract.
// No restrictions apply after the expiration date shown above.  Any reproduction of the software
// or portions thereof marked with this legend must also reproduce the markings.
//

import React, { useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ClearAllOptionsIcon from '@mui/icons-material/PlaylistRemove';
import SelectAllOptionsIcon from '@mui/icons-material/PlaylistAddCheck';


/**
 * Simple search filter item containing a list of options
 * @param {object} params
 * @returns
 */
const SearchFilterItem = ({
  item,
  enabled,
  handleChange,
  options,
  handleEnabledChange,
  handleResetClick,
  handleNoneClick,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const handleGroupClick = (event) => {
    handleChange(event);
    handleEnabledChange(event.target.name);
  };

  const searchFilterOptions = item.options.map((fItemOption) => {
    const ff = `${item.field}-${fItemOption.field}`;
    if (!(ff in options)) {
      options[ff] = false;
    }

    return (
      <FormControlLabel
        key={`k-${item.field}-${fItemOption.field}`}
        name={item.field}
        disabled={!enabled}
        control={
          <Checkbox
            key={ff}
            sx={{
              padding: '2px 2px 2px 5px',
              marginLeft: '8px',
            }}
            size='small'
            checked={!!options[ff]}
            onChange={handleChange}
            name={ff}
          />
        }
        label={fItemOption.name}
      />
    );
  });

  return (
    <Card variant='outlined' sx={{ marginTop: '8px' }}>
      <CardContent
        sx={{ padding: '4px', ':last-child': { paddingBottom: '0px' } }}
      >
        <FormControl
          key={`l-${item.field}`}
          sx={{ m: 1, width: '100%' }}
          component='fieldset'
          variant='standard'
        >
          <FormLabel component='legend' style={{ width: '100%' }}>
            <div style={{ display: 'block', width: '100%' }}>
              <FormControlLabel
                key={`${item.field}-enabled`}
                name={`${item.field}-enabled`}
                control={
                  <Checkbox
                    sx={{
                      padding: '0px 0px 0px 0px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                    size='small'
                    onChange={handleGroupClick}
                    checked={!!enabled}
                  />
                }
                label={item.title}
              />

              <div style={{ position: 'absolute', right: '4px', top: '-3px' }}>
                {!isCollapsed && (
                  <Tooltip title='Select All'>
                    <IconButton
                      size='small'
                      color='primary'
                      aria-label='select all'
                      component='label'
                      onClick={() => handleResetClick(item.field)}
                    >
                      <SelectAllOptionsIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!isCollapsed && (
                  <Tooltip title='Clear All'>
                    <IconButton
                      size='small'
                      color='primary'
                      aria-label='clear'
                      component='label'
                      onClick={() => handleNoneClick(item.field)}
                    >
                      <ClearAllOptionsIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton
                  size='small'
                  color='primary'
                  aria-label='collapse'
                  component='label'
                  onClick={(e) => setCollapsed(!isCollapsed)}
                >
                  {isCollapsed && <ExpandLess />}
                  {!isCollapsed && <ExpandMore />}
                </IconButton>
              </div>
            </div>
          </FormLabel>
          {!isCollapsed && (
            <>
              <FormGroup>{searchFilterOptions}</FormGroup>
            </>
          )}
        </FormControl>
      </CardContent>
    </Card>
  );
};

SearchFilterItem.propTypes = {};

export default SearchFilterItem;
